// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2qhf_UiJXYNH_2_uxovjCX {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n}\n  ._2qhf_UiJXYNH_2_uxovjCX :focus {\n    outline: 2px dotted rgb(15, 111, 237);\n  }\n\n._3ns5FtgrvqGEBBHxOzyNL3 {\n  flex: 1;\n  font-weight: inherit;\n}\n", "",{"version":3,"sources":["app.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AAInB;EAHE;IACE,qCAAqC;EACvC;;AAGF;EACE,OAAO;EACP,oBAAoB;AACtB","file":"app.css","sourcesContent":[".pageContainer {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  :focus {\n    outline: 2px dotted rgb(15, 111, 237);\n  }\n}\n\n.contentWrap {\n  flex: 1;\n  font-weight: inherit;\n}\n"]}]);
// Exports
exports.locals = {
	"pageContainer": "_2qhf_UiJXYNH_2_uxovjCX",
	"contentWrap": "_3ns5FtgrvqGEBBHxOzyNL3"
};
module.exports = exports;
