import React from 'react';
import { resultItem, resultDetails } from './results.css';
import PropTypes from 'prop-types';
import highlightText from '../../../utils/highlightText';
import ResultUtilityButtons from './resultUtilityButtons/ResultUtilityButtons';
import { supportLitFields, ignoreEntries } from '../../../utils/displayKeys';

SupportLitResultItem.propTypes = {
  supportLitItem: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default function SupportLitResultItem({ supportLitItem, isOpen }) {
  const { documentTitle: title, publicationYear: year, source, filesLink: link } = supportLitItem;

  const supportLitTableData = [];
  supportLitFields.map((field, i) => {
    const entry = supportLitItem[field];
    if(entry && !ignoreEntries.includes(entry)) {
      if(!(field === 'filesLink'))
        supportLitTableData.push(<p key={i}>{highlightText(entry, 'highlighter').fragment}</p>);
      else
      {
        const supportLitLink = link.startsWith('http') ? link : `${process.env.SUP_LIT_PATH}/${link}`;
        supportLitTableData.push(<strong key={i}><a href={supportLitLink} rel='noopener noreferrer' target='_blank'>link</a></strong>);
      }
    }
  }).join('\n');

  const highlightedTitle = highlightText(title, 'highlighter').fragment;
  return (
    <details className={resultItem} open={isOpen}>
      <summary><strong>{highlightedTitle} ({year})</strong></summary>
      <table className={resultDetails}>
        <tbody>
          <tr>
            <td>
              {supportLitTableData}
            </td>
          </tr>
        </tbody>
      </table>
    </details>
  );
}
