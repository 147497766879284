import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

export default function pageTracker(WrappedComponent, options = {}) {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = (props) => {
    const location = useLocation();

    useEffect(() => {
      const { pathname: page } = location;
      trackPage(page);
    }, [location.pathname]);

    return (<WrappedComponent {...props } />);
  };

  return HOC;
}
