import { SET_USER_LOADING, SET_USER, SET_AUTH_ERROR, LOGOUT_USER, SET_COOKIE_PREF, SET_PROVIDER_EDITING, SET_PROVIDER } from '../actions/userAuthActions';

const cookiePref = window.localStorage.getItem('cookiePref') ? window.localStorage.getItem('cookiePref') : null;

const initialState = {
  userLoading: true,
  user: {},
  error: '',
  cookiePref,
  isEditingProvider: false
};

export const userAuthReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_USER_LOADING:
      return { ...state, userLoading: true };
    case SET_USER:
      return { ...state, userLoading: false, user: action.payload };
    case SET_AUTH_ERROR:
      return { ...state, userLoading: false, error: action.payload };
    case LOGOUT_USER:
      return { ...state, userLoading: false, user: {} };
    case SET_COOKIE_PREF:
      return { ...state, cookiePref: action.payload };
    case SET_PROVIDER:
      return { 
        ...state, 
        user: { ...state.user, provider: action.payload } 
      };
    case SET_PROVIDER_EDITING:
      return { ...state, isEditingProvider: action.payload };
    default:
      return state;
  }
};
