export const SET_PROVIDER_QUERY = 'SET_PROVIDER_QUERY';
export const setProviderQuery = query => ({
  type: SET_PROVIDER_QUERY,
  payload: query
});

export const RESET_PROVIDER_QUERY = 'RESET_PROVIDER_QUERY';
export const resetProviderQuery = () => ({
  type: RESET_PROVIDER_QUERY
});
