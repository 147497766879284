import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './phoneStyles.css';

PhoneForm.propTypes = {
  submitCallback: PropTypes.func.isRequired,
  cancelCallback: PropTypes.func.isRequired,
  editingPhone: PropTypes.shape({
    number: PropTypes.string,
    isHome: PropTypes.bool,
    isOffice: PropTypes.bool,
    isMobile: PropTypes.bool,
    isFax: PropTypes.bool
  }),
  required: PropTypes.bool
};

export default function PhoneForm({ 
  submitCallback, 
  cancelCallback,
  editingPhone = {},
  required
}) {
  const [phoneNumber, setPhoneNumber] = useState(editingPhone.number || '');
  const [phoneType, setPhoneType] = useState({
    home: editingPhone.isHome || false,
    office: editingPhone.isOffice || false,
    mobile: editingPhone.isMobile || false,
    fax: editingPhone.isFax || false
  });
  const phoneTypeRef = useRef(null);
  const phoneNumberRef = useRef(null);

  const handlePhoneNumberChange = ({ target }) => setPhoneNumber(target.value);
  const handlePhoneTypeChange = event => {
    event.stopPropagation();
    const formattedProviderPhoneType = event.target.value.toLowerCase();
    setPhoneType({
      ...phoneType,
      [formattedProviderPhoneType]: !phoneType[formattedProviderPhoneType]
    });
  };

  const phoneTypeInputs = ['Mobile', 'Office', 'Home', 'Fax'].map(type => (
    <label
      className={styles.phoneLabel}
      key={type}
    >
      <input
        type='checkbox'
        value={type}
        checked={phoneType[type.toLowerCase()]}
        onChange={handlePhoneTypeChange}
        className={styles.phoneOption}
      /> {type}
    </label>
  ));


  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const validPhoneType = Object.values(phoneType).includes(true);
    if(!phoneNumber) {
      phoneNumberRef.current.style.border = '3px solid red';
      phoneNumberRef.current.scrollIntoView();
      return;
    }
    if((required && !validPhoneType) || (phoneNumber && 
      !validPhoneType)) {
      phoneNumberRef.current.style.border = 'none';
      phoneTypeRef.current.style.border = '3px solid red';
      phoneTypeRef.current.scrollIntoView();
      return;
    } else {
      phoneTypeRef.current.style.border = 'none';
    }

    submitCallback({
      number: phoneNumber,
      isOffice: phoneType.office,
      isHome: phoneType.home,
      isMobile: phoneType.mobile,
      isFax: phoneType.fax
    });
  };

  return (
    <div>
      <input
        ref={phoneNumberRef}
        type='text'
        placeholder='1-234-567-8901'
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
      />
      <label ref={phoneTypeRef}>*What type of phone number is this?
        <div className={styles.phoneTypeSection}>
          {phoneTypeInputs}
        </div>
      </label>
      <div className={styles.buttonContainer}>
        <input 
          type="button"
          onClick={cancelCallback}
          value="Cancel"
        />
        <input 
          type="button"
          onClick={handleSubmit}
          value="Done"
        />
      </div>
    </div>
  );
}
