// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._4HE8h6zot8tiFMs2h3p2Y {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  top: 50%;\n  left: 50%;\n  position: fixed;\n  overflow: auto;\n  z-index: 3;\n  margin: 5% auto;\n  padding: 1rem;\n  border: solid #dddddd 1px;\n  border-radius: 5px;\n  background-color: #f6f6f6;\n  min-width: 7em;\n  width: auto;\n  transform: translate(-50%, -50%);\n}\n\n.Xie_xM9YcysxMDo8PiLde {\n  position: absolute;\n  z-index: 2;\n  width: 100vw; \n  height: 100vh;\n  background-color: #bbbaba6c;\n}", "",{"version":3,"sources":["orgUsersAccessExpiration.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,eAAe;EACf,cAAc;EACd,UAAU;EACV,eAAe;EACf,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,WAAW;EACX,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,2BAA2B;AAC7B","file":"orgUsersAccessExpiration.css","sourcesContent":[".accessModal {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  top: 50%;\n  left: 50%;\n  position: fixed;\n  overflow: auto;\n  z-index: 3;\n  margin: 5% auto;\n  padding: 1rem;\n  border: solid #dddddd 1px;\n  border-radius: 5px;\n  background-color: #f6f6f6;\n  min-width: 7em;\n  width: auto;\n  transform: translate(-50%, -50%);\n}\n\n.backgroundDiv {\n  position: absolute;\n  z-index: 2;\n  width: 100vw; \n  height: 100vh;\n  background-color: #bbbaba6c;\n}"]}]);
// Exports
exports.locals = {
	"accessModal": "_4HE8h6zot8tiFMs2h3p2Y",
	"backgroundDiv": "Xie_xM9YcysxMDo8PiLde"
};
module.exports = exports;
