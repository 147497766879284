/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import { 
  // useDispatch, 
  useSelector } from 'react-redux';
import { getUserLoading, getUser } from '../../data/selectors/userAuthSelectors';
import { useNavigate } from 'react-router-dom';
import ResultsSection from './results/ResultsSection';
import styles from './studies.css';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import createQuery, { emptyQuery } from './studiesFuncs/createQuery';
import ModalGroup from '../modalGroup/ModalGroup';
import { yearPublished, tumorType, naturalTherapyTypes, conventionalTreatmentTypes, sideEffects } from '../../utils/dropdownSeedData';
import SynonymModal from '../modalGroup/SynonymModal';
import { getSynonymDictionaries } from '../../services/synonyms';
import SearchInfo from '../searchInfo/SearchInfo';
import executeSearch from './studiesFuncs/executeSearch';
import { TiFilter } from 'react-icons/ti';
import ReactGA from 'react-ga';
import { getNotableStudies } from '../../services/studies';
import SearchBetaInfo from '../searchBetaInfo/SearchBetaInfo';
// import { setQuery } from '../../data/actions/searchActions';

const filterGroupArrays = [yearPublished, tumorType, conventionalTreatmentTypes, sideEffects];
let naturalTherapyAgents = ['Natural Therapy Agents', 'All Natural Therapy Agents'];
let conventionalTreatmentAgents = ['Conventional Treatment Agents', 'All Conventional Treatment Agents'];

const initializeFilterState = () => {
  return filterGroupArrays.reduce((initialState, filterArray) => {
    const [filterName, allOption] = filterArray;
    return { ...initialState, [filterName]: [allOption] };
  }, {});
};

const Studies = () => {
  const navigate  = useNavigate();
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);
  // const dispatch = useDispatch();
  const [filterOptions, setFilterOptions] = useState(initializeFilterState);
  const [searchText, setSearchText] = useState('');
  const [masterSearchQuery, setMasterSearchQuery] = useState({});
  const [studiesLoading, setStudiesLoading] = useState({ loading: false, newSearch: false });
  const [pediatricCheckbox, setPediatricCheckbox] = useState(false);
  const [betaSearch, setBetaSearch] = useState(false);
  const [remountKey, setRemountKey] = useState(0);
  const [searchOccuredFlag, setSearchOccuredFlag] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [dictionariesLoading, setDictionariesLoading] = useState(false);
  const [naturalSynonymsDictionary, setNaturalSynonymsDictionary] = useState({});
  const [conventionalSynonymsDictionary, setConventionalSynonymsDictionary] = useState({});
  const [summarizedStudies, setSummarizedStudies] = useState({ 
    results: [],
    fullCount: 0
  });
  const [supportLit, setSupportLit] = useState({ 
    results: [],
    fullCount: 0
  });

  useEffect(() => {
    document.title = 'KNOW - Studies';
  }, []);

  useEffect(() => {
    setDictionariesLoading(true);
    getSynonymDictionaries()
      .then(({ naturalSynonymsDictionary, conventionalSynonymsDictionary }) => {
        naturalTherapyAgents = ['Natural Therapy Agents', 'All Natural Therapy Agents', ...Object.keys(naturalSynonymsDictionary).map(key => key.includes('"') ? key.slice(1, -1) : key)];
        conventionalTreatmentAgents = ['Conventional Treatment Agents', 'All Conventional Treatment Agents', ...Object.keys(conventionalSynonymsDictionary).map(key => key.includes('"') ? key.slice(1, -1) : key)];
        setNaturalSynonymsDictionary(naturalSynonymsDictionary);
        setConventionalSynonymsDictionary(conventionalSynonymsDictionary);
        setDictionariesLoading(false);
      })
      .catch(err => {
        console.log(err);
        setDictionariesLoading(false);
      });
  }, []);

  const initialRender = useRef(true);
  useEffect(() => {
    if(!initialRender.current && updateFlag) {
      setStudiesLoading({ loading: true });
      clearStudies();
      executeSearch(masterSearchQuery, setSummarizedStudies, setSupportLit, betaSearch)
        .then(() => {
          setStudiesLoading({ loading: false });
          setUpdateFlag(false);
        });
    }
  }, [updateFlag]);

  useEffect(() => {
    if(!user.email) navigate('/login#studies');
  }, [user.email, navigate]);
  
  function handleSubmit({ event = null, showSupportLit = false, newSearch = false }) {
    if(event) {
      event.preventDefault();
    }
    clearStudies();
    const searchQuery = createQuery(filterOptions, searchText, pediatricCheckbox, betaSearch);
    if(searchQuery !== emptyQuery) {
      setStudiesLoading({  loading:true, newSearch });
      ReactGA.event({
        category: 'Search',
        action: `Search Text: ${searchQuery.searchTextInput}; Only Pediatric: ${searchQuery.onlyPediatric}; Filters: ${searchQuery.selectedOptionsArray.toString()}`,
      });
      setMasterSearchQuery(searchQuery);
      // dispatch(setQuery(searchQuery));
      executeSearch(searchQuery, setSummarizedStudies, showSupportLit ? setSupportLit : null, betaSearch)
        .then(() => {
          setSearchOccuredFlag(true);
          setStudiesLoading({ loading:false, newSearch });
        });
      // setCurrentPage(1);
    }
  }

  useEffect(() => {
    if(!initialRender.current){
      handleSubmit({
        event: null,
        showSupportLit: !pediatricCheckbox && user.role !== 'allied' && user.organizations !== 'ccnm',
        newSearch: true
      });
    } else initialRender.current = false;
  }, [filterOptions, pediatricCheckbox]);

  const updateStudiesCallback = () => setUpdateFlag(true);

  if(dictionariesLoading || userLoading) return <LoadingSpinner />;

  const url = new URL(window.location);
  if(url.searchParams.get('notable') === 'true') {
    window.history.pushState('', document.title, window.location.pathname);
    setStudiesLoading({ loading: true, newSearch: true });
    setSearchOccuredFlag(true);
    getNotableStudies()
      .then(res => {
        setSummarizedStudies({ results: res, fullCount: res.length });
      })
      .catch(err => console.log(err))
      .finally(setStudiesLoading({ loading: false, newSearch: false }));
  }

  function clearStudies() {
    setSummarizedStudies({ 
      results: [],
      fullCount: 0
    });
    setSupportLit({ 
      results: [],
      fullCount: 0
    });
    window.history.pushState('', document.title, window.location.pathname);
  }

  const handlePediatricChange = () => setPediatricCheckbox(!pediatricCheckbox);
  const handleToggleBetaSearch = () => setBetaSearch(!betaSearch);
  const handleSearchText = ({ target }) => setSearchText(target.value);
  const handleClear = () => {
    setRemountKey(remountKey + 1);
    setSearchOccuredFlag(false);
    clearStudies();
    setSearchText('');
    initialRender.current = true;
    // setCurrentPage(1);
    setPediatricCheckbox(false);
    setFilterOptions(initializeFilterState());
  };
  const handleFilterChange = newOptions => setFilterOptions(newOptions);

  // const handlePagination = (page, search) => {
  //   search.page = page;
  //   setCurrentPage(page);
  //   executeSearch(search, setSummarizedStudies, setSupportLit);
  // };

  const filteredKeys = Object.entries(filterOptions).filter(([, [firstFilter]]) => !firstFilter.startsWith('All ')).map(([key]) => key);

  const { searchContainer, submit, textInput, searchButtonContainer, searchButton, checkbox, checkboxContainer } = styles;
  return (
    <div role="main" aria-label="search for studies">
      <section className={searchContainer} aria-label="search options">
        <form className={submit} onSubmit={e => handleSubmit({ 
          event: e,
          showSupportLit: !pediatricCheckbox && user.role !== 'allied' && user.organizations !== 'ccnm',
          newSearch: true
        })}>
          <span>
            <SearchInfo />
            Search:
          </span>
          <input type='text' className={textInput} value={searchText} onChange={handleSearchText} autoFocus={true}/>
          <div className={searchButtonContainer}>
            <button type='submit' className={searchButton} value='Search' aria-label="search button">Search</button>
            <button type='button' className={searchButton} onClick={handleClear} aria-label="clear search results">Clear</button>
          </div>
          <div className={checkboxContainer}>
            <label><input className={checkbox} type='checkbox' checked={pediatricCheckbox} onChange={handlePediatricChange} />Pediatric Results Only<TiFilter /></label>
            {/* {user.role === 'know-admin' && 
                <label>
                  <input className={checkbox} type='checkbox' checked={betaSearch} onChange={handleToggleBetaSearch} />
                  Beta Search 
                  <SearchBetaInfo />
                </label>
            } */}
          </div>
        </form>
        <ModalGroup key={remountKey} modalGroupArray={filterGroupArrays} setCallback={handleFilterChange} includeAllOption={true} styles={styles}>
          <SynonymModal key={naturalTherapyAgents[0]} optionsArray={naturalTherapyAgents} includeAllOption={true} synonymsDictionary={naturalSynonymsDictionary} setCallback={_ => _}/>
          <SynonymModal key={conventionalTreatmentAgents[0]} optionsArray={conventionalTreatmentAgents} includeAllOption={true} synonymsDictionary={conventionalSynonymsDictionary} setCallback={_ => _}/>
        </ModalGroup>
      </section>
      {searchOccuredFlag && <ResultsSection
        summarizedStudies={summarizedStudies}
        filteredKeys={filteredKeys}
        supportLit={supportLit}
        updateCallback={updateStudiesCallback}
        masterSearchQuery={masterSearchQuery}
        // pageCallback={handlePagination}
        searchOccuredFlag={searchOccuredFlag}
        // currentPage={currentPage}
        // setCurrentPage={setCurrentPage}
        studiesLoading={studiesLoading} />}
    </div>
  );
};

export default Studies;
