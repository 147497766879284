import React from 'react';
import VirtualServices from './VirutalServices';
import styles from './providerResultDetail.css';
import PropTypes from 'prop-types';
import { parsePhoneNumbers } from './utils';

ProviderLocation.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string,
    virtual: PropTypes.bool,
    suppressAddress: PropTypes.bool,
    country: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zipCode: PropTypes.string,
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.string,
      isHome: PropTypes.bool,
      isMobile: PropTypes.bool,
      isOffice: PropTypes.bool,
      isFax: PropTypes.bool
    })),
    email: PropTypes.string,
    url: PropTypes.string
  })
};

export default function ProviderLocation({ location
}) {
  const { name, virtual, suppressAddress, country, address1, address2, city, state, zipCode, phoneNumbers, email, url } = location;
  const hasAddress = [address1, address2, city, state, zipCode].every(string => string);
  const phoneItems = parsePhoneNumbers(phoneNumbers);

  return (
    <div>
      <h4>{name}</h4>
      {virtual && <VirtualServices />}
      <div style={{ margin: '5px 0' }}>
        {country !== 'United States of America' && <p style={{ lineHeight: 1.2 }}>
          {country}
        </p>}
        {hasAddress ?
          !suppressAddress ?
            <div>
              <p style={{ lineHeight: 1.2 }}>
                {address1}
              </p>
              <p style={{ lineHeight: 1.2 }}>
                {address2}
              </p>
              <p style={{ lineHeight: 1.2 }}>
                {`${city}${city && state && ','} ${state} ${zipCode}`}
              </p>
            </div> : 
            <div>
              <p style={{ lineHeight: 1.2 }}>
                {state}
              </p>
            </div>
          : ''
        }
      </div>
      <div className={styles.phoneNumbers}>
        {phoneItems}
      </div>
      {email && <p style={{ lineHeight: 1.2 }}>
        email: {email}
      </p>}
      {url && <p>
        website: <a href={url}>{url}</a>
      </p>}
    </div>
  );
}
