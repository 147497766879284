// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2qBDkO8UPfoEVzo4yjdqNE {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 1rem 3rem 1rem 3rem;\n  text-align: center;\n\n  border: 1px solid;\n  box-shadow: 1px 1px #dddddd;\n  border-color: #dddddd;\n  border-bottom-width: 1px;\n  border-radius: 10px;\n  padding: .75em;\n}\n\n  ._2qBDkO8UPfoEVzo4yjdqNE img {\n    width: 50%;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n  }\n", "",{"version":3,"sources":["continuingEducation.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;;EAElB,iBAAiB;EACjB,2BAA2B;EAC3B,qBAAqB;EACrB,wBAAwB;EACxB,mBAAmB;EACnB,cAAc;AAOhB;;EALE;IACE,UAAU;IACV,gBAAgB;IAChB,mBAAmB;EACrB","file":"continuingEducation.css","sourcesContent":[".ceContainer {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 1rem 3rem 1rem 3rem;\n  text-align: center;\n\n  border: 1px solid;\n  box-shadow: 1px 1px #dddddd;\n  border-color: #dddddd;\n  border-bottom-width: 1px;\n  border-radius: 10px;\n  padding: .75em;\n\n  img {\n    width: 50%;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"ceContainer": "_2qBDkO8UPfoEVzo4yjdqNE"
};
module.exports = exports;
