const conventionalTreatmentAgents = [
  'Conventional Treatment Agents',
  'All Conventional Treatment Agents',
  '5-FU (Fluorouracil Injection)',
  '5-FU (Fluorouracil--Topical)',
  'Abiraterone Acetate',
  'Abitrexate (Methotrexate)',
  'Abraxane (Paclitaxel Albumin bound)',
  'ABVD',
  'ABVE',
  'ABVE-PC',
  'AC',
  'AC-T',
  'Adcetris (Brentuximab Vedotin)',
  'ADE',
  'Ado-Trastuzumab Emtansine',
  'Adriamycin (Doxorubicin Hydrochloride)',
  'Afatinib Dimaleate',
  'Afinitor (Everolimus)',
  'Akynzeo (Netupitant and Palonosetron Hydrochloride)',
  'Aldara (Imiquimod)',
  'Aldesleukin',
  'Alecensa (Alectinib)',
  'Alectinib',
  'Alemtuzumab',
  'Alimta (Pemetrexed Disodium)',
  'Alkeran for Injection (Melphalan Hydrochloride)',
  'Alkeran Tablets (Melphalan)',
  'Allogenic stem cell transplant',
  'Aloxi (Palonosetron Hydrochloride)',
  'Alpha-adrenergics',
  'Ambochlorin (Chlorambucil)',
  'Amboclorin (Chlorambucil)',
  'Aminolevulinic Acid',
  'Anastrozole',
  'Androgen deprivation therapy',
  'Anthracyclines',
  'Anti-thymocyte globulin',
  'Aprepitant',
  'Aredia (Pamidronate Disodium)',
  'Arimidex (Anastrozole)',
  'Aromasin (Exemestane)',
  'aromatase inhibitors',
  'Arranon (Nelarabine)',
  'Arsenic Trioxide',
  'Arzerra (Ofatumumab)',
  'Asparaginase Erwinia chrysanthemi',
  'Atezolizumab',
  'autologous stem cell transplant',
  'Avastin (Bevacizumab)',
  'Axitinib',
  'Azacitidine',
  'BCG',
  'BEACOPP',
  'Becenum (Carmustine)',
  'Beleodaq (Belinostat)',
  'Belinostat',
  'Bendamustine Hydrochloride',
  'BEP',
  'Bevacizumab',
  'Bexarotene',
  'Bexxar (Tositumomab and Iodine I 131 Tositumomab)',
  'Bicalutamide',
  'BiCNU (Carmustine)',
  'bisphosphonate',
  'Bleomycin',
  'Blinatumomab',
  'Blincyto (Blinatumomab)',
  'Bortezomib',
  'Bosulif (Bosutinib)',
  'Bosutinib',
  'Brentuximab Vedotin',
  'bromocriptine',
  'BuMel',
  'Busulfan',
  'Busulfex (Busulfan)',
  'Cabazitaxel',
  'Cabometyx (Cabozantinib-S-Malate)',
  'Cabozantinib-S-Malate',
  'CAF',
  'Campath (Alemtuzumab)',
  'Camptosar (Irinotecan Hydrochloride)',
  'CAP',
  'Capecitabine',
  'CAPOX',
  'Carac (Fluorouracil--Topical)',
  'Carboplatin',
  'CARBOPLATIN-TAXOL',
  'Carfilzomib',
  'Carmubris (Carmustine)',
  'Carmustine',
  'Carmustine Implant',
  'Casodex (Bicalutamide)',
  'CEM',
  'Ceritinib',
  'Cerubidine (Daunorubicin Hydrochloride)',
  'Cervarix (Recombinant HPV Bivalent Vaccine)',
  'Cetuximab',
  'CEV',
  'Chemotherapies (as reported by NCI with last update completed Aug 18 2016)',
  'Chlorambucil',
  'CHLORAMBUCIL-PREDNISONE',
  'CHOP',
  'Cisplatin',
  'Cixutumumab',
  'Cladribine',
  'Clafen (Cyclophosphamide)',
  'Clofarabine',
  'Clofarex (Clofarabine)',
  'Clolar (Clofarabine)',
  'CMF',
  'Cobimetinib',
  'Cometriq (Cabozantinib-S-Malate)',
  'COPDAC',
  'COPP',
  'COPP-ABV',
  'Cosmegen (Dactinomycin)',
  'Cotellic (Cobimetinib)',
  'Crizotinib',
  'CT',
  'CVP',
  'Cyclophosphamide',
  'Cyfos (Ifosfamide)',
  'Cyramza (Ramucirumab)',
  'Cytarabine',
  'Cytarabine Liposome',
  'Cytosar-U (Cytarabine)',
  'Cytoxan (Cyclophosphamide)',
  'Dabrafenib',
  'Dacarbazine',
  'Dacogen (Decitabine)',
  'Dactinomycin',
  'Daratumumab',
  'Darzalex (Daratumumab)',
  'Dasatinib',
  'Daunorubicin Hydrochloride',
  'Decitabine',
  'Defibrotide Sodium',
  'Defitelio (Defibrotide Sodium)',
  'Degarelix',
  'Denileukin Diftitox',
  'Denosumab',
  'DepoCyt (Cytarabine Liposome)',
  'Dexamethasone',
  'Dexrazoxane Hydrochloride',
  'Dinutuximab',
  'Docetaxel',
  'Dolasteron mesylate (Anzemet)',
  'domperidone',
  'Dox-SL (Doxorubicin Hydrochloride Liposome)',
  'doxifluridine',
  'Doxil (Doxorubicin Hydrochloride Liposome)',
  'Doxorubicin Hydrochloride',
  'Doxorubicin Hydrochloride Liposome',
  'DTIC-Dome (Dacarbazine)',
  'Duoxitasai',
  'Efudex (Fluorouracil--Topical)',
  'Elitek (Rasburicase)',
  'Ellence (Epirubicin Hydrochloride)',
  'Elotuzumab',
  'Eloxatin (Oxaliplatin)',
  'Eltrombopag Olamine',
  'Emend (Aprepitant)',
  'Empliciti (Elotuzumab)',
  'Endotecarin',
  'Enocitabine',
  'Enzalutamide',
  'Epirubicin Hydrochloride',
  'EPOCH',
  'epothilone',
  'Erbitux (Cetuximab)',
  'Eribulin Mesylate',
  'Erivedge (Vismodegib)',
  'Erlotinib Hydrochloride',
  'Erwinaze (Asparaginase Erwinia chrysanthemi)',
  'Etopophos (Etoposide Phosphate)',
  'Etoposide',
  'Etoposide Phosphate',
  'Evacet (Doxorubicin Hydrochloride Liposome)',
  'Everolimus',
  'Evista (Raloxifene Hydrochloride)',
  'Evomela (Melphalan Hydrochloride)',
  'Exemestane',
  'Fareston (Toremifene)',
  'Farydak (Panobinostat)',
  'Faslodex (Fulvestrant)',
  'FEC',
  'Femara (Letrozole)',
  'Filgrastim',
  'Fludara (Fludarabine Phosphate)',
  'Fludarabine Phosphate',
  'Fluoroplex (Fluorouracil--Topical)',
  'Fluorouracil Injection',
  'Fluorouracil--Topical',
  'Flutamide',
  'Folex (Methotrexate)',
  'Folex PFS (Methotrexate)',
  'FOLFIRI',
  'FOLFIRI-BEVACIZUMAB',
  'FOLFIRI-CETUXIMAB',
  'FOLFIRINOX',
  'FOLFOX',
  'Folinic Acid',
  'Folotyn (Pralatrexate)',
  'Fotemustine',
  'FU-LV',
  'FUFA',
  'Fulvestrant',
  'Gardasil (Recombinant HPV Quadrivalent Vaccine)',
  'Gardasil 9 (Recombinant HPV Nonavalent Vaccine)',
  'Gazyva (Obinutuzumab)',
  'Gefitinib',
  'Gemcitabine Hydrochloride',
  'GEMCITABINE-CISPLATIN',
  'GEMCITABINE-OXALIPLATIN',
  'Gemtuzumab Ozogamicin',
  'Gemzar (Gemcitabine Hydrochloride)',
  'Gilotrif (Afatinib Dimaleate)',
  'Gimeracil',
  'Gleevec (Imatinib Mesylate)',
  'Gliadel (Carmustine Implant)',
  'Gliadel wafer (Carmustine Implant)',
  'Glucarpidase',
  'GnRH analogue',
  'Goserelin Acetate',
  'Granisetron',
  'Granistron (Kytril)',
  'Granulocyte colony-stimulating factor',
  'Halaven (Eribulin Mesylate)',
  'Hemangeol (Propranolol Hydrochloride)',
  'Herceptin (Trastuzumab)',
  'HPV Bivalent Vaccine, Recombinant',
  'HPV Nonavalent Vaccine, Recombinant',
  'HPV Quadrivalent Vaccine, Recombinant',
  'Hycamtin (Topotecan Hydrochloride)',
  'Hydrea (Hydroxyurea)',
  'Hydroxycamptothecin',
  'Hydroxyurea',
  'Hyper-CVAD',
  'Ibrance (Palbociclib)',
  'Ibritumomab Tiuxetan',
  'Ibrutinib',
  'ICE',
  'Iclusig (Ponatinib Hydrochloride)',
  'Idamycin (Idarubicin Hydrochloride)',
  'Idarubicin Hydrochloride',
  'Idelalisib',
  'Ifex (Ifosfamide)',
  'Ifosfamide',
  'Ifosfamidum (Ifosfamide)',
  'IL-2 (Aldesleukin)',
  'Imatinib Mesylate',
  'Imbruvica (Ibrutinib)',
  'Imiquimod',
  'Imlygic (Talimogene Laherparepvec)',
  'Inlyta (Axitinib)',
  'Interferon Alfa-2b, Recombinant',
  'Interferon-gamma',
  'Interleukin-2 (Aldesleukin)',
  'intracavitary caesium',
  'Intron A (Recombinant Interferon Alfa-2b)',
  'Iodine I 131 Tositumomab and Tositumomab',
  'Ipilimumab',
  'Iressa (Gefitinib)',
  'Irinotecan Hydrochloride',
  'Irinotecan Hydrochloride Liposome',
  'Istodax (Romidepsin)',
  'Ixabepilone',
  'Ixazomib Citrate',
  'Ixempra (Ixabepilone)',
  'Jakafi (Ruxolitinib Phosphate)',
  'Jevtana (Cabazitaxel)',
  'Kadcyla (Ado-Trastuzumab Emtansine)',
  'Keoxifene (Raloxifene Hydrochloride)',
  'Kepivance (Palifermin)',
  'Keytruda (Pembrolizumab)',
  'Kyprolis (Carfilzomib)',
  'Lanreotide Acetate',
  'Lapatinib Ditosylate',
  'Lenalidomide',
  'Lenvatinib Mesylate',
  'Lenvima (Lenvatinib Mesylate)',
  'Letrozole',
  'Leucovorin Calcium',
  'Leukeran (Chlorambucil)',
  'Leuprolide Acetate',
  'Levulan (Aminolevulinic Acid)',
  'Linfolizin (Chlorambucil)',
  'lipiodol',
  'LipoDox (Doxorubicin Hydrochloride Liposome)',
  'Lobaplatin',
  'Lomustine',
  'Lonsurf (Trifluridine and Tipiracil Hydrochloride)',
  'Lupron (Leuprolide Acetate)',
  'Lupron Depot (Leuprolide Acetate)',
  'Lupron Depot-3 Month (Leuprolide Acetate)',
  'Lupron Depot-4 Month (Leuprolide Acetate)',
  'Lupron Depot-Ped (Leuprolide Acetate)',
  'Luteinizing hormone-releasing hormone agonists',
  'Lynparza (Olaparib)',
  'Marqibo (Vincristine Sulfate Liposome)',
  'Matulane (Procarbazine Hydrochloride)',
  'Mechlorethamine Hydrochloride',
  'Megestrol Acetate',
  'Mekinist (Trametinib)',
  'Melphalan',
  'Melphalan Hydrochloride',
  'Mercaptopurine',
  'Mesna',
  'Mesnex (Mesna)',
  'Metformin',
  'Methazolastone (Temozolomide)',
  'Methotrexate',
  'Methotrexate LPF (Methotrexate)',
  'Metoclopramide',
  'Mexate (Methotrexate)',
  'Mexate-AQ (Methotrexate)',
  'Mitomycin C',
  'Mitoxantrone Hydrochloride',
  'Mitozytrex (Mitomycin C)',
  'MOPP',
  'Mozobil (Plerixafor)',
  'Mustargen (Mechlorethamine Hydrochloride)',
  'Mutamycin (Mitomycin C)',
  'Myleran (Busulfan)',
  'Mylosar (Azacitidine)',
  'Mylotarg (Gemtuzumab Ozogamicin)',
  'N/A',
  'Nanoparticle Paclitaxel (Paclitaxel Albumin-stabilized Nanoparticle Formulation)',
  'Navelbine (Vinorelbine Tartrate)',
  'Necitumumab',
  'Nedaplatin',
  'Nelarabine',
  'Neosar (Cyclophosphamide)',
  'Netupitant and Palonosetron Hydrochloride',
  'Neupogen (Filgrastim)',
  'Nexavar (Sorafenib Tosylate)',
  'Nilotinib',
  'Ninlaro (Ixazomib Citrate)',
  'Nivolumab',
  'Nolvadex (Tamoxifen Citrate)',
  'Nplate (Romiplostim)',
  'Obinutuzumab',
  'Octreotide',
  'Odomzo (Sonidegib)',
  'OEPA',
  'Ofatumumab',
  'OFF',
  'Olaparib',
  'Omacetaxine Mepesuccinate',
  'Oncaspar (Pegaspargase)',
  'Ondansetron Hydrochloride',
  'Onivyde (Irinotecan Hydrochloride Liposome)',
  'Ontak (Denileukin Diftitox)',
  'Opdivo (Nivolumab)',
  'OPPA',
  'Osimertinib',
  'oteracil',
  'Oxaliplatin',
  'Paclitaxel',
  'Paclitaxel Albumin-stabilized Nanoparticle Formulation',
  'PAD',
  'Palbociclib',
  'Palifermin',
  'Palonosetron Hydrochloride',
  'Palonosetron Hydrochloride and Netupitant',
  'Pamidronate Disodium',
  'Panitumumab',
  'Panobinostat',
  'Paraplat (Carboplatin)',
  'Paraplatin (Carboplatin)',
  'Pazopanib Hydrochloride',
  'PCV',
  'PEB',
  'PEG-Intron (Peginterferon Alfa-2b)',
  'Pegaspargase',
  'Pegfilgrastim',
  'Pembrolizumab',
  'Pemetrexed Disodium',
  'Perjeta (Pertuzumab)',
  'Personalized peptide vaccine',
  'Pertuzumab',
  'Phytohaemagglutinin PHA',
  'Pirarubicin',
  'Platinol (Cisplatin)',
  'Platinol-AQ (Cisplatin)',
  'Platinums',
  'Plerixafor',
  'Pomalidomide',
  'Pomalyst (Pomalidomide)',
  'Ponatinib Hydrochloride',
  'Portrazza (Necitumumab)',
  'Pralatrexate',
  'Prednisone',
  'Procarbazine Hydrochloride',
  'Proleukin (Aldesleukin)',
  'Prolia (Denosumab)',
  'Promacta (Eltrombopag Olamine)',
  'Propranolol Hydrochloride',
  'Provenge (Sipuleucel-T)',
  'Purinethol (Mercaptopurine)',
  'Purixan (Mercaptopurine)',
  'R-CHOP',
  'R-CVP',
  'R-EPOCH',
  'R-ICE',
  'Radiation',
  'Radioactive iodine therapy',
  'Radium 223 Dichloride',
  'Raloxifene Hydrochloride',
  'Raltitrexed',
  'Ramucirumab',
  'Ranitidine (Zantac)',
  'Rasburicase',
  'Recombinant Human Papillomavirus (HPV) Bivalent Vaccine',
  'Recombinant Human Papillomavirus (HPV) Nonavalent Vaccine',
  'Recombinant Human Papillomavirus (HPV) Quadrivalent Vaccine',
  'Recombinant Interferon Alfa-2b',
  'Regorafenib',
  'Revlimid (Lenalidomide)',
  'Rheumatrex (Methotrexate)',
  'Rituxan (Rituximab)',
  'Rituximab',
  'Rolapitant Hydrochloride',
  'Romidepsin',
  'Romiplostim',
  'Rubidomycin (Daunorubicin Hydrochloride)',
  'Ruxolitinib Phosphate',
  'sagopilone',
  'Sclerosol Intrapleural Aerosol (Talc)',
  'Siltuximab',
  'Sipuleucel-T',
  'Somatostatin',
  'Somatuline Depot (Lanreotide Acetate)',
  'Sonidegib',
  'Sorafenib Tosylate',
  'Sprycel (Dasatinib)',
  'STANFORD V',
  'Sterile Talc Powder (Talc)',
  'Steritalc (Talc)',
  'Stivarga (Regorafenib)',
  'Sunitinib Malate',
  'Surgery',
  'Sutent (Sunitinib Malate)',
  'Sylatron (Peginterferon Alfa-2b)',
  'Sylvant (Siltuximab)',
  'Synovir (Thalidomide)',
  'Synribo (Omacetaxine Mepesuccinate)',
  'Tabloid (Thioguanine)',
  'TAC',
  'TACE',
  'Tafinlar (Dabrafenib)',
  'Tagrisso (Osimertinib)',
  'Talc',
  'Talimogene Laherparepvec',
  'Tamoxifen Citrate',
  'Tarabine PFS (Cytarabine)',
  'Tarceva (Erlotinib Hydrochloride)',
  'Targretin (Bexarotene)',
  'Tasigna (Nilotinib)',
  'Taxanes',
  'Taxol (Paclitaxel)',
  'Taxotere (Docetaxel)',
  'TCF',
  'Tecentriq (Atezolizumab)',
  'tegafur',
  'Temodar (Temozolomide)',
  'Temozolomide',
  'Temsirolimus',
  'Thalidomide',
  'Thalomid (Thalidomide)',
  'Thioguanine',
  'Thiotepa',
  'TNF',
  'Tolak (Fluorouracil--Topical)',
  'Topotecan Hydrochloride',
  'Toremifene',
  'Toremifene citrate',
  'Torisel (Temsirolimus)',
  'Tositumomab and Iodine I 131 Tositumomab',
  'Totect (Dexrazoxane Hydrochloride)',
  'TPF',
  'Trabectedin',
  'Trametinib',
  'Trastuzumab',
  'Treanda (Bendamustine Hydrochloride)',
  'Treosulfan',
  'Trifluridine and Tipiracil Hydrochloride',
  'Trisenox (Arsenic Trioxide)',
  'TS-1 S-1',
  'Tykerb (Lapatinib Ditosylate)',
  'Unituxin (Dinutuximab)',
  'Uridine Triacetate',
  'VAC',
  'VAMP',
  'Vandetanib',
  'Varubi (Rolapitant Hydrochloride)',
  'VEC',
  'Vectibix (Panitumumab)',
  'VeIP',
  'Velban (Vinblastine Sulfate)',
  'Velcade (Bortezomib)',
  'Velsar (Vinblastine Sulfate)',
  'Vemurafenib',
  'Venclexta (Venetoclax)',
  'Venetoclax',
  'Viadur (Leuprolide Acetate)',
  'Vidaza (Azacitidine)',
  'Vinblastine Sulfate',
  'Vinca alkaloids',
  'Vincasar PFS (Vincristine Sulfate)',
  'Vincristine Sulfate',
  'Vincristine Sulfate Liposome',
  'Vindesine',
  'Vinorelbine Tartrate',
  'VIP',
  'Vismodegib',
  'Vistogard (Uridine Triacetate)',
  'Voraxaze (Glucarpidase)',
  'Vorinostat',
  'Votrient (Pazopanib Hydrochloride)',
  'VP',
  'Wellcovorin (Leucovorin Calcium)',
  'Xalkori (Crizotinib)',
  'XELIRI',
  'Xeloda (Capecitabine)',
  'XELOX',
  'Xgeva (Denosumab)',
  'Xofigo (Radium 223 Dichloride)',
  'Xtandi (Enzalutamide)',
  'Yervoy (Ipilimumab)',
  'Yondelis (Trabectedin)',
  'Zaltrap (Ziv-Aflibercept)',
  'Zarxio (Filgrastim)',
  'Zelboraf (Vemurafenib)',
  'Zevalin (Ibritumomab Tiuxetan)',
  'Zinecard (Dexrazoxane Hydrochloride)',
  'Ziv-Aflibercept',
  'Zofran (Ondansetron Hydrochloride)',
  'Zoladex (Goserelin Acetate)',
  'Zoledronic Acid',
  'Zolinza (Vorinostat)',
  'Zometa (Zoledronic Acid)',
  'Zydelig (Idelalisib)',
  'Zykadia (Ceritinib)',
  'Zytiga (Abiraterone Acetate)'
];

export default conventionalTreatmentAgents;
