import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import defaultStyles from './modalGroup.css';
import { IoMdSearch, IoIosArrowDown } from 'react-icons/io';

const propTypes = {
  optionsArray: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  openModalGroupBool: PropTypes.bool,
  modalFunctions: PropTypes.shape({
    openCallback: PropTypes.func.isRequired,
    closeCallback: PropTypes.func.isRequired
  }),
  setCallback: PropTypes.func.isRequired,
  includeAllOption: PropTypes.bool.isRequired,
  styles: PropTypes.object
};

//optionsArray looks like [Label, All options, option1, option2, etc]
//["Tumor Type", "All Tumor Types", "Bladder", etc]

export default function Modal({ optionsArray, openModalGroupBool = false, modalFunctions = {}, setCallback, includeAllOption, styles = defaultStyles }) {
  const { showModal, hideModal, modalLabel, modal, modalSearch } = styles;
  const [modalLabelText, allOption] = optionsArray;
  const individualDisplayOptions = optionsArray.slice(2);
  const initializeState = () => {
    const initialState = individualDisplayOptions.reduce((acc, filterOptions) => {
      return { ...acc, [filterOptions]: false };
    }, {});
    if(includeAllOption) initialState[allOption] = true;
    return initialState;
  };
  const [modalState, setModalState] = useState(false);
  const [optionFilterText, setOptionFilterText] = useState('');
  const [optionSnapshot, setOptionSnapshot] = useState([]);
  const [optionsState, setOptionsState] = useState(initializeState);

  //these refs manage focus
  const modalSearchBox = useRef(null);
  const modalContainer = useRef(null);
  const initialRender = useRef(true);
  //keep ref to modal to allow clicking out to exit
  const modalRef = useRef(null);
  //store state prior to opening modal so it can be reverted if user clicks outside of it
  const revertOptions = useRef(optionsState);

  const clickEventListener = ({ target }) => {
    const { current: clickedElement } = modalRef;
    if(clickedElement && (target !== clickedElement && !clickedElement.contains(target))) {
      closeModal();
    }
  };

  useEffect(() => {
    if(modalState) {
      document.body.addEventListener('click', clickEventListener);
      modalSearchBox.current.focus();
    }
    else {
      document.body.removeEventListener('click', clickEventListener);
      if(!initialRender.current) modalContainer.current.focus();
    }
    initialRender.current = false;
    return () => document.body.removeEventListener('click', clickEventListener);
  }, [modalState]);

  //keep track of true options
  const trueOptions = Object.entries(optionsState)
    .filter(entry => entry[1])
    .map(trueEntry => trueEntry[0]);

  //To open a modal, make sure no other modal is open, then set state to trigger a change in style. Let modal container know that a snapshot is open so that no others can open. Set a snapshot to keep track of any changes when the modal is finally closed.
  const openModal = () => {
    if(modalState) return;
    if(!openModalGroupBool) {
      setModalState(true);
      setOptionSnapshot(trueOptions);
      revertOptions.current = optionsState;
      if(modalFunctions) modalFunctions.openCallback();
    }
  };
  const handleKeyboardModalOpen = ({ key }) => {
    if(key === 'Enter') openModal();
  };

  //On modal close, pass trueOptions to the parent. Trigger a style change to close the modal, and let the parent know the modal has closed. If there was a change in selected options, execute a new search if a searchCallback was provided. Return focus to the modal container.
  const closeModal = (event = null) => {
    if(event) { // there will only be an event if the user clicked the 'Apply' button or user hits enter key.
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      event.preventDefault();
      revertOptions.current = optionsState;
      if(optionSnapshot.toString() !== trueOptions.toString()) setCallback({ [modalLabelText]: trueOptions });
    }
    else {
      const revertState = { ...revertOptions.current };
      setOptionsState(revertState);
    }
    setOptionFilterText('');
    setModalState(false);
    modalContainer.current.focus();
    if(modalFunctions) modalFunctions.closeCallback();
  };
  const handleKeyboardModalClose = event => {
    if(event.key === 'Escape') closeModal();
    if(event.key === 'Enter') closeModal(event);
  };

  const handleOptionFilterText = ({ target }) => setOptionFilterText(target.value);

  //handle toggling of a checkbox (except the first, which uses a different callback). If the All option is in use, enforce rules for checkbox selection.
  const handleOptionClick = ({ target }) => {
    const newState = {
      ...optionsState,
      [target.value]: !optionsState[target.value]
    };
    if(includeAllOption && newState[allOption]) newState[allOption] = false;
    if(includeAllOption && !Object.values(newState).includes(true)) newState[allOption] = true;
    setOptionsState(newState);
  };

  //Prepare checkboxes and labels for rendering (except All option, which will be handled separately). Filter rendered options based on optionFilterText.
  const generateOptionHTML = (option, onClickCallback) => (
    <label key={option}>
      <input type='checkbox' checked={optionsState[option]} value={option} onChange={onClickCallback}  />{option}
    </label>
  );

  const filteredArray = optionFilterText ?
    individualDisplayOptions.filter(option => {
      if(trueOptions.includes(option)) return true;
      if(optionSnapshot.includes(option)) return true;
      const lowerCaseOption = option.toLowerCase();
      const lowerCaseFilterText = optionFilterText.toLowerCase();
      if(lowerCaseOption.includes(lowerCaseFilterText)) return true;
      return false;
    }) : 
    individualDisplayOptions;

  const optionCheckboxes = filteredArray.map(option => generateOptionHTML(option, handleOptionClick));

  let firstOption = null;
  if(includeAllOption && allOption.toLowerCase().includes(optionFilterText.toLocaleLowerCase()))
    firstOption = generateOptionHTML(allOption, () => setOptionsState(initializeState));

  const openCloseModal = modalState ? showModal : hideModal;
  const dropdownIcon = <IoIosArrowDown style={{ backgroundColor: '#af94ae', color: 'white', borderRadius: '100%' }}/>;

  const displayText = (includeAllOption && optionsState[allOption]) ? allOption : `${trueOptions.length} ${optionsArray[0]}` || 'None';

  return (
    <>
      <span className={modalLabel} aria-label={`${optionsArray[0]} filter options`} role="">{modalLabel}</span>
      <span>
        <div ref={modalContainer} className={modal} onClick={openModal} tabIndex={0} onKeyPress={handleKeyboardModalOpen}>
          {displayText} &nbsp; {dropdownIcon}
          <div ref={modalRef} className={openCloseModal} onKeyDown={handleKeyboardModalClose}>
            <div className={modalSearch}>
              <button onClick={closeModal}>Search &nbsp;<IoMdSearch style={{ backgroundColor: '#af94ae', color: 'white', borderRadius: '100%' }}/></button>
              <input ref={modalSearchBox} type='text' value={optionFilterText} onChange={handleOptionFilterText} autoFocus/>
            </div>
            {firstOption}
            {optionCheckboxes}
          </div>
        </div>
      </span>
    </>
  );
}

Modal.propTypes = propTypes;
