import { saveAs } from 'file-saver';

export const exportUsers = (userList, orgName) => {
  const [headerObj] = userList;
  delete headerObj._id;
  delete headerObj.last_modified;

  const headerArray = Object.keys(headerObj);
  const formattedHeaderArray = headerArray.map(colName => {
    let splitStr = colName
      .replace(/_/gm, ' ')
      .split(' ');

    for(let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i]
        .charAt(0)
        .toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  });
  
  let csvString = formattedHeaderArray.join(',');
  csvString += '\n';

  csvString += userList.reduce((acc, curr) => {
    delete curr._id;
    delete curr.last_modified;
    delete curr.password_hash;
    curr.organizations = orgName;

    const userInfoArray = Object.values(curr);
    const formattedInfoArray = userInfoArray
      .map(field => {
        if(typeof(field) != 'string') return field;
        return field.replace(/,/gm, '')
          .replace(/\n/gm, '')
          .replace(/\r/gm, '');
      });
    const userString = formattedInfoArray.join(',');
    acc += userString + '\n';
    return acc;
  }, '');

  const csvBlob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
  saveAs(csvBlob, `${orgName} Org User List.csv`);
};
