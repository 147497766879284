// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".YzEgHL5WHzcaDCmjNvGAW {\n  margin: 3rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n  \n  .YzEgHL5WHzcaDCmjNvGAW section {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 80%;\n    border: 1px solid #dddddd;\n    border-radius: 10px;\n  }\n  \n  .YzEgHL5WHzcaDCmjNvGAW p {\n    margin: 1rem;\n    text-align: center;\n  }\n  \n  .YzEgHL5WHzcaDCmjNvGAW span:hover {\n    cursor: pointer;\n  }\n\n@media (max-width: 425px) {\n    .YzEgHL5WHzcaDCmjNvGAW section {\n      width: 100%;\n    }\n}", "",{"version":3,"sources":["joinNow.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AAmBzB;;EAjBE;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,yBAAyB;IACzB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;AAGF;IAEI;MACE,WAAW;IACb;AAEJ","file":"joinNow.css","sourcesContent":[".joinContainer {\n  margin: 3rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  \n  section {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 80%;\n    border: 1px solid #dddddd;\n    border-radius: 10px;\n  }\n  \n  p {\n    margin: 1rem;\n    text-align: center;\n  }\n\n  span:hover {\n    cursor: pointer;\n  }\n}\n\n@media (max-width: 425px) {\n  .joinContainer {\n    section {\n      width: 100%;\n    }\n  }\n}"]}]);
// Exports
exports.locals = {
	"joinContainer": "YzEgHL5WHzcaDCmjNvGAW"
};
module.exports = exports;
