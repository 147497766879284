import { searchSummariesWithFilter, searchSummariesWithFilterBeta, searchSupportLitWithFilter, searchSupportLitWithFilterBeta } from '../../../services/studies';
export default function executeSearch(searchQuery, setSummarizedCallback, setSupportCallback = null, betaSearch = false) {
  const promiseArray = [
    !betaSearch ? searchSummariesWithFilter(searchQuery) : searchSummariesWithFilterBeta(searchQuery)
  ];
  if(setSupportCallback) promiseArray.push(!betaSearch ? searchSupportLitWithFilter(searchQuery) : searchSupportLitWithFilterBeta(searchQuery));
  
  return Promise.all(promiseArray)
    .then(results => {
      results.forEach(({ promiseId, res }) => {
        switch(promiseId) {
          case 'summarized':
            setSummarizedCallback(res);
            break;
          case 'support':
            setSupportCallback(res);
            break;
        }
      });
      return results;
    })
    .catch(error => console.log(error)); // eslint-disable-line no-console
}
