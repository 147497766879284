import { request } from './request';

export const userSignup = userInfo => {
  return request('/invitations/signup', 'POST', userInfo);
};

export const invitePaidUser = (transactionId, emailAddress, code = '') => {
  return request('/invitations/licensee-invite', 'POST', { transactionId, emailAddress, code });
};

export const noInviteSignup = (userInfo, uid, referrer) => {
  return request('/auth/no-invite-signup', 'POST', { userInfo, uid, referrer });
};

export const inviteFreeTrialUser = async (emailAddress) => {
  return request('/invitations/free-trial', 'POST', { emailAddress });
};
