import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { autoSetUser, autoSignupAndLogin } from '../../data/actions/userAuthActions';
import { getUserLoading } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import styles from './autoAuth.css';
import ReactGA from 'react-ga';

export default function AutoAuth() {
  const navigate = useNavigate();
  const userLoading = useSelector(getUserLoading);
  const dispatch = useDispatch();
  const [signupFlag, setSignupFlag] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [displayName, setDisplayName] = useState('');

  const url = new URL(window.location);
  const referrer = url.searchParams.get('referrer');
  const uid = url.searchParams.get('uid');

  useEffect(() => {
    dispatch(autoSetUser(referrer, uid))
      .then(user => {
        setLoading(false);
        if(user?._id) {
          ReactGA.set({
            userId: user._id,
            sessionStart: Date.now()
          });
          ReactGA.event({
            category: 'User',
            action: 'Auto Login',
            label: user.organizations
          });
          navigate('/', { replace: true });
        }
        else if(user?.toString() === '[object Object]') setSignupFlag(true);
        else navigate('/', { replace: true });
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleEmailChange = ({ target }) => setEmail(target.value);
  const handleFirstNameChange = ({ target }) => setFirstName(target.value);
  const handleLastNameChange = ({ target }) => setLastName(target.value);
  const handleDisplayNameChange = ({ target }) => setDisplayName(target.value);

  const handleSubmit = event => {
    event.preventDefault();
    const userInfo = {
      email, 
      first_name: firstName,
      last_name: lastName,
      display_name: displayName
    };
    dispatch(autoSignupAndLogin(userInfo, uid, referrer))
      .then(user => {
        if(user.email && !userLoading) {
          ReactGA.set({
            userId: user._id,
            sessionStart: Date.now()
          });
          ReactGA.event({
            category: 'User',
            action: 'Automatic Signup/Login',
            label: user.organizations
          });
        }
        navigate('/', { replace: true });
      })
      .catch(err => {
        console.log(err);
      });
  };

  if(!referrer || !uid) navigate('/', { replace: true });
  if(userLoading || loading) return <LoadingSpinner />;
  if(signupFlag) return (
    <div className={styles.signupContainer}>
      <h1>Welcome!</h1>
      <p>
        Please provide us a little information about yourself. We have plans to implement some new features in the near future that will depend on this information.
      </p>
      <p>
        After registration, you will be automatically logged in whenever you navigate to our website from your resources portal.
      </p>
      <div className={styles.formContainer}>
        <form>
          <label htmlFor='email'>Email Address</label>
          <input id='email' type='text' value={email} onChange={handleEmailChange} placeholder='Email Address' />
          <label htmlFor='firstName'>First Name</label>
          <input id='firstName' type='text' value={firstName} onChange={handleFirstNameChange} placeholder='First Name' />
          <label htmlFor='lastName'>Last Name</label>
          <input id='lastName' type='text' value={lastName} onChange={handleLastNameChange} placeholder='Last Name' />
          <label htmlFor='displayName'>Display Name</label>
          <input id='displayName' type='text' value={displayName} onChange={handleDisplayNameChange} placeholder='Display Name' />
          <button onClick={handleSubmit}>Register</button>
        </form>
      </div>
    </div>
  );
  
  //basically to avoid rendering nothing
  return (
    <>
      <p>Redirecting...</p>
    </>
  );
}
