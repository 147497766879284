import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { paramCase } from 'param-case';
import { getProvider, getUser } from '../../data/selectors/userAuthSelectors';
import { fetchFocuses, fetchProfessions, submitHeadshot, submitProvider, updateProvider } from '../../services/providers';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import styles from './providerSignup.css';
import { provinceList, stateList } from '../../utils/addressData';
import { setProvider, setProviderEditing } from '../../data/actions/userAuthActions';
import 'regenerator-runtime/runtime';
import PhoneContainer from './phoneNumbers/PhoneContainer';
import LocationsContainer from './locations/LocationsContainer';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';

const deduceRegion = provider => {
  const result = {};
  if(!provider) return result;
  const providerAreas = provider.serviceAreas?.map(area => area.name) || [];

  providerAreas.forEach(area => {
    if(provinceList.includes(area)) result['Canada'] = true;
    if(stateList.includes(area)) result['USA'] = true;
  });

  if(!result['Canada'] && !result['USA']) result['Other'] = true;

  return result;
};

export default function EditProvider() {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const provider = useSelector(getProvider);

  const [loading, setLoading] = useState(false);

  const [locations, setLocations] = useState(provider?.locations || []);
  const [designations, setDesignations] = useState(provider?.designations || '');
  const [title, setTitle] = useState(provider?.title || '');
  const [bio, setBio] = useState(provider?.bio || '');
  const [rawProfessions, setRawProfessions] = useState([]);
  const [selectedProfessions, setSelectedProfessions] = useState(provider?.professions?.reduce((acc, curr) => {
    acc[curr.name] = true;
    return acc;
  }, {}) || {});
  const [rawFocuses, setRawFocuses] = useState([]);
  const [selectedFocuses, setSelectedFocuses] = useState(provider?.focuses?.reduce((acc, curr) => {
    acc[curr.name] = true;
    return acc;
  }, {}) || {});
  const [providerPhoneNumbers, setProviderPhoneNumbers] = useState(provider?.phoneNumbers || []);
  const [region, setRegion] = useState(deduceRegion(provider));
  const [serviceAreas, setServiceAreas] = useState(provider?.serviceAreas?.reduce((acc, curr) => {
    acc[curr.name] = true;
    return acc;
  }, {}) || {});
  const [slug, setSlug] = useState(provider?.slug || `${paramCase(user.first_name + '-' + user.last_name)}`);
  const [thumbSrc, setThumbSrc] = useState(provider?.headshot || '');
  const [newHeadshotFlag, setNewHeadshotFlag] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  
  const profRef = useRef(null);
  const focusesRef = useRef(null);
  const regionRef = useRef(null);
  const serviceAreasRef = useRef(null);
  const headshotRef = useRef(null);
  const thumbnailRef = useRef(null);

  // if(!user.email && !user.isEditingProvider) return <Redirect to='/login#provider-signup' />;
  // if(!user.email) return <Redirect to='/login#provider-signup' />;
  useEffect(() => {
    if(!user.email) navigate('/login#edit-provider');
  }, [user.email, navigate]);

  let allowProvider = false;
  try {
    const sessionCookie = cookies.get('session');
    const cookiePayload = jwt_decode(sessionCookie);
    allowProvider = cookiePayload.allow_provider;
  // eslint-disable-next-line no-empty
  } catch(err) {}

  useEffect(() => {
    if(!allowProvider) navigate('/providers');
  }, [allowProvider]);
  
  useEffect(() => {
    Promise.all([fetchProfessions(), fetchFocuses()])
      .then(([professionData, focusesData]) => {
        // put the 'Other' options last on the list

        professionData.sort();
        professionData.push(professionData.splice(professionData.indexOf('Other'), 1)[0]);
        setRawProfessions(professionData);
        
        focusesData.sort();
        focusesData.push(focusesData.splice(focusesData.indexOf('Other'), 1)[0]);
        setRawFocuses(focusesData);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const newServiceAreas = { ...serviceAreas };
    if(!region['USA']) {
      stateList.forEach(state => newServiceAreas[state] = false);
    }
    if(!region['Canada']) {
      provinceList.forEach(province => newServiceAreas[province] = false);
    }
    setServiceAreas(newServiceAreas);
  }, [region]);

  if(loading) {
    return <LoadingSpinner />;
  }

  const handleDesignation = ({ target }) => setDesignations(target.value);
  const handleTitles = ({ target }) => setTitle(target.value);
  const handleBio = ({ target }) => setBio(target.value);
  const handleProfChange = event => {
    event.stopPropagation();
    setSelectedProfessions({
      ...selectedProfessions,
      [event.target.value]: !selectedProfessions[event.target.value]
    });
  };
  const handleFocusChange = event => {
    event.stopPropagation();
    setSelectedFocuses({
      ...selectedFocuses,
      [event.target.value]: !selectedFocuses[event.target.value]
    });
  };
  const handleSlug = ({ target }) => setSlug(paramCase(target.value));
  const handleProviderPhoneChange = newNumbers => setProviderPhoneNumbers(newNumbers);
  const handleLocationsChange = newLocations => setLocations(newLocations);
  const handleRegionChange = event => {
    event.stopPropagation();
    setRegion({
      ...region,
      [event.target.value]: !region[event.target.value]
    });
  };
  const handleAreaServedChange = event => {
    event.stopPropagation();
    setServiceAreas({
      ...serviceAreas,
      [event.target.value]: !serviceAreas[event.target.value]
    });
  };

  const handleHeadshotInput = () => {
    const headshot = headshotRef.current;
    const [file] = headshot.files;
    if(file) {
      setNewHeadshotFlag(true);
      setThumbSrc(URL.createObjectURL(file));
      thumbnailRef.current.style = { height: '10em' };
      thumbnailRef.current.style['object-fit'] = 'contain';
    }
  };

  const handleSubmit = async() => {
    let headshotData;
    if(newHeadshotFlag) {
      const headshot = headshotRef.current.files[0];
      headshotData = new FormData();
  
      const operations = JSON.stringify({
        query: `
        mutation uploadHeadshot($file: Upload!) {
          uploadHeadshot(headshot: { image: $file })
            {
              message
              url
            }
        }`,
        variables: {
          file: null
        }
      });
      const map =	`{
        "0": ["variables.file"]
      }`;
  
      headshotData.append('operations', operations);
      headshotData.append('map', map);
      headshotData.append('0', headshot);
    }

    const validProfession = validateProfession();
    if(!validProfession) {
      profRef.current.style.border = '3px solid red';
      profRef.current.scrollIntoView();
      return;
    } else {
      profRef.current.style.border = 'none';
    }

    const validFocuses = validateFocuses();
    if(!validFocuses) {
      focusesRef.current.style.border = '3px solid red';
      focusesRef.current.scrollIntoView();
      return;
    } else {
      focusesRef.current.style.border = 'none';
    }
    
    const validRegion = validateRegion();
    if(!validRegion) {
      regionRef.current.style.border = '3px solid red';
      regionRef.current.scrollIntoView();
    } else {
      regionRef.current.style.border = 'none';
    }

    const validServiceAreas = validateServiceAreas();
    if(!validServiceAreas) {
      serviceAreasRef.current.style.border = '3px';
      serviceAreasRef.current.scrollIntoView();
    } else {
      if(serviceAreasRef.current) serviceAreasRef.current.style.border = 'none';
    }

    setLoading(true);

    locations.forEach(location => {
      location.phoneNumbers?.forEach(number => delete number.id);
      delete location.id;
      delete location.latitude;
      delete location.longitude;
    });
    providerPhoneNumbers?.forEach(phoneNumber => delete phoneNumber.id);

    const formattedProvider = {
      designations,
      title,
      bio,
      professions: Object.keys(selectedProfessions)
        .filter(prof => selectedProfessions[prof])
        .map(prof => ({ name: prof })),
      focuses: Object.keys(selectedFocuses)
        .filter(focus => selectedFocuses[focus])
        .map(focus => ({ name: focus })),
      serviceAreas: Object.keys(serviceAreas)
        .filter(area => serviceAreas[area])
        .map(area => ({ name: area })),
      slug,
      phoneNumbers: !providerPhoneNumbers ? [] : providerPhoneNumbers,
      locations,
      id: provider?.id
    };

    try {
      const providerRes = provider?.id ? await updateProvider(formattedProvider) : await submitProvider(formattedProvider);

      if(newHeadshotFlag) {
        const headshotRes = await submitHeadshot(headshotData);
        providerRes.headshot = headshotRes.data.uploadHeadshot.url;
      }
      dispatch(setProvider({
        ...providerRes,
        headshot: newHeadshotFlag ? providerRes?.headshot : provider?.headshot
      }));
      dispatch(setProviderEditing(false));
      setLoading(false);
      navigate(`/provider/${providerRes.slug}`, { replace: true });
    } catch(err) {
      console.log(err);
      dispatch(setProviderEditing(false));
      setLoading(false);
    }
  };

  const handleCancelEdit = () => {
    dispatch(setProviderEditing(false));
    navigate(provider ? `/provider/${provider.slug}` : '/', { replace: true });
  };

  const profDropdownItems = rawProfessions.map(prof => {
    return (
      <label 
        className={styles.profOption}
        key={prof}
      >
        <input
          type='checkbox'
          value={prof}
          checked={selectedProfessions[prof]} 
          onChange={handleProfChange}
        /> {prof}
      </label>
    );
  });

  const focusDropdownItems = rawFocuses.map(focus => {
    return (
      <label
        className={styles.profOption}
        key={focus}
      >
        <input 
          type='checkbox'
          value={focus}
          checked={selectedFocuses[focus]}
          onChange={handleFocusChange}
        /> {focus}
      </label>
    );
  });

  const regionDropdownItems = ['USA', 'Canada', 'Other'].map(regionItem => (
    <label className={styles.profOption} key={regionItem}>
      <input 
        type='checkbox'
        value={regionItem}
        checked={region[regionItem]}
        onChange={handleRegionChange}
      /> {regionItem}
    </label>
  ));
  const getServiceAreasItems = () => {
    let areasList = [];
    if(region['USA'] && region['Canada']) areasList = [...stateList, ...provinceList];
    else if(region['Canada']) areasList = [...provinceList];
    else if(region['USA']) areasList = [...stateList];
    else return null;
    
    const areasItems = areasList.map(area => (
      <label key={area} className={styles.profOption}>
        <input 
          type='checkbox'
          value={area}
          checked={serviceAreas[area]}
          onChange={handleAreaServedChange}
        /> {area}
      </label>
    ));
    
    return (
      <label className={styles.formLabel} ref={serviceAreasRef}>*Please specify the areas you serve
        <div className={styles.profSection}>
          {areasItems}
        </div>
      </label>
    );
  };

  const validateProfession = () => true; // () => Object.values(selectedProfessions).includes(true);
  const validateFocuses =  () => true; // () => Object.values(selectedFocuses).includes(true);
  const validateRegion =  () => true; // () => Object.values(region).includes(true);
  const validateServiceAreas =  () => true; // () => Object.values(serviceAreas).includes(true) || region['Other'];

  const disableSubmitCB = (bool) => setDisableSubmit(bool);

  return (
    <div className={styles.signupContainer}>
      <h2>Edit Provider Information</h2>
      <p>
        You are currently editing your provider information
      </p>
      <form
        onSubmit={handleSubmit}
        className={styles.signupForm}
      >
        <p>* indicates a required field.</p>
        {<img
          ref={thumbnailRef}
          src={thumbSrc}
          style={thumbSrc ? { height: '10em', objectFit: 'contain' } : { display: 'hidden' }}
        />}
        <label className={styles.headshotContainer}>Headshot
          <input
            type='file'
            required={false}
            name='filename'
            ref={headshotRef}
            onInput={handleHeadshotInput}
          />
        </label>
        <label className={styles.formLabel}>Designations - the initials after your name
          <input 
            required={false}
            type='text' 
            placeholder='PhD, MD, ND etc' 
            value={designations} 
            onChange={handleDesignation} 
          />
        </label>
        <label className={styles.formLabel}>Titles - your jobs title(s)
          <input 
            required={false}
            type='text' 
            placeholder='e.g.: Senior Reasearch Associate' 
            value={title} 
            onChange={handleTitles} 
          />
        </label>
        <label className={styles.formLabel}>*Bio - a brief description of your skills and experience
          <textarea 
            required={true}
            placeholder='I have over 25 years experience treating different types of skin cancer.'
            rows={4}
            value={bio} 
            onChange={handleBio} 
          />
        </label>
        <label className={styles.formLabel} ref={profRef}>*Professions
          <div className={styles.profSection}>
            {profDropdownItems}
          </div>
        </label>
        <label className={styles.formLabel} ref={focusesRef}>*Specialties or areas of focus
          <div className={styles.profSection}>
            {focusDropdownItems}
          </div>
        </label>
        <label className={styles.formLabel} ref={regionRef}>Geographic areas you serve
          <div className={styles.profSection}>
            {regionDropdownItems}
          </div>
        </label>
        {(region['USA'] || region['Canada']) && getServiceAreasItems()}
        <div style={{ width: '60%', padding: '5px', border: '2px solid rgba(169, 169, 169, 0.3)' }}>
          <p>List phone numbers to reach you directly (peer-to-peer).</p>
          <p>Only other KNOW website members can see these numbers.</p>
          <PhoneContainer
            setParentCB={handleProviderPhoneChange}
            required={false}
            editingNumbers={providerPhoneNumbers}
            disableSubmitCB={disableSubmitCB}
          />
        </div>
        <label > <span style={{ marginRight: '1em' }}>*Your provider URL</span>
          <span>
            www.knowintegrativeoncology/provider/
            <input 
              required={true}
              type='text'
              value={slug}
              onChange={handleSlug}
              style={{ width: '15vw', display: 'inline' }}
            />
          </span>
        </label>
        <LocationsContainer
          setParentCB={handleLocationsChange}
          require={true}
          editingLocations={locations}
          disableSubmitCB={disableSubmitCB}
        />
        
        <div>
          <button className={styles.submitButton} onClick={handleCancelEdit}>
            Cancel
          </button>
          <input
            type='submit'
            className={styles.submitButton}
            value='Submit'
            disabled={disableSubmit}
          />
        </div>
      </form>
    </div>
  );
}
