import React, { useState, useEffect } from 'react';
import styles from './feedback.css';
import { submitFeedback } from '../../services/feedback';
import { useSelector } from 'react-redux';
import { getUser, getUserLoading } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

export default function Feedback() {
  const [feedback, setFeedback] = useState('');
  const [message, setMessage] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [successfulSubmissionFlag, setSuccessfulSubmissionFlag] = useState(false);
  // Since ccnm is not required to register their users, user must supply their email address to submit feedback.
  const [ccnmEmail, setCcnmEmail] = useState('');
  const { email } = useSelector(getUser);
  const userLoading = useSelector(getUserLoading);
  const { feedbackContainer, imAButton } = styles;

  useEffect(() => {
    document.title = 'KNOW - Feedback';
  }, []);

  useEffect(() => {
    if(feedback) {
      if(email === 'ccnm@ccnm.org') {
        if(ccnmEmail) setSubmitDisabled(false);
        else setSubmitDisabled(true);
      } else setSubmitDisabled(false);
    }
    else setSubmitDisabled(true);
  }, [feedback]);

  useEffect(() => {
    if(ccnmEmail && feedback) setSubmitDisabled(false);
    else setSubmitDisabled(true);
  }, [ccnmEmail]);

  if(userLoading || submitLoading) {
    return <LoadingSpinner />;
  }

  if(!email) {
    return (
      <div className={feedbackContainer}>
        <h1>You must be logged in to submit feedback</h1>
      </div>
    );
  }
  
  const handleFeedbackChange = event => setFeedback(event.target.value);
  const handleCcnmEmailChange = event => setCcnmEmail(event.target.value);

  const handleSubmit = event => {
    event.preventDefault();

    const fromEmail = email === 'ccnm@ccnm.org' ? ccnmEmail : email;

    setSubmitLoading(true);
    submitFeedback(fromEmail, feedback)
      .then(() => {
        setMessage('Thank you for your feedback!');
        setSubmitLoading(false);
        setSuccessfulSubmissionFlag(true);
      })
      .catch(err => {
        setMessage(err.message);
      });
  };

  return (
    <div className={feedbackContainer} role="main" aria-label="feedback">
      <h1>Feedback</h1>
      <p>Please let us know if you have any comments!</p>
      <form onSubmit={handleSubmit}>
        {email === 'ccnm@ccnm.org' && 
          <label className={styles.emailContainer}>
            Email: <input type='text' value={ccnmEmail} onChange={handleCcnmEmailChange} placeholder='Email Address' />
          </label>
        }
        <textarea placeholder='comments' value={feedback} onChange={handleFeedbackChange} />
        <input type='submit' disabled={submitDisabled || successfulSubmissionFlag} className={imAButton} value='Send feedback' />
      </form>
      <p>
        {message}
      </p>
    </div>
  );
}
