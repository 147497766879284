// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2HvAgBhstdBVwpw0EEQJL6 {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n  align-items: center;\n  margin: 3rem;\n}\n\n@media (max-width: 768px) {\n  ._2HvAgBhstdBVwpw0EEQJL6 {\n    margin: 1rem;\n  }\n}", "",{"version":3,"sources":["iowgSurvey.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;AACF","file":"iowgSurvey.css","sourcesContent":[".iowgSurvey {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  text-align: center;\n  align-items: center;\n  margin: 3rem;\n}\n\n@media (max-width: 768px) {\n  .iowgSurvey {\n    margin: 1rem;\n  }\n}"]}]);
// Exports
exports.locals = {
	"iowgSurvey": "_2HvAgBhstdBVwpw0EEQJL6"
};
module.exports = exports;
