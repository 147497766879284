// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2sFf1vvaCEVoiPaf3MGa6x {\n}\n\n._1unoQlSdXPIN7gWAtYyjZF {\n  margin: 1rem 10vw 1rem 10vw;\n  display: grid;\n  grid-template-columns: 45% 45%;\n  justify-content: space-around;\n  min-width: -moz-fit-content;\n  min-width: fit-content;\n}\n\n._2-UjD-mO_S_Zbz-zf0yfzG {\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["providerNetwork.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,2BAA2B;EAC3B,aAAa;EACb,8BAA8B;EAC9B,6BAA6B;EAC7B,2BAAsB;EAAtB,sBAAsB;AACxB;;AAEA;EACE,iBAAiB;AACnB","file":"providerNetwork.css","sourcesContent":[".providerContainer {\n}\n\n.resultsContainer {\n  margin: 1rem 10vw 1rem 10vw;\n  display: grid;\n  grid-template-columns: 45% 45%;\n  justify-content: space-around;\n  min-width: fit-content;\n}\n\n.noResults {\n  font-weight: bold;\n}\n"]}]);
// Exports
exports.locals = {
	"providerContainer": "_2sFf1vvaCEVoiPaf3MGa6x",
	"resultsContainer": "_1unoQlSdXPIN7gWAtYyjZF",
	"noResults": "_2-UjD-mO_S_Zbz-zf0yfzG"
};
module.exports = exports;
