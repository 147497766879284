import React, { useEffect, useState } from 'react';
import styles from './locationsStyles.css';
import PropTypes from 'prop-types';
import LocationsForm from './LocationsForm';

LocationsContainer.propTypes = {
  setParentCB: PropTypes.func,
  editingLocations: PropTypes.array,
  disableSubmitCB: PropTypes.func.isRequired
};

export default function LocationsContainer({ setParentCB, editingLocations = [], disableSubmitCB }) {
  const [locations, setLocations] = useState(editingLocations);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    if(isEditing || isAdding) disableSubmitCB(true);
    else disableSubmitCB(false);
  }, [isEditing, isAdding]);

  const handleAddLocation = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsAdding(true);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsAdding(false);
    setIsEditing(false);
  };

  const handleSubmitLocation = (newLocation) => {
    if(isAdding) {
      setLocations([...locations, newLocation]);
      setParentCB([...locations, newLocation]);
      setIsAdding(false);
    }
    if(isEditing) {
      const newLocations = [...locations];
      newLocations.splice(activeIndex, 1, newLocation);
      setLocations(newLocations);
      setParentCB(newLocations);
      setActiveIndex(null);
      setIsEditing(false);
    }
  };

  const handleDeleteLocation = (index, event) => {
    event.preventDefault();
    event.stopPropagation();

    const newLocations = [...locations];
    newLocations.splice(index, 1);
    setLocations(newLocations);
    setParentCB(newLocations);
  };

  const getAddButton = () => {
    if(isEditing || isAdding) return null;

    return (
      <label style={{ display: 'flex', justifyContent: 'center' }}>
        <input
          type="button"
          onClick={handleAddLocation}
          value="Add Location"
          className={styles.addButton}
        />
      </label>
    );
  };

  const locationItems = locations.map((location, i) => (
    <div className={styles.locationItem} key={location.name}>
      <span >{location.name}</span>
      {(!isAdding && !isEditing) && (
        <div className={styles.editDeleteButtons}>
          <input
            type="button"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              setActiveIndex(i);
              setIsEditing(true);
            }}
            value="Edit"
          />
          <input
            type="button"
            onClick={(e) => handleDeleteLocation(i, e)}
            value="Delete"
          />
        </div>
      )}
    </div>
  ));

  return (
    <div className={styles.locationsContainer}>
      <p>Business / Clinic Information</p>
      {locationItems.length > 0 ? <p>Locations</p> : null}
      {locationItems}
      {getAddButton()}
      {(isEditing || isAdding) && <LocationsForm 
        submitCallback={handleSubmitLocation}
        cancelCallback={handleCancel}
        editingLocation={locations[activeIndex] || {}}
        disableSubmitCB={disableSubmitCB}
      />}
    </div>
  );
}
