// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3kG-IxRL_7YQun7lEDVNjO {\n  display: flex;\n  flex-direction: column;\n  width: 100vw;\n  position: sticky;\n  background-color: #f69022;\n  color: black;\n  padding: .3em;\n  text-align: center;\n  z-index: 3;\n  bottom: 0;\n}\n\n  ._3kG-IxRL_7YQun7lEDVNjO ._15kHZwPvxRo2Qk2oD5ZeM6 {\n    color: darkblue;\n  }", "",{"version":3,"sources":["ipLoginNotice.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,UAAU;EACV,SAAS;AAKX;;EAHE;IACE,eAAe;EACjB","file":"ipLoginNotice.css","sourcesContent":[".noticeContainer {\n  display: flex;\n  flex-direction: column;\n  width: 100vw;\n  position: sticky;\n  background-color: #f69022;\n  color: black;\n  padding: .3em;\n  text-align: center;\n  z-index: 3;\n  bottom: 0;\n\n  .linkText {\n    color: darkblue;\n  }\n}"]}]);
// Exports
exports.locals = {
	"noticeContainer": "_3kG-IxRL_7YQun7lEDVNjO",
	"linkText": "_15kHZwPvxRo2Qk2oD5ZeM6"
};
module.exports = exports;
