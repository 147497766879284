import React, { useEffect, useState } from 'react';
import styles from './iowgSurvey.css';
import { request } from '../../services/request';

const inputStyle = {
  padding: '10px',
  margin: '10px 0',
  borderRadius: '4px',
  border: '1px solid #ccc',
  width: 'calc(100% - 22px)', // Adjust width for padding and border
};

const submitButtonStyle = {
  marginTop: '10px',
  padding: '10px 20px',
  fontSize: '16px',
  borderRadius: '4px',
  backgroundColor: '#8B62B7',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
};

const successMessageStyle = {
  color: '#8B62B7',
  marginTop: '20px',
};

const inviteIowgUser = async(email) => {
  const response = await request('/invitations/iowg-survey', 'POST', {
    emailAddress: email
  });
  return response;
};

export default function IOWGSurveyInvitation() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    document.title = 'KNOW - Welcome IOWG Members!';
  }, []);

  const handleSubmit = async(event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage('');

    try {
      // Assuming `inviteIowgUser(email)` internally uses the `request` wrapper function
      // and correctly handles throwing errors for non-OK responses.
      await inviteIowgUser(email);
      
      // Since the wrapper function throws for non-OK responses, if we reach here, the request was successful.
      setEmail('');
      setMessage('Success! Please check your email inbox for further instructions.');
    } catch(error) {
      console.error('Error submitting form:', error);
    
      // Check if the error is an object with a 'status' property
      if(error && error.status === 409) {
        setMessage('This email has already been used.');
      } else if(error instanceof Error) {
        // Handle case where error is an Error object, possibly from the timeout or network issue
        setMessage(error.message);
      } else {
        // Handle generic error messages or structured error data
        // Assuming error might be a parsed JSON object or plain text from the server
        const errorMessage = error.message || 'An error occurred. Please try again later and contact us for support if this persists.';
        setMessage(errorMessage);
      }
    }

    setIsSubmitting(false);
  };

  return (
    <div className={styles.iowgSurvey}>
      <br />
      <h3 style={{ fontSize: '20px' }}>Welcome IOWG Members!</h3>
      <br />
      <br />
      <strong>Claim your free month of access to KNOW 🎉</strong>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email"
          style={inputStyle}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" style={submitButtonStyle} disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
      </form>
      {message && <p style={successMessageStyle}>{message}</p>}
    </div>
  );
}
