import React from 'react';
import { highlighter } from '../../src/components/studies/results/results.css';

export default function highlightText(text, highlight) {
  if(typeof text !== 'string') return text;
  const parts = text.split(new RegExp(`(</?${highlight}>)`, 'gi'));
  
  const theHtml = [];
  const theHtmlStrings = [];
  parts.forEach((part, i, partsArray) => {
    const thePart = part.toLowerCase();
    const openHighlight = `<${highlight}>`.toLowerCase();
    const closeHighlight = `</${highlight}>`.toLowerCase();

    if(i > 0 && partsArray[i - 1] === openHighlight) {
      theHtml.push(<span key={i} className={highlighter}>{part}</span>);
      theHtmlStrings.push(`<span key=${i} class=${highlighter}>${part}</span>`);
    }
    else if(thePart !== openHighlight && thePart !== closeHighlight) {
      theHtml.push(part);
      theHtmlStrings.push(part);
    }
  });
  return {
    raw: theHtmlStrings.join(''),
    fragment:
      (
        <>
          {theHtml}
        </>
      )
  };
}
