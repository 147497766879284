import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setCookieBannerInfo } from '../../data/actions/stylesActions';
import { setCookiePref } from '../../data/actions/userAuthActions';
import { getCookiePref } from '../../data/selectors/userAuthSelectors';
import styles from './cookieConsent.css';

export default function CookieConsent() {
  const dispatch = useDispatch();
  const cookiePref = useSelector(getCookiePref);
  const bannerElement = useRef(null);

  //note, cookieBannerInfo does not get set if banner does not load.
  useEffect(() => {
    if(cookiePref === null) {
      const layoutInfo = bannerElement.current.getBoundingClientRect();
      dispatch(setCookieBannerInfo(layoutInfo));
    }
  }, []);

  if(cookiePref !== null) return null;
  
  const handleCookieAgree = () => {
    window.localStorage.setItem('cookiePref', true);
    dispatch(setCookiePref(true));
    dispatch(setCookieBannerInfo({ height: 0 }));
  };

  const handleCookieReject = () => {
    dispatch(setCookiePref(false));
    dispatch(setCookieBannerInfo({ height: 0 }));
  };

  return (
    <div className={styles.cookieBanner} ref={bannerElement}>
      <p>KNOW uses cookies or similar technologies as outlined in our <Link to={'/privacy-policy#cookies'} style={{ color: 'black', textDecoration: 'underline' }}>cookie policy</Link> to enhance your experience as a user. Please consent to the use of cookies by clicking the accept button.</p>
      <input type='button' onClick={handleCookieAgree} value="Accept"/>
      <input type='button' onClick={handleCookieReject} value="Reject"/>
    </div>
  );
}
