// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1Bh3ok4Pkv3QvaujJt82IZ, ._3Y1gAkb7MTlmVoVkKsakcg {\n  margin-bottom: 10px;\n  background-color: #f6f6f6;\n  border: 1px solid #dddddd;\n  border-radius: 1em;\n  padding: .6em;\n}\n\n._3Y1gAkb7MTlmVoVkKsakcg {\n  margin-right: .5em;\n}\n\n.aIui1SoGfdWTdjx_-wUE4 {\n  margin-right: .6em;\n}\n\n._1Bh3ok4Pkv3QvaujJt82IZ:disabled, ._3Y1gAkb7MTlmVoVkKsakcg:disabled {\n    background-color: #fff;\n\n}\n\n._1Bh3ok4Pkv3QvaujJt82IZ:enabled:hover, ._3Y1gAkb7MTlmVoVkKsakcg:enabled:hover {\n  cursor: pointer;\n}\n\n._1Bh3ok4Pkv3QvaujJt82IZ {\n  margin-right: 5px;\n}", "",{"version":3,"sources":["pagination.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;IACI,sBAAsB;;AAE1B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB","file":"pagination.css","sourcesContent":[".prev, .next {\n  margin-bottom: 10px;\n  background-color: #f6f6f6;\n  border: 1px solid #dddddd;\n  border-radius: 1em;\n  padding: .6em;\n}\n\n.next {\n  margin-right: .5em;\n}\n\n.pageOf {\n  margin-right: .6em;\n}\n\n.prev:disabled, .next:disabled {\n    background-color: #fff;\n\n}\n\n.prev:enabled:hover, .next:enabled:hover {\n  cursor: pointer;\n}\n\n.prev {\n  margin-right: 5px;\n}"]}]);
// Exports
exports.locals = {
	"prev": "_1Bh3ok4Pkv3QvaujJt82IZ",
	"next": "_3Y1gAkb7MTlmVoVkKsakcg",
	"pageOf": "aIui1SoGfdWTdjx_-wUE4"
};
module.exports = exports;
