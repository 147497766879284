import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { deleteProvider, getProvider } from '../../services/providers';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import styles from './providerResultDetail.css';
import ProviderLocation from './ProviderLocation';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../data/selectors/userAuthSelectors';
import { logoutUser, setProviderEditing } from '../../data/actions/userAuthActions';
import { useNavigate } from 'react-router-dom';
import { parsePhoneNumbers } from './utils';
import ConfirmModal from '../confirmModal/ConfirmModal';

ProviderResultDetail.propTypes = {
  providerResult: PropTypes.object
};

export default function ProviderResultDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState({});
  const user = useSelector(getUser);
  const initialRender = useRef(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    getProvider(params.slug)
      .then(data => {
        setProvider(data);
      })
      .catch(err => {
        console.log(err);
        navigate('/not_found', { replace: true });
      });
  }, []);

  useEffect(() => {
    if(!initialRender.current === true) setLoading(false);
    else initialRender.current = false;
  }, [provider]);

  if(loading) {
    return <LoadingSpinner />;
  }

  const locationItems = provider.locations ? provider.locations.map(location => (
    <ProviderLocation key={location.name} location={location} />
  )) : null;

  const professions = provider.professions ? provider.professions.map(profession => profession.name).join(', ') : null;

  const focuses = provider.focuses ? provider.focuses.map(focus => (
    <li key={focus.name}>
      {focus.name}
    </li>
  )) : null;

  const areasServed = provider.serviceAreas.map(area => area.name).join(', ');

  const phoneItems = parsePhoneNumbers(provider.phoneNumbers);

  const handleEditProfile = () => {
    dispatch(setProviderEditing(true));
    navigate('/edit-provider');
  };
  const handleOpenModal = () => setOpenConfirmation(true);
  const handleCloseModal = () => setOpenConfirmation(false);
  const handleDeleteProfile = () => {
    handleCloseModal();
    setLoading(true);
    deleteProvider(user.provider.id)
      .then(res => {
        const returnedUser = res?.data?.deleteProvider;
        if(returnedUser) {
          dispatch({ type: 'SET_USER', payload: returnedUser });
        }
        navigate('/providers', { replace: true });
      });
  };

  const editButton = () => {
    if(user.provider?.slug !== params.slug) return null;
    else { return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <button
          onClick={handleEditProfile}
        >
          Edit Your Provider Profile
        </button>
        <button
          onClick={handleOpenModal}
        >
          Delete Your Profile
        </button>
      </div>
    );}
  };

  const nameText = provider.designations ? `${provider.firstName} ${provider.lastName} ${provider.designations}` : `${provider.firstName} ${provider.lastName}`;

  return (
    <>
      <ConfirmModal isOpen={openConfirmation} confirmCB={handleDeleteProfile} cancelCB={handleCloseModal} />
      <div
        className={styles.providerContainer}
        style={provider.headshot ? {} : { display: 'flex' } }
      >
        {provider.headshot && <img src={provider.headshot}/>}
        <div className={styles.providerInfoContainer}>
          <h2>
            {nameText}
          </h2>
          <h3>
            {provider.title}
          </h3>
          <ul>
            {professions}
          </ul>
          {!!focuses.length && <ul className={styles.secondaryList}>Areas of focus:
            {focuses}
          </ul>}
          {!!areasServed && <p>Service Areas: {areasServed}</p>}
          <hr />
          <p className={styles.bio}>
            {provider.bio}
          </p>
          {user.email && <div className={styles.phoneNumbers}>
            {phoneItems}
          </div>}
          <div className={styles.locationContainer}>
            {locationItems}
          </div>
        </div>
      </div>
      {editButton()}
    </>
  );
}
