import React, { useRef } from 'react';
import SummarizedResultItem from './SummarizedResultItem';
import PropTypes from 'prop-types';
import styles from './results.css';

const propTypes = {
  studies: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.object).isRequired,
    fullCount: PropTypes.number,
    observationalCount: PropTypes.number,
    clinicalCount: PropTypes.number,
    systematicCount: PropTypes.number,
    caseCount: PropTypes.number,
    unknownCount: PropTypes.number
  }).isRequired,
  updateCallback: PropTypes.func.isRequired,
  searchFlag: PropTypes.bool.isRequired,
  filteredKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  // currentPage: PropTypes.number.isRequired
};

export default function SummarizedResults({ studies, 
  // searchFlag, 
  filteredKeys, 
  updateCallback,
  // currentPage 
}) {

  const caseReports = [];
  const clinicalTrials = [];
  const observationalStudies = [];
  const systematicReviews = [];
  const unknownStudyTypes = [];

  const detailRefs = {
    'sysrev': useRef(null),
    'clintri': useRef(null),
    'obser': useRef(null),
    'casrep': useRef(null),
    'pensum': useRef(null)
  };

  studies.results.forEach(study => {
    const studyListItem =
        <li key={study._id}>
          <SummarizedResultItem study={study} isOpen={false} filteredKeys={filteredKeys} updateCallback={updateCallback}/>
        </li>;

    switch(study.studyTypeInt) {
      case 1:
        systematicReviews.push(studyListItem);
        break;
      case 2:
        clinicalTrials.push(studyListItem);
        break;
      case 3:
        observationalStudies.push(studyListItem);
        break;
      case 4:
        caseReports.push(studyListItem);
        break;
      default:
        unknownStudyTypes.push(studyListItem);
    }
  });

  window.onhashchange = () => {
    const hashValue = window.location.hash.slice(1);
    if(hashValue && hashValue !== 'suplit') {
      detailRefs[hashValue].current.open = true;
      detailRefs[hashValue].current.scrollIntoView();
    }
  };

  // const calcStudiesCount = () => {
  //   const countMin = currentPage * 20 - 19;
  //   let countMax = countMin + 19;
  //   if(countMax > studies.fullCount) countMax = studies.fullCount;
  //   if(countMin > studies.fullCount) {
  //     return 'No more results';
  //   }
  //   return `${countMin} - ${countMax} of ${studies.fullCount}`;
  // };

  return (
    <>
      {/* <h2>
        For studies header with pagination:
        <strong>Studies{searchFlag && ` (${calcStudiesCount()})`}:</strong> &nbsp; 

        For studies header without pagination: 
        <strong>Studies{searchFlag && ` (${studies.fullCount})`}</strong>
        
        If summarized results and supportLit are separated and no longer have a combined results header: 
        {!!studies.systematicCount && <span title="Systematic Reviews/Meta Analyses" className={`${styles.resultCount} ${styles.sysBadge}`}>
          {studies.systematicCount}
        </span>}
        &nbsp;
        {!!studies.clinicalCount && <span title="Clinical Trials" className={`${styles.resultCount} ${styles.clinBadge}`}>
          {studies.clinicalCount}
        </span>}
        &nbsp;
        {!!studies.observationalCount && <span title="Observational" className={`${styles.resultCount} ${styles.obsBadge}`}>
          {studies.observationalCount}
        </span>}
        &nbsp;
        {!!studies.caseCount && <span title="Case Studies" className={`${styles.resultCount} ${styles.caseBadge}`}>
          {studies.caseCount}
        </span>}
        &nbsp;
        {!!studies.unknownCount && <span title="Pending Summary" className={`${styles.resultCount} ${styles.pendBadge}`}>
          {studies.unknownCount}
        </span>}
      </h2> */}
      {!!systematicReviews.length && <details open={false} ref={detailRefs['sysrev']} aria-label="systematic review search results" role='tablist'>
        <summary className={`${styles.sysHeader} ${styles.summarySection}`}>
          <span style={{ backgroundColor: 'purple', color: 'purple', display:'inline-block' }}>
            &nbsp;
          </span>
          <span style={{ marginLeft: '2px' }}>
            <strong>Systematic Reviews and/or Meta-Analyses</strong>
          </span>
        </summary>
        {systematicReviews}
      </details>}
      {!!clinicalTrials.length && <details open={false} ref={detailRefs['clintri']} aria-label="clinical trial search results" role='tablist'>
        <summary className={`${styles.clinHeader} ${styles.summarySection}`}>
          <span style={{ backgroundColor: '#9c9b65', color: '#9c9b65', display:'inline-block' }}>
            &nbsp;
          </span>
          <span style={{ marginLeft: '2px' }}>
            <strong>Clinical Trials</strong>
          </span>
        </summary>
        {clinicalTrials}
      </details>}
      {!!observationalStudies.length && <details open={false} ref={detailRefs['obser']} aria-label="observational study search results" role='tablist'>
        <summary className={`${styles.obsHeader} ${styles.summarySection}`}>
          <span style={{ backgroundColor: '#68689c', color: '#68689c', display:'inline-block' }}>
            &nbsp;
          </span>
          <span style={{ marginLeft: '2px' }}>
            <strong>Observational Studies</strong>
          </span>
        </summary>
        {observationalStudies}
      </details>}
      {!!caseReports.length && <details open={false} ref={detailRefs['casrep']} aria-label="case report search results" role='tablist'>
        <summary className={`${styles.caseHeader} ${styles.summarySection}`}>
          <span style={{ backgroundColor: '#f69022', color: '#f69022', display:'inline-block' }}>
            &nbsp;
          </span>
          <span style={{ marginLeft: '2px' }}>
            <strong>Case Reports/Series</strong>
          </span>
        </summary>
        {caseReports}
      </details>}
      {!!unknownStudyTypes.length && <details open={false} ref={detailRefs['pensum']} aria-label="search results pending summarization" role='tablist'>
        <summary className={`${styles.pendHeader} ${styles.summarySection}`}>
          <span style={{ backgroundColor: '#311140', color: '#311140', display:'inline-block' }}>
            &nbsp;
          </span>
          <span style={{ marginLeft: '2px' }}>
            <strong>Pending Summary</strong>
          </span>
        </summary>
        {unknownStudyTypes}
      </details>}
    </>
  );
}

SummarizedResults.propTypes = propTypes;
