import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './locationsStyles.css';
import PhoneContainer from '../phoneNumbers/PhoneContainer';
import { countryList, provinceList, stateList } from '../../../utils/addressData';

LocationsForm.propTypes = {
  submitCallback: PropTypes.func.isRequried,
  cancelCallback: PropTypes.func.isRequired,
  editingLocation: PropTypes.object,
  disableSubmitCB: PropTypes.func.isRequired,
};

export default function LocationsForm({
  submitCallback,
  cancelCallback,
  editingLocation = {},
  disableSubmitCB
}) {
  const [name, setName] = useState(editingLocation.name || '');
  const [virtual, setVirtual] = useState(editingLocation.virtual || false);
  const [virtualOnly, setVirtualOnly] = useState(editingLocation.virtualOnly || false);
  const [suppressAddress, setSuppressAddress] = useState(editingLocation.suppressAddress || false);
  const [country, setCountry] = useState(editingLocation.country || 'United States of America');
  const [address1, setAddress1] = useState(editingLocation.address1 || '');
  const [address2, setAddress2] = useState(editingLocation.address2 || '');
  const [city, setCity] = useState(editingLocation.city || '');
  const [businessState, setBusinessState] = useState(editingLocation.state || '');
  const [zipCode, setZipCode] = useState(editingLocation.zipCode || '');
  const [locationPhoneNumbers, setLocationPhoneNumbers] = useState(editingLocation.phoneNumbers || []);
  const [businessEmail, setBusinessEmail] = useState(editingLocation.email || '');
  const [businessUrl, setBusinessUrl] = useState(editingLocation.url || '');
  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  

  const handleNameChange = ({ target }) => setName(target.value);
  const handleVirtualChange = ({ target }) => {
    const selection = target.value;
    if(selection === 'Yes') {
      setVirtual(true);
    } else {
      // reset to defaults
      setVirtualOnly(false);
      setSuppressAddress(false);
    }
  };
  const handleVirtualOnlyChange = ({ target }) => setVirtualOnly(target.value === 'Yes');
  const handleSuppressAddressChange = ({ target }) => setSuppressAddress(target.value === 'Yes');
  const handleCountryChange = ({ target }) => setCountry(target.value);
  const handleAddress1Change = ({ target }) => setAddress1(target.value);
  const handleAddress2Change = ({ target }) => setAddress2(target.value);
  const handleCityChange = ({ target }) => setCity(target.value);
  const handleStateChange = ({ target }) => setBusinessState(target.value);
  const handleZipChange = ({ target }) => setZipCode(target.value);
  const handleLocationsPhoneChange = newNumbers => setLocationPhoneNumbers(newNumbers);
  const handleBusinessEmailChange = ({ target }) => setBusinessEmail(target.value);
  const handleBusinessUrlChange = ({ target }) => setBusinessUrl(target.value);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if(name.length < 1) {
      nameRef.current.style.border = '3px solid red';
      nameRef.current.scrollIntoView();
      return;
    }

    submitCallback({
      name,
      virtual,
      virtualOnly,
      suppressAddress,
      country,
      address1,
      address2,
      city,
      state: businessState,
      zipCode,
      phoneNumbers: locationPhoneNumbers,
      email: businessEmail,
      url: businessUrl,
    });
  };

  const countryDropdownItems = countryList.map(country => (
    <option key={country} value={country}>{country}</option>
  ));
  const stateDropdownItems = stateList.map(state => (
    <option key={state} value={state}>{state}</option>
  ));
  const provinceDropdownItems = provinceList.map(prov => (
    <option key={prov} value={prov}>{prov}</option>
  ));

  const useStateList = country === 'United States of America' || country === 'Canada' ? true : false;
  const stateLabel = country === 'United States of America' ? '*State' : country === 'Canada' ? '*Province' : 'State/Province';
  const provinceOrStateList = country === 'United States of America' ? stateDropdownItems : provinceDropdownItems;

  return (
    <div className={styles.locationsForm}>
      <label className={styles.formLabel}>*Name
        <input
          ref={nameRef}
          required={true}
          type='text'
          placeholder='Business / Clinic Name'
          value={name}
          onChange={handleNameChange}
        />
      </label>
      <label className={styles.formLabel} htmlFor='virtual'>*Do you offer virtual services at this business or clinic?
      </label>
      <span>
        <span className={styles.radioInput}>
          <input 
            required={true}
            type='radio'
            name='virtual'
            value='Yes'
            checked={virtual}
            onChange={handleVirtualChange}
          /> Yes
        </span>
        <span className={styles.radioInput}>
          <input 
            type='radio'
            name='virtual'
            value='No'
            checked={!virtual}
            onChange={handleVirtualChange}
          /> No
        </span>
      </span>
      {virtual && (
        <>
          <label className={styles.formLabel} htmlFor='virtualOnly'>*Does this location ONLY offer virtual services?
          </label>
          <span>
            <span className={styles.radioInput}>
              <input
                required={true}
                type='radio'
                name='virtualOnly'
                value='Yes'
                checked={virtualOnly}
                onChange={handleVirtualOnlyChange}
              /> Yes
            </span>
            <span className={styles.radioInput}>
              <input 
                type='radio'
                name='virtualOnly'
                value='No'
                checked={!virtualOnly}
                onChange={handleVirtualOnlyChange}
              /> No
            </span>
          </span>
          {virtualOnly && (
            <>
              <label className={styles.formLabel} htmlFor='suppress'>*Suppress the display of this address?
              </label>
              <span>
                <span className={styles.radioInput}>
                  <input
                    required={true}
                    type='radio'
                    name='suppress'
                    checked={suppressAddress}
                    value='Yes'
                    onChange={handleSuppressAddressChange}
                  /> Yes
                </span>
                <span className={styles.radioInput}>
                  <input
                    type='radio'
                    name='suppress'
                    checked={!suppressAddress}
                    value='No'
                    onChange={handleSuppressAddressChange}
                  /> No
                </span>
              </span>
            </>
          )}
        </>
      )}
      <label className={styles.formLabel}>*Country
        <select
          required={true}
          value={country}
          onChange={handleCountryChange}
        >
          {countryDropdownItems}
        </select>
      </label>
      <label className={styles.formLabel}>*Street Address
        <input 
          required={true}
          type='text'
          placeholder='1234 Example St'
          value={address1}
          onChange={handleAddress1Change}
        />
      </label>
      <label className={styles.formLabel}>Street Address 2
        <input 
          type='text'
          placeholder='Suite 234'
          value={address2}
          onChange={handleAddress2Change}
        />
      </label>
      <label className={styles.formLabel}>*City
        <input 
          required={true}
          type='text'
          value={city}
          onChange={handleCityChange}
        />
      </label>
      <label className={styles.formLabel}>{stateLabel}
        {useStateList ?
          <select
            required={true}
            value={businessState}
            onChange={handleStateChange}
          >
            {provinceOrStateList}
          </select> :
          <input 
            required={false}
            value={businessState}
            onChange={handleStateChange}
          />
        }
      </label>
      <label className={styles.formLabel}>*Zip Code
        <input
          required={true}
          type='text'
          value={zipCode}
          placeholder='00000'
          onChange={handleZipChange}
        />
      </label>
      <label ref={phoneRef}>Business Phone Numbers
        <PhoneContainer
          setParentCB={handleLocationsPhoneChange}
          required={false}
          editingNumbers={locationPhoneNumbers}
          disableSubmitCB={disableSubmitCB}
        />
      </label>
      <label className={styles.formLabel}>Business/Clinic Email Address:
        <input 
          required={false}
          type='text'
          placeholder='clinic@example.com'
          value={businessEmail}
          onChange={handleBusinessEmailChange}
        />
      </label>
      <label className={styles.formLabel}>Business/Clinic Website
        <input 
          required={false}
          type='text'
          placeholder='www.knowintegrativeoncology.org'
          value={businessUrl}
          onChange={handleBusinessUrlChange}
        />
      </label>
      <div className={styles.buttonContainer}>
        <input 
          type="button"
          onClick={cancelCallback}
          value="Cancel"
        />
        <input 
          type="button"
          onClick={handleSubmit}
          value="Save Location"
        />
      </div>
    </div>
  );
}
