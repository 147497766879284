import React, { useEffect, useRef } from 'react';
import styles from './privacyPolicy.css';

export default function PrivacyPolicy() {
  const cookieSection = useRef(null);
  useEffect(() => {
    document.title = 'KNOW - Privacy Policy';
  }, []);

  useEffect(() => {
    if(window.location.hash === '#cookies') {
      cookieSection.current.scrollIntoView();
    }
  }, []);

  return (
    <div className={styles.privacyContainer} role="main" aria-label="privacy policy">
      <h3 className='h3'>KNOW PRIVACY POLICY 10/6/20</h3>
      <main>
        <h4>
          INTRODUCTION
        </h4>
        <p>
          KNOWoncology.org (&quot;we,&quot; &quot;our,&quot; or &quot;us&quot;) in processing your personal information, wants to inform you about the ways we do that. In this Privacy &amp; Cookie Notice we describe what personal information we collect, use and disclose.
        </p>
        <p>
          Personal information means any data associated with an individual who can be identified, directly or indirectly, based on that information. This may include names, contact details, (online) identification data, online identifiers, or other information specific to that individual.
        </p>
        <p>
          This Privacy &amp; Cookie Notice applies to you when visiting our website and other services, including events, that refer or display a link to this notice (“Services”). This Privacy &amp; Cookie Notice may be supplemented or replaced by additional privacy statements or terms provided to you.
        </p>

        <h4>
          LAYERED NOTICE SECTIONS:
        </h4>
        <ul className={styles.noticeSections}>
          <li>WHO WE ARE</li>
          <li>WHAT PERSONAL INFORMATION DO WE COLLECT</li>
          <li>HOW WE COLLECT PERSONAL INFORMATION</li>
          <li>HOW WE USE PERSONAL INFORMATION</li>
          <li>SHARING PERSONAL INFORMATION</li>
          <li>HOW LONG DO WE KEEP PERSONAL INFORMATION</li>
          <li>HOW WE PROTECT PERSONAL INFORMATION</li>
          <li>WHAT ARE YOUR RIGHTS</li>
          <li>USE OF COOKIES</li>
          <li>LINKS TO OTHER WEBSITES</li>
          <li>HOW YOU CAN CONTACT US</li>
          <li>UPDATES TO THIS NOTICE</li>
        </ul>

        <h4>
          WHO WE ARE
        </h4>
        <p>
          KNOWoncology.org is a non-profit subsidiary of the Oncology Association of Naturopathic Physicians (OncANP.org). The KNOWoncology.org team consists of research directors, volunteers, and paid contractors who create summaries of data from human clinical trials related to integrative oncology within an online user interface. Any personal information provided to or collected by KNOWoncology.org is used for the purposes of or related to administering the database to its registrants.
        </p>

        <p>
          We are committed to safeguarding the personal information of our customers, users, contractors and other stakeholders, while helping our customers, contractors, investors, and society create a deeper impact by having more information about integrative oncology clinical care.
        </p>
        <p>
          If you wish to contact us in relation to questions to your personal information we refer you to the ‘HOW YOU CAN CONTACT US’ section below.
        </p>

        <h4>
          WHAT PERSONAL INFORMATION DO WE COLLECT?
        </h4>
        <p>
          The type of personal information we collect depends on your relationship with us and the Services we deliver to you. To that end, we may collect the following personal information about you:
        </p>
        <ul>
          <li>
            Name and contact details, such as, first and last name, email address, postal address, telephone number
          </li>
          <li>
            Work-related information, such as, job title and other employment or education information
          </li>
          <li>
            Account Credentials, such as, passwords and other security information for authentication and access
          </li>
          <li>
            Financial Information, such as, bank account, credit and debit card numbers for processing of payments
          </li>
          <li>
            Usage information, such as how you navigate within our services and which elements of you use the most as well as information regarding the goods and/or services you wish to order, wish list, preferences, interest, etc. Other information shared by you through our surveys, market research or through communication with our directors or staff.
          </li>
          <li>
            Computer, device and connection information, such as, IP address, browser type and version and location
          </li>
          <li>
            If you are under 13 years of age, please do not use or access the Services. We do not knowingly collect or maintain personal information (as defined by the United States Children’s Online Privacy Protection Act) from persons under 13 years of age. If we learn that personal information of persons under 13 has been collected on or through our services, we will take appropriate steps to delete this information.
          </li>
        </ul>

        <h4>
          HOW WE COLLECT PERSONAL INFORMATION
        </h4>

        <p>
          We may collect personal information from the following sources:
        </p>
        <ul>
          <li>
            Direct Interactions, such as, when you register for our Services or make a purchase
          </li>
          <li>
            Automated Tracking Technologies, such as, information automatically collected about your interaction with our services, website, survey or market research using various technologies such as cookies, web logs and beacons and internet tags
          </li>
        </ul>

        <h4>
          HOW WE USE YOUR PERSONAL INFORMATION
        </h4>

        <p>
          Personal information collected is used for the purposes for which you have provided it and might be further used for statutory or other legitimate purposes only. As an example, if you make use of our service contact form on our website, we will use the information you provided in the first place to respond to your service request. We might further use that information to improve the quality of our customer service process.

        </p>
        <p>
          Lawfulness of the processing is one of the main principles relating to processing of your personal information - we apply the following legal processing grounds:
        </p>
        <ul>
          <li>
            processing is necessary for the performance of a contract or in order to enter into a contract;
          </li>
          <li>
            processing is necessary for the purposes of our legitimate interests - for example, to improve the quality of our products and services;
          </li>
          <li>
            processing is necessary for compliance with a legal obligation to which we are subject or that is otherwise lawful under applicable data protection laws;
          </li>
          <li>
            with your given consent.
          </li>
        </ul>
        <p>
          Depending on how you interact with us and our services, we may use your personal information for the following purposes:
        </p>
        <ul>
          <li>
            Providing and managing your access to and use of our services and administration related to this relationship as necessary for the enactment of a contract;
          </li>
          <li>
            Improving our Services and developing new products, such as inviting you to participate in surveys or to personalize your experience with our Services. This processing is necessary for the purposes of our legitimate interests;
          </li>
          <li>
            Marketing, such as providing you with marketing or advertising communications that we think you may be interested in. This processing is with your given consent or for our legitimate interests;
          </li>
          <li>
            Recruiting, such as assessing job applicants. This processing is in order to take steps prior to entering into a contract or with your given consent;
          </li>
          <li>
            Other general business support purposes, including but not limited to, procurement, financial and fiscal management, risk and compliance management, and external reporting. This processing is necessary for compliance with a legal obligation under applicable data protection laws or for our legitimate interests.
          </li>
        </ul>

        <h4>
          SHARING YOUR PERSONAL INFORMATION
        </h4>

        <p>
          To be able to provide you our services and further our operational goals, we may share certain information internally or with selected third parties. There might also be a certain statutory or legal obligation that we believe in good faith requires us to disclose your personal information externally. Parties we might share data with include:
        </p>
        <p>
          KNOWoncology.org or OncANP.org affiliates or subsidiaries, e.g. for technology to support the services we are delivering; our service providers, e.g. for managing or hosting services and/or technology for the Services we are providing; our business partners, to the extent you have purchased or expressed interest in, a product or service of such business partner, interacted with them or otherwise authorized the sharing of your personal information with such business partner; subscribing, accrediting or professional organizations, e.g. for providing utilization information to organizations that provide you with access to our Services and/or sharing tracking and redeeming credits for professional accreditation; organizations involved in business transfers, e.g. to a purchaser or successor entity in the event of a sale or any other corporate transaction involving some or all of our business; other parties, e.g. as needed for external audit, compliance, risk management, development and/or governance related matters; or governmental authorities and regulators, as required under applicable law.
        </p>
        <p>
          Whenever we share personal information internally we have appropriate safeguards in place. As required under applicable law, third parties are required to use appropriate safeguards to protect personal information and they can only access personal information that is necessary for performing their specific tasks.
        </p>

        <h4>
          HOW LONG WE KEEP YOUR PERSONAL INFORMATION
        </h4>
        <p>
          Your personal information will be processed to the extent necessary for the performance of our obligations, and for the time necessary to achieve the purposes for which the information is collected, in accordance with our data retention policies and applicable data protection laws. When we no longer need your personal information, we will take all reasonable steps to remove it from our systems and records or take steps to properly anonymize it so that you can no longer be identified from it.
        </p>

        <h4>
          HOW WE PROTECT YOUR PERSONAL INFORMATION
        </h4>
        <p>
          We have put in place an internal framework of standards to keep your data safe. In addition, we limit access to personal information by our staff, contractors, business partners, service providers and third-party service providers to a ‘need-to-know’ basis. More specifically and in accordance with the law, we take appropriate technical and organizational measures to ensure the confidentiality and integrity of your personal information and the way it is processed.
        </p>

        <h4>
          WHAT ARE YOUR RIGHTS
        </h4>
        <p>
          We will honor your rights under applicable laws.  To the extent provided under your local data protection laws, including European data protection laws, your rights include the following:
        </p>
        <ul>
          <li>
            Right of access (‘an overview of your personal information’): the right to get an overview of your personal information that we process.
          </li>
          <li>
            Right to rectification (‘correct your personal information’): if your personal information is inaccurate or incomplete, the right to ask us to rectify or complete your personal information.
          </li>
          <li>
            Right to erasure (‘right to be forgotten’): the right to request that we delete your personal information to the extent permitted by applicable law. In certain circumstances, it may not be possible for us to accept your request, for example, when the processing is necessary to comply with a legal obligation, or if processing is necessary for the enactment of a contract. In some cases, your personal information is necessary in order to provide our services and if you do not provide such information to us then you may be precluded from accessing and using the services or using certain features.
          </li>
          <li>
            Right to data portability (‘transfer your personal information’): the right to ask us to transfer your personal information directly to you. This applies to certain personal information if processed by automated means and with your consent or based on a contract you have with us. On your request, and where technically feasible, we will transfer your personal information to another party of your choice.
          </li>
          <li>
            Right to restrict processing: the right to request that we restrict or stop the processing of your personal information held by us for a certain period of time, or for an indefinite period. In certain circumstances, it may not be possible for us to accept your request, for example, when the processing is necessary to comply with a legal obligation, or if we can demonstrate compelling legitimate grounds otherwise.
          </li>
          <li>
            Right to object: the right to object to the processing of your personal information. The reasons for objection should relate to your particular situation, and be related to a processing based on the legitimate interest condition. We will then no longer process the personal information, unless we can demonstrate compelling reasons otherwise. In certain circumstances you have the unconditional right to object; for instance, in the context of direct marketing activities.
          </li>
          <li>
            Withdrawal of Consent: the right to withdraw your consent to the processing of your personal information at any time; for instance, after you consented to keeping you informed about our services, you have the right to withdraw this consent at any time.
          </li>
          <li>
            To exercise your rights and/or for any other questions about the handling of your personal information, we refer you to the &apos;HOW YOU CAN CONTACT US&apos; section below.
          </li>
        </ul>
        <p>
          If you feel we are unresponsive or disagree with our data privacy practices, you can also file a complaint with your national Data Protection Authority.
        </p>

        <h4 ref={cookieSection}>
          THE USE OF COOKIES
        </h4>
        <p>
          We use cookies to optimize our websites and analyze website traffic. On occasion, we may use third party cookies, notably social media plugins, to enable you to share ideas and information related to us instantly and for own marketing purposes.
        </p>
        <p>
          Cookies or similar techniques (referred to collectively as “Cookies”) are small text files or pixels, which might be stored on your computer or mobile device. Cookies may be necessary to facilitate website browsing and making it more user-friendly. Third party Cookies may collect information to analyze personal browsing behavior.
        </p>
        <p>
          We may use Cookies belonging to one of the three categories listed below.
        </p>
        <ul>
          <li>
            Necessary Cookies - these mandatory Cookies are needed for our websites to function properly.
          </li>
            Functional Cookies - these Cookies add additional functionality to our websites.
          <li>
            Performance Cookies - these Cookies support analytic services that help us to improve our website’s functionality and user experience.
          </li>
        </ul>
        <p>
          If you do not want cookies to be stored, then you may turn off certain cookies described above individually or you can select the appropriate options on your web browser to delete some or all cookies. Please note, however, that if you block some or all cookies (including essential cookies) you may not be able to use or access all or parts of our websites, such as being able to log on to member-specific areas.
        </p>

        <h4>
          LINKS TO OTHER WEBSITES
        </h4>
        <p>
          Our websites may contain links to other KNOWongology.org or OncANP.org affiliates or third-party websites, which may have privacy notices that differ from ours. We are not responsible for the collection, processing or disclosure of personal information collected through other websites.
        </p>
        <p>
          We are also not responsible for any information or content contained on such websites. Links to other websites are provided solely for convenience. Your usage and browsing on any such website is subject to that website’s own policies. Please review the privacy notices posted on other websites that you may access through our website.
        </p>
        <p>
          KNOWoncology.org may provide you with additional or different privacy notices in specific instances.
        </p>

        <h4>
          HOW YOU CAN CONTACT US
        </h4>
        <p>
          If you have any questions about how we process your personal information or if you want to exercise one of your rights, you can contact us as follows:
        </p>
        <p>
          By email to: privacy@knowintegrative oncology.com
        </p>
        <p>
          By telephone to:  +1 (800) 908 5175
        </p>
        <p>
          If you feel we are unresponsive or disagree with our data privacy practices, you can also file a complaint with your national Data Protection Authority.
        </p>
        <p>
          In response to a request, we might ask you to verify your identity if needed, and to provide information that helps us to understand your request better. If we do not grant your request, whether in whole or in part, we will explain why.
        </p>

        <h4>
          UPDATES TO THIS NOTICE
        </h4>
        <p>
          From time to time we may update this Privacy &amp; Cookie Notice or any other specific privacy statement. When making changes to this Privacy & Cookie Notice, we will add a new date to this Privacy Statement. This version is effective per October 6 2020.
        </p>
      </main>
    </div>
  );
}
