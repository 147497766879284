// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1HMpYTgXS3OajoCWy08vHw {\n  background-color: #683266;\n} \n\n  ._1HMpYTgXS3OajoCWy08vHw span {\n    color: white;\n    -webkit-hyphens: auto;\n            hyphens: auto;\n  }\n\n._1HMpYTgXS3OajoCWy08vHw:hover {\n  background-color: #683266 !important;\n}", "",{"version":3,"sources":["pageNavButton.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAM3B;;EAJE;IACE,YAAY;IACZ,qBAAa;YAAb,aAAa;EACf;;AAGF;EACE,oCAAoC;AACtC","file":"pageNavButton.css","sourcesContent":[".navLink {\n  background-color: #683266; \n\n  span {\n    color: white;\n    hyphens: auto;\n  }\n}\n\n.navLink:hover {\n  background-color: #683266 !important;\n}"]}]);
// Exports
exports.locals = {
	"navLink": "_1HMpYTgXS3OajoCWy08vHw"
};
module.exports = exports;
