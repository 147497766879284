export const summaryTagHeadersDict = {
  docType: 'Summary Type',
  createdTime: 'Date Added',
  summarizer: 'Summary Author',
  summaryTitle: 'Title',
  year: 'Year',
  author: 'Authors',
  studyType: 'Study Type',
  studyTypeInt: 'Numeric Study Type',
  briefSummary: 'Brief Summary',
  abstract: 'Abstract',
  designFeatures: 'Design Features',
  designSubtype: 'Design Subtype',
  humanStudies: 'Human Studies',
  nonhumanStudies: 'Nonhuman Studies',
  inclusionCriteria: 'Inclusion Criteria',
  searchDates: 'Search Dates',
  populationDescribed: 'Population Described',
  participants: 'Participants',
  cancerStatus: 'Cancer Status',
  cancerType: 'Cancer Type',
  staging: 'Staging',
  pathology: 'Pathology Reported',
  molecularTargets: 'Molecular Targets Reported',
  naturalTherapyCategory: 'Natural Therapy Category',
  naturalTherapyDescribed: ' Natural Therapy Described',
  naturalTherapyName: 'Natural Therapy Name',
  naturalTherapyComponents: 'Natural Therapy Components',
  comparator: 'Comparator',
  resultsDescribed: 'Outcomes/Combined Statistical Results Described',
  outcomeCategory: 'Outcome Category',
  outcomeResults: 'Outcome Results',
  associatedAdverseEvents: 'Adverse Events Associated with Natural Therapy',
  adverseEventsDescribed: ' Adverse Events Brief Description',
  adverseEventsDescribed2: 'Adverse events Additonal Description',
  interactions: 'Interactions',
  interactionsDescribed: 'Interactions Described',
  tumorType: 'Tumor Type',
  sideEffectsAssociated: 'Side Effects Associated with Natural Therapy',
  sideEffects: 'Side Effects Reported',
  sideEffectsDescribed: 'Side Effects Described',
  conventionalTreatment: 'Conventional Treatment',
  conventionalTreatmentAgents: 'Conventional Treatment Agents',
  additionalReferences: 'Additional References',
  qraSequence: 'Quality Risk Assessment [Random Sequence Generation]',
  qraAllocation: 'Quality Risk Assessment [Allocation Concealment]',
  qraBlinding: 'Quality Risk Assessment [Blinding of Participants and Personnel]',
  qraBlindingOutcome: 'Quality Risk Assessment [Blinding of Outcome Assessment]',
  qraIncomplete: 'Quality Risk Assessment [Incomplete Outcome Data]',
  qraSelective: 'Quality Risk Assessment [Selective Reporting]',
  qraOther: 'Quality Risk Assessment [Other Bias]',
  qraJustification: 'Risk Assessment Justification',
  pediatricPopulation: 'Pediatric',
  keywords: 'Keywords',
  openAccess: 'Open Access',
  reference: 'Reference',
  link: 'Links',
};

// export const microtagTagHeadersDict = {
//   docType: 'Microtag',
//   createdTime: 'Date Added',
//   summaryTitle: 'Title',
//   year: 'Year',
//   author: 'Authors',
//   studyType: 'Study Type',
//   studyTypeInt: 'Numeric Study Type',
//   abstract: 'Abstract',
//   keywords: 'Keywords',
//   openAccess: 'Open Access',
//   reference: 'Reference',
//   link: 'Links',
// };

// export const supportLitTagHeadersDict = {
//   authorName: 'Author Name',
//   documentTitle: 'Document Title',
//   publicationYear: 'Year',
//   source: 'Source',
//   naturalTherapyCategory: 'Natural Therapy Category',
//   naturalTherapyNames: 'Natural Therapy Names',
//   tumorTypeTags: 'Tumor Type Tags',
//   sideEffectTags: 'Side Effect Tags',
//   typeOfConventionalTreatment: 'Type of Conventional Treatment',
//   conventionalTreatmentAgents: 'Conventional Treatment Agents',
//   interventionTags: 'Intervention Tags',
//   keywords: 'Keywords',
//   filesLink: 'Files/Link',
//   publishingAgreement: 'Publishing Agreement'
// };
