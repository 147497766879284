// src/components/home/HomeBanner.jsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './homeBanner.css';

export const HomeBanner = ({ btnText, link, inviteFreeTrial }) => {
  const [email, setEmail] = useState('');
  const [isInviting, setIsInviting] = useState(false);
  const [message, setMessage] = useState('');
  const [isVisible, setIsVisible] = useState(true);

  const handleFreeTrialInvite = () => {
    setIsInviting(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (email) {
      try {
        const inviteFreeTrialResponse = await inviteFreeTrial(email);
        console.info(inviteFreeTrialResponse);
        setMessage('Free trial invitation sent successfully!');
      } catch (err) {
        console.error('Error sending free trial invitation:', err);
        setMessage('Failed to send free trial invitation.');
      } finally {
        setTimeout(() => {
          setIsVisible(false);
          setMessage('');
          setIsInviting(false);
        }, 3000);
      }
    }
  };

  return (
    <div className={`${isVisible ? styles.homeBanner : styles.shrink}`}>
      {inviteFreeTrial ? (
        isInviting ? (
          <>
            {!message && isVisible && <><input
              type="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter email address"
              className={styles.emailInput} /><button className={`${styles.link} ${styles.submitButton}`} onClick={handleEmailSubmit}>
                Submit
              </button></>}
            {message && <p className={styles.message}>{message}</p>}
          </>
        ) : (
          <>
            {!message && isVisible && <button className={styles.link} onClick={handleFreeTrialInvite}>
              {btnText}
            </button>}
            {message && <p className={styles.message}>{message}</p>}
          </>
        )
      ) : (
        <Link className={styles.link} to={link}>{btnText}</Link>
      )}
    </div>
  );
};
