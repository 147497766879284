// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3V6h0SVbzcAONmsK5M94Li {\n  height: 60px;\n  background-color: #f69022cf;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n  ._3V6h0SVbzcAONmsK5M94Li .hS97cCclIed3W_rejnNDq {\n    width: auto;\n    height: 39px;\n    padding: .6em;\n    background-color: #f6f6f6;\n    border: 1px solid #dddddd;\n    border-radius: 1em;\n    box-shadow: none;\n    font-weight: bold;\n    color: black;\n    line-height: 1.15;\n  }\n", "",{"version":3,"sources":["providerBanner.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,2BAA2B;EAC3B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AAcrB;;EAZE;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;EACnB","file":"providerBanner.css","sourcesContent":[".providerBanner {\n  height: 60px;\n  background-color: #f69022cf;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .link {\n    width: auto;\n    height: 39px;\n    padding: .6em;\n    background-color: #f6f6f6;\n    border: 1px solid #dddddd;\n    border-radius: 1em;\n    box-shadow: none;\n    font-weight: bold;\n    color: black;\n    line-height: 1.15;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"providerBanner": "_3V6h0SVbzcAONmsK5M94Li",
	"link": "hS97cCclIed3W_rejnNDq"
};
module.exports = exports;
