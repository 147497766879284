import React from 'react';

const AllCancerTypes = () => (
  <section role="complementary" aria-label="All cancer types methodology">
    <h1>
      KNOW Search Filters
    </h1>
    <br/>
    <h2>
      RESEARCH QUESTION:
    </h2>
    <p>
      What is the role of integrative therapies in the clinical management of cancer patients and survivors?
    </p>
    <br/>
    <h2>
      INCLUSION CRITERIA:
    </h2>
    <p>
      English language<br />
      Articles published all years to present<br />
      Human experimental studies including meta-analyses, systematic reviews, clinical guidelines, clinical trials, observational data, case reports (population must include humans with cancer or survivors)<br />
      Population includes humans with cancer (all tumor types), precancerous conditions, and survivors of cancer (i.e., diagnosed with cancer and forward in time)<br />
      All outcomes, including but not limited to: Overall Survival (OS), Progression Free Survival (PFS), Recurrence, Quality of Life (QoL), side effect management, Patient Reported Outcomes (PROs),  surrogate markers<br />
      Females and males<br />
      All nutraceuticals and diet therapies, including: vitamins, minerals, macronutrients, botanicals, homeopathy, specific diets (ex. Mediterranean, ketogenic), and natural agents complexed to pharmaceuticals, administered via dietary or supplemental means<br />
      All routes of administration, including: oral, intravenous, injectable, topical, inhaled, intranasal, rectal, intravesical, intra-tumoral, etc. If combined with a nutraceutical or dietary approach in study, include: Mind body therapies, movement interventions (exercise, yoga, tai chi, etc.), prayer, hypnotherapy, light therapy, hydrotherapy, acupuncture, and oriental medicine<br />
      **As of January 2023, KNOW includes integrative data for precancerous conditions 
    </p>
    <br />
    <h2>
      EXCLUSION CRITERIA:
    </h2>
    <p>
      Non-English language<br />
      Non-human experimental (e.g., in vitro or in vivo non-human animal studies)<br />
      Studies that do not include populations with precancerous conditions, cancer, or cancer survivors<br />
      Narrative reviews<br />
      Drug-only trials<br />
      Prevalence/usage of complementary and alternative medicine (CAM)<br />
      Conference proceedings<br />
      Trial postings without results, study protocols, interim analyses.
    </p>
    <p><br />
      NOTE: At this time, we are including data from studies conducted with participants after diagnosis with precancerous conditions or cancer, including survivors. We are NOT including studies on the primary prevention of cancer or associated precancerous conditions.
    </p>
    <br/>
    <h2>
      KNOW Search Filters
    </h2>
    <p>
      Using the original dietary supplement subset available in both PubMed and MEDLINE as our base, we created a line-by-line version of this filter for use in Embase. With the help of naturopathic doctors, we identified additional relevant vocabulary that we incorporated into the filter. This filter was then paired with the pertinent vocabulary for all cancer types. In 2020, the original PubMed/MEDLINE dietary supplement filter was replaced with a greatly reduced filter that searches only the “traditional” vitamins and minerals, and we have subsequently translated the original subset in full, in MEDLINE, to match the vocabulary in Embase.
    </p>
    <p>
      We run the searches in Ovid MEDLINE&reg; (which includes in-process and Epub ahead-of-print records) and Embase. Strategies utilize a combination of controlled vocabulary (e.g., Neoplasms, Flavonoids, Homeopathy) and keywords (e.g., cancer, glutamine, probiotic). Vocabulary and syntax are adjusted across the databases. There are no language limits but animal-only, opinion pieces, and conference proceedings are removed from the results. Duplicates are removed using bibliographic software and through screening processes. Searches are updated on a regular basis (e.g., semi-annually) and relevant citations incorporated. Examples of updates and adjustments to the filter are the addition of expanded cannabis-relevant and pre-cancerous vocabulary, added in 2022.
    </p>
    <br/>
    <p>
      The search is updated semi-anually and relevant citations incorporated.
    </p>
  </section>
);

export default AllCancerTypes;
