import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './joinNow.css';
import { SUBSCRIPTION_PRICES } from '../../utils/prices';

export default function JoinNow() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'KNOW - Join Now';
  }, []);

  const handleLicenseNav = (event) => {
    event.preventDefault();
    navigate('/license', { replace: true });
  };

  return (
    <div className={styles.joinContainer} role="main" aria-label="join now">
      <section>
        <p className='h5'>Join Now</p>
        <p>
          <span style={{ 'fontWeight': 'bold' }}>Individual Annual Rates</span>
          <br /><br />
          <ul>
            <li>
              1-year access to KNOW: ${SUBSCRIPTION_PRICES.standard_dollars}<br />
              <button onClick={handleLicenseNav} style={buttonStyle}>Subscribe</button>
            </li>
            {/* <li>If you would like to receive additional member benefits, you can join the Oncology Association of Naturopathic Physicians as an Allied Care Provider for ${SUBSCRIPTION_PRICES.allied_dollars}, which includes yearly access to KNOW, discounts to the annual conference, and free webinars. <a href='https://oncanp.org/product/allied-membership/' rel='noopener noreferrer' target='_blank'>Click here to join</a></li> */}
          </ul>
        </p>
        <hr style={{ color: 'gray', width: '70%' }}/>
        <p>
          <span style={{ 'fontWeight': 'bold' }}>Small Group Annual Rates</span>
          <br />
          <ul>
            <br />
            <li>Up to 5 users: $1,000</li>
          </ul>
        </p>
        <br />
        <hr style={{ color: 'gray', width: '70%' }}/>
        <p>
          <span style={{ 'fontWeight': 'bold' }}>Large Group / Team Annual Rates</span>
          <ul>
            <li>Designed for Professional Associations, Cancer Organizations, Industry, Hospital systems, etc.</li>
            <li>Up to 100 users, $10,000</li>
            <li>Up to 149 users, $12,000</li>
            <li>Up to 249 users, $15,000</li>
            <li>Up to 399 users, $18,000</li>
            <li>Up to 599 users, $22,000</li>
            <li>For organizations of 600-2000 users, standard rate of $25,000</li>
          </ul>
        </p>
        <p>
          For group licensing options, including integration solutions, please email <a href='info@knowoncology.org'>info@knowoncology.org</a>
        </p>
      </section>
    </div>
  );
}

const buttonStyle = {
  backgroundColor: '#8B62B7',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  padding: '8px 16px',
  cursor: 'pointer'
};
