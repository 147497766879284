import { SET_SEARCH_QUERY } from '../actions/searchActions';

const initialState = {
  query: {
    selectedOptionsArray: [],
    supportLitOptionsArray: [],
    searchTextInput: '',
    perPage: 20,
    page: 1,
    onlyPediatric: false
  }
};

export const searchReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_SEARCH_QUERY:
      return { ...state, query: action.payload };
    default:
      return state;
  }
};
