import React from 'react';
import './welcome.css';

const Welcome = () => (
  <section id='welcome' role="complementary" aria-label="welcome">
    <h3>WELCOME to KNOW</h3>
    <p>
      <span style={{ 'fontWeight': 'bold' }}>KNOW</span>{' '}
      is a tool that allows access to up-to-date research on natural agents in cancer care.{' '}
      KNOW systematically searches and presents relevant human studies, including clinical trials,{' '}
      from Medline and EMBASE.
    </p>
    <br />
    <p>
      In KNOW, data is searchable by tumor type, natural therapy, conventional treatment, and side effects.{' '}
      You can copy references into professional communications, academic projects or presentations, education materials,{' '}
      curriculum, and websites. KNOW provides convenient links to the publisher for full text review or access.
    </p>
    <br />
    <p>
      Membership to KNOW is subscription-based, providing access for individuals, cancer care teams,{' '}
      research groups, academic project groups, hospitals, and public education organizations. 
    </p>
    <br />
    Key Benefits of KNOW
    <ul style={{ 'paddingLeft': '1em' }}>
      <li>• Efficient access to current best evidence</li>
      <li>• Improves clinical outcomes</li>
      <li>• Supports development of educational resources</li>
      <li>• Comprehensive and cost-effective</li>
      <li><b>• Answers questions about natural therapies in cancer care</b></li>
    </ul>
    <br />
  </section>
);

export default Welcome;
