import React from 'react';
import SocialIcons from './SocialIcons';
import ExternalLinks from './ExternalLinks.jsx';
import './sidebar.css';

const Sidebar = () => (
  <aside className="sideBar" role="complementary" aria-label="mission and links">
    <div id="mission-statement" aria-label="mission statement">
      <h4>Our Mission</h4>
      <br />
      <span style={{ fontWeight: 'bold' }}>KNOWintegrativeoncology.org</span> is dedicated to improving the lives of people with cancer through integrative cancer care.
      <br />
      <br />
      KNOW shares current best evidence on the use of nutrition and natural health products in oncology.
      <br />
      <br />
      Our goal is to inspire collaboration among healthcare providers, researchers, and advocacy groups to support education, safety, and clinical decision-making.
    </div>
    <div className="social-links" aria-label="social media links">
      <SocialIcons size={40} />
    </div>
    <div className="links" aria-label="external links">
      <ExternalLinks />
    </div>
  </aside>
);

export default Sidebar;
