const setArray = [
  ['Title', 'summaryTitle'],
  ['Year Published', 'yearPublished'], 
  ['Study Type', 'studyType'],
  ['Study Design Features', 'studyDesignFeatures'],
  ['Summary', 'publicSummary'],
  ['Number of Participants', 'numParticipants'],
  ['Population', 'populationDesc'],
  ['Cancer Type', 'cancerType'],
  ['Stage', 'staging'],
  ['Tumor Type', 'tumorTypeTags'],
  ['Natural Therapy Type', 'naturalTherapyCategory'],
  ['Natural Therapy Description', 'naturalTherapyDesc'],
  ['Natural Therapy Agents', 'naturalTherapyName'],
  ['Natural Therapy Components', 'naturalTherapyComponents'],
  ['Conventional Treatment Type', 'conventionalTreatment'],
  ['Interactions', 'interactionsDesc'],
  ['Side Effects', 'sideEffectTags'],
  ['Side Effects Described', 'sideEffectsDescribed'],
  ['Adverse Events', 'adverseEventsDesc'],
  ['Outcome Category', 'outcomeCategory'],
  ['Outcome Results', 'outcomeResults'],
  ['Outcome Description', 'outcomesResultsDesc']
];

export const unsummarizedSetArray = [['Title', 'title'], ['Year Published', 'pubYear'], ['Source', 'source'], ['Abstract', 'abstract']];

export const dictionary = {
  'Year Published': 'year',
  'Study Types': 'study_type',
  'Population Sizes (N)': 'population_described',
  'Cancer Type': 'tumor_type',
  'Natural Therapy Types': 'natural_therapy_category',
  'Natural Therapy Agents': 'natural_therapy_name',
  'Outcome Categories': 'outcome_category',
  'Outcome Results': 'outcome_results',
  'Side Effects': 'side_effects',
  'Side Effects Described': 'side_effects_described',
  'Study Design Features': 'design_features',
  'Adverse Events': 'adverse_events_described',
  'Stage': 'staging',
  'Interactions': 'interactions_desc',
  'Conventional Treatment Types': 'conventional_treatment',
  'Conventional Treatment Agents': 'conventional_treatment_agents'
};

export const supportLitFilterDict = {
  'Year Published': 'publication_year',
  'Cancer Type': 'tumor_type_tags',
  'Natural Therapy Types': 'natural_therapy_category',
  'Conventional Treatment Types': 'type_of_conventional_treatment',
  'Side Effects': 'side_effect_tags',
  'Natural Therapy Agents': 'natural_therapy_names',
  'Conventional Treatment Agents': 'conventional_treatment_agents'
};

export const fieldNameToSearchNameDictionary = {
  'Natural Therapy Types': 'natural_therapy_category',
  'Natural Therapy Agents': 'natural_therapy_name',
  'Conventional Treatment Types': 'conventional_treatment',
  'Conventional Treatment Agents': 'conventional_treatment_agents',
  'Side Effects': 'side_effects',
  'Side Effects Described': 'side_effects_described',
  'naturalTherapyDescribed': '4.1 Natural Therapy Described',
  'naturalTherapyName': '4.2 Natural Therapy Name',
  'naturalTherapyComponents': '4.3 Natural Therapy Components',
  'comparator': '5.0 Comparator',
  'resultsDescribed': '6.0 Outcomes/Combined statistical results described',
  'outcomeResults': '6.2 Outcome Results',
  'associatedAdverseEvents': '7.0 Adverse events associated with natural therapy',
  'adverseEventsDescribed': '7.0b Adverse Events, Briefly Description',
  'adverseEventsDescribed2': '7.1 Adverse events associated with natural therapy described',
  'tumorType': '9.0 Tumor Type Tags',
  'sideEffects': '10.0 Side Effect Tags',
  'pediatricPopulation': 'Pediatric Population',
  'keywords': 'Keywords',
  'openAccess': 'Open Access',
  'link': 'Link',
  'reference': 'Reference',
  'year': 'Year',
  'author': 'Author',
  'briefSummary': 'Brief Summary',
  'participants': 'Participants',
  'cancerType': 'Cancer Type',
  'staging': 'Staging'
};

// use the displayed tag header to highlight filtered fields in the displayed results
export const tagHeaderToFilterDictionary = {
  'Year Published': 'Year',
  'Cancer Type': 'Tumor Type',
  'Natural Therapy Types': 'Natural Therapy Types',
  'Conventional Treatment Types': 'Conventional Treatment',
  'Side Effects': 'Side Effects',
  'Natural Therapy Agents': 'Natural Therapy Agents',
  'Conventional Treatment Agents': 'Conventional Treatment Agents'
};

export default setArray;
