import React from 'react';
import { assetUrl } from '../../../utils/assetsMap';
import styles from './video.css';

const Video = () => (
  <section id='video' role="complementary">
    <div className={styles.videoWrapper}  aria-label="know instructional videos">
      <article aria-label="KNOW Database 101 video">
        <h4>KNOW Database 101</h4>
        <iframe 
          src="https://drive.google.com/file/d/1K4D9UWQBDqXdel3JYazf3KKADqhZmHRH/preview" 
          allowFullScreen
          title="KNOW Database 101"
          name="KNOW Database 101"
        ></iframe>
      </article>
      <article aria-label="Top 50 Systematic Reviews">
        <h4>Top 50 Systematic Reviews</h4>
        <a
          href={assetUrl('TOP_50_SYS_REVIEWS')}
          rel='noopener noreferrer'
          target='_blank'>
          <img
            width='250px'
            src={assetUrl('TOP_50_SYS_REVIEWS_THUMB')}
            alt='Top 50 Systematic Reviews'
          ></img>
        </a>
      </article>
    </div>
  </section>
);

export default Video;
