// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1PsCjVK2kQyAcKblfwa4DW {\n  width: 100%;\n  bottom: 0;\n  position: relative;\n\n  font-family: 'Roboto', sans-serif;\n  color: #333333;\n  font-size: 1rem;\n  padding-top: 1em;\n  padding-bottom: 1em;\n  font-size: small;\n  font-weight: bold;\n  text-align: center;\n  background-color: #dddddd;\n  line-height: 1.3;\n}\n\n._1PsCjVK2kQyAcKblfwa4DW ._20T2Dm60v5T25v-xMvr22E a {\n  margin-right: 6px; /* Adds space between icons */\n}\n\n._1PsCjVK2kQyAcKblfwa4DW ._20T2Dm60v5T25v-xMvr22E a:last-child {\n  margin-right: 0;\n}\n", "",{"version":3,"sources":["footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,SAAS;EACT,kBAAkB;;EAElB,iCAAiC;EACjC,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB,EAAE,6BAA6B;AAClD;;AAGA;EACE,eAAe;AACjB","file":"footer.css","sourcesContent":[".Footer {\n  width: 100%;\n  bottom: 0;\n  position: relative;\n\n  font-family: 'Roboto', sans-serif;\n  color: #333333;\n  font-size: 1rem;\n  padding-top: 1em;\n  padding-bottom: 1em;\n  font-size: small;\n  font-weight: bold;\n  text-align: center;\n  background-color: #dddddd;\n  line-height: 1.3;\n}\n\n.Footer .social-media-icons a {\n  margin-right: 6px; /* Adds space between icons */\n}\n\n\n.Footer .social-media-icons a:last-child {\n  margin-right: 0;\n}\n"]}]);
// Exports
exports.locals = {
	"Footer": "_1PsCjVK2kQyAcKblfwa4DW",
	"social-media-icons": "_20T2Dm60v5T25v-xMvr22E"
};
module.exports = exports;
