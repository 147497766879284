import React from 'react';
import styles from './confirmModal.css';

export default function ConfirmModal({ isOpen, confirmCB, cancelCB }) {

  return (
    <div className={isOpen ? styles.openModal : styles.closedModal}>
      <h3>Delete Provider Profile</h3>
      <p>Are you sure that you would like to delete your profile? This cannot be undone.</p>
      <div>
        <button onClick={cancelCB}>
          Cancel
        </button>
        <button onClick={confirmCB}>
          Confirm
        </button>
      </div>
    </div>
  );
}
