import React from 'react';
import { assetUrl } from '../../../utils/assetsMap';
// import NegativeOutcome from '../../outcomes/NegativeOutcome';
// import NeutralOutcome from '../../outcomes/NeutralOutcome';
// import PositiveOutcome from '../../outcomes/PositiveOutcome';

export default function Outcomes() {

  return (
    <section role="complementary" aria-label="outcome reporting explanation">
      <h1>KNOW Outcomes Reporting Explained</h1>
      <br />
      <p>
        KNOW extracts data from human clinical trials using objective reporting in a chart summary format.
      </p>
      <ol>
        <li>
          <b>For all studies, we provide a link to the article and extract data relevant to: tumor type, natural and conventional therapies, side effects, and outcome.</b>
        </li>
        <li>
          <b>For clinical trials that are high quality, clinically relevant, and have ≥50 participants, we extract and summarize the data in chart format to provide clinicians with a detailed overview pertinent to the study:</b>
          <ul>
            <li>Population</li>
            <li>Tumor type</li>
            <li>Conventional treatment</li>
            <li>Natural therapies</li>
            <li>Side effects</li>
            <li>Outcome</li>
            <li>Quality analysis <a href='https://training.cochrane.org/handbook/current/chapter-08' rel='noopener noreferrer' target='_blank'>Cochrane Assessment of Risk for Randomized Trials</a></li>
          </ul>
        </li>
        <li>
          <b>Searches in KNOW display results according to the type of study:</b>
          <img src={assetUrl('RESULTS_SCREENSHOT_EXAMPLE_PNG')} alt='Results example'/>
        </li>
      </ol>
      KNOW’s positive impact in busy cancer care settings: <a href='https://pubmed.ncbi.nlm.nih.gov/35484545/' rel='noopener noreferrer' target='_blank'>A survey of multidisciplinary healthcare providers utilizing the KNOWintegrativeoncology.org educational platform - PubMed (nih.gov)</a>
    </section>
  );
}
