import React, { useEffect } from 'react';
import { assetUrl } from '../../utils/assetsMap';
import styles from './donate.css';

const cardStyle = {
  width: '80%',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  margin: '5px 0px 40px 0px',
  padding: '16px'
};

const titleStyle = {
  margin: 0,
  padding: 0
};

const contentStyle = {
  marginTop: '8px'
};

export default function Donate() {
  useEffect(() => {
    document.title = 'KNOW - Donate';
  }, []);

  return (
    <div className={styles.donate} role="main" aria-label="donate">
      <div style={cardStyle}>
        <p style={contentStyle}>
          Thank you for your tax deductible donation to KNOWoncology.org.
          <br />
          <br />
          Your generous support helps to improve the lives of people with cancer through integrative cancer care. 
          Your gift facilitates collaboration among healthcare providers, researchers, and advocacy groups 
          to support best education, safety, and clinical decision-making in integrative oncology.
        </p>
      </div>
      <br />
      <br />
      <p className="h5">Donate to the OncANP to support the KNOW Project</p>
      <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=TQ4LGZ45ZZJ64&source=url" rel="noopener noreferrer" target="_blank">
        <img src={assetUrl('DONATE_GIF')} alt="donation button" />
      </a>
      <br />
      <br />
      <p>KNOW Licensing Opportunities</p>
      <p>Interested in licensing KNOW for your organization?<br />
      Please email us: <a href="mailto:info@knowoncology.org?subject=Sponsorship%20Inquiry">info@knowoncology.org</a> 
      </p>
    </div>
  );
}
