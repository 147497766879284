import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './noMatchPage.css';

const NoMatchPage = () => {
  useEffect(() => {
    document.title = 'KNOW - Page Not Found';
  }, []);

  return (
    <>
      <h3 id='not-found'>PAGE NOT FOUND</h3>
      <Link to='/'><h2 id='return-home'>RETURN TO HOME PAGE</h2></Link>
    </>
  );
};

export default NoMatchPage;
