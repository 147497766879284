import React from 'react';
import { Link } from 'react-router-dom';
import styles from './providerBanner.css';

export const ProviderBanner = ({ btnText, link }) => {

  return (
    <div className={styles.providerBanner}>
      <Link className={styles.link} to={link}>{btnText}</Link>
    </div>
  );
};
