import React from 'react';
import { IoIosVideocam } from 'react-icons/io';
export default function VirtualServices() {

  return (
    <div style={{
      backgroundColor: '#f69022df',
      borderRadius: '10px',
      padding: '1px 10px',
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content'
    }}>
      <IoIosVideocam style={{ 
        marginRight: '5px', 
        marginBottom: '3px'
      }}/>
      Virtual Services
    </div>
  );
}
