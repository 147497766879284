import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { resultItem, resultDetails } from './results.css';
import ResultUtilityButtons from './resultUtilityButtons/ResultUtilityButtons.jsx';
import highlightText from '../../../utils/highlightText';
import { tagHeaderToFilterDictionary } from '../../../utils/resultsSectionSetArray';
import { summaryTagHeadersDict } from '../../../utils/tagHeadersDicts';
import { sysReviewFields, clinicalTrialFields, observationalFields, caseReportFields, unknownFields, microtagFields, ignoreEntries } from '../../../utils/displayKeys';
// import PositiveOutcome from '../../outcomes/PositiveOutcome';
// import NeutralOutcome from '../../outcomes/NeutralOutcome';
// import NegativeOutcome from '../../outcomes/NegativeOutcome';
import formatLinks from '../../../utils/formatLinks';
// import styles from './results.css';
                
SummarizedResultItem.propTypes = {
  study: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  filteredKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateCallback: PropTypes.func.isRequired
};

export default function SummarizedResultItem({ study, isOpen, filteredKeys, updateCallback }) {
  // const { docType, studyTypeInt, summaryTitle, year, link } = study;
  const { docType, studyTypeInt, summaryTitle, year, 
    // outcomeResults, 
    link } = study;
  // const displayOutcomes = (outcomeResults => {
  //   const displayOutcomesObj = { positive: '', negative: '', neutral: '' };
  //   if(!outcomeResults) return displayOutcomesObj;
  //   if(outcomeResults.includes('Positive')) displayOutcomesObj.positive = <PositiveOutcome />;
  //   if(outcomeResults.includes('Neutral')) displayOutcomesObj.neutral = <NeutralOutcome />;
  //   if(outcomeResults.includes('Negative')) displayOutcomesObj.negative = <NegativeOutcome />;
  //   return displayOutcomesObj;
  // })(outcomeResults);

  const highlightTagHeader = tagHeader => {
    if(!filteredKeys) return;
    if(filteredKeys.includes(tagHeaderToFilterDictionary[tagHeader]))
      return highlightText(`<highlighter>${tagHeader}</highlighter>`, 'highlighter').fragment;
    return tagHeader;
  };

  const studyTableRows = [];
  const populateRows = field => {
    if(!field) return;

    const tagHeader = summaryTagHeadersDict[field];
    const entry = study[field];
    if(entry && !ignoreEntries.includes(entry)) {
      //Necessary until all doi values are removed from references
      if(field === 'reference') {
        const rx = /(doi.*[\s]?)/;
        studyTableRows.push(
          <tr key={field}>
            <td>
              <strong>{highlightTagHeader(tagHeader)}</strong>
            </td>
            <td>
              {highlightText(entry.replace(rx, ''), 'highlighter').fragment}
            </td>
          </tr>
        );
      }
      else if(!(field === 'link')) {
        studyTableRows.push(
          <tr key={field}>
            <td>
              <strong>{highlightTagHeader(tagHeader)}</strong>
            </td>
            <td dangerouslySetInnerHTML={{ __html: highlightText(entry.toString().replaceAll('\n', '<p>'), 'highlighter').raw }}>
            </td>
          </tr>
        );
      } else {
        studyTableRows.push(
          <tr key={field}>
            <td>
              <strong>{tagHeader}</strong>
            </td>
            <td>
              {formatLinks(link).split(',').map((link, i) => <Fragment key={i}><a href={link.trim()} target='_blank' rel="noopener noreferrer">{link.trim()}</a><br /></Fragment>)}
            </td>
          </tr>
        );
      }
    }
  };

  const searchParams = new URL(window.location).searchParams;
  const singleStudyTest = searchParams.get('id');
  const displayStudyType = fieldArray => {
    if(singleStudyTest){
      const newArray = [...fieldArray];
      newArray.splice(1, 0, 'studyType');
      return newArray;
    } else return fieldArray;
  };

  switch(studyTypeInt) {
    case 1:
      if(docType && docType === 'Summary') displayStudyType(sysReviewFields).forEach(populateRows);
      else displayStudyType(microtagFields).forEach(populateRows);
      break;
    case 2:
      if(docType && docType === 'Summary') displayStudyType(clinicalTrialFields).forEach(populateRows);
      else displayStudyType(microtagFields).forEach(populateRows);
      break;
    case 3:
      if(docType && docType === 'Summary') displayStudyType(observationalFields).forEach(populateRows);
      else displayStudyType(microtagFields).forEach(populateRows);
      break;
    case 4:
      if(docType && docType === 'Summary') displayStudyType(caseReportFields).forEach(populateRows);
      else displayStudyType(microtagFields).forEach(populateRows);
      break;
    case 5:
      unknownFields.forEach(populateRows);
      break;
    default:
      break;
  }

  const highlightedTitle = highlightText(summaryTitle, 'highlighter').fragment;
  // const firstLetter = docType.slice(0, 1);
  // const studyTypeBadge = firstLetter !== 'N' ? <span title={firstLetter === 'S' ? 'Full Summary' : 'Microtag'} className={styles.studyTypeBadge}>{docType.slice(0, 1)}</span> : '';
  const studyTypeText = docType !== 'None' && docType !== 'Microtag' ? <span style={{ fontWeight: '400' }}>[{docType}]</span> : '';
  // const { positive, neutral, negative } = displayOutcomes;
  return (
    <>
      <details className={resultItem} open={isOpen} role='tab'>
        {<summary><strong>{highlightedTitle} ({year}) 
          {/* {positive}
          {neutral}
          {negative}  */}
          {studyTypeText}</strong></summary>}
        {/* {<summary><strong>{highlightedTitle} ({year}) {studyTypeText}</strong></summary>} */}
        <ResultUtilityButtons study={study} updateCallback={updateCallback} />
        <table className={resultDetails}>
          <tbody>
            {studyTableRows}
          </tbody>
        </table>
      </details>
    </>
  );
}
