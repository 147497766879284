import React from 'react';
import './explanation.css';

const Explanation = () => (
  <section id='explanation' aria-label="explanation">
    <p style={{ 'textDecoration': 'underline' }}>How KNOW supports you:</p>
    <div style={{ 'lineHeight': '1.6em' }}>
      ✔ Improved efficiency - Enormous energy is spent to distill current literature.<br />
      ✔ Stay up-to-date - The volume of research in integrative oncology is ever increasing and it&apos;s nearly impossible to stay abreast. Our team keeps the website current with summaries of published studies that the average clinician cannot easily acquire.<br />
      ✔ Knowledge sharing with providers - KNOW references can be pasted into letters, handouts, presentations, and websites..<br />
      ✔ Evidence-informed practice - Informed decisions require access to relevant research.<br />
      ✔ Knowledge base for teaching - A central repository of information supports curriculum for integrative residencies, fellowships, and other training programs.<br />
      ✔ Collaboration for research and publication projects<br />
      ✔ Growing competency and safety - Articles in KNOW provide important information about safety, tolerability, preparation, dosing, and side effects not readily available to clinicians<br />
    </div>

    <br />
    <p style={{ 'textDecoration': 'underline' }}>Applications of KNOW:</p>
    <ul>
      <li>Cancer hospitals can deliver info and updates on evidence at point of care for best safety and efficacy
          (e.g. <a href='https://pubmed.ncbi.nlm.nih.gov/35484545/' target='_blank'>A survey of multidisciplinary healthcare providers utilizing the KNOWintegrativeoncology.org educational platform - PubMed (nih.gov)</a>)</li>
      <li>Integrative medicine providers can improve interprofessional communications by sharing links or full summaries from KNOW</li>
      <li>Advocacy groups can efficiently update public education materials with the latest research. Search topics by year to narrow results to the most current studies eg.</li>
      <li>Faculty and teaching teams can use KNOW as a knowledge base for trainings and to support curriculum development</li>
      <li>Academic groups can conduct mini reviews and guidelines</li>
    </ul>

    <div className="whatyouget">
      <br />
      <p style={{ 'textDecoration': 'underline' }}>KNOW&apos;s <a href='/methodology'>Methods</a>:</p>
      <p>KNOW is updated quarterly, providing current, detailed, and accessible online access to published human research on natural health{' '}
         products and nutrients in oncology care.</p>
      <ul>
        <li>
          The KNOW team systematically searches, screens, and summarizes worldwide human studies and allows the data to be searchable by{' '}
          details such as tumor type, natural therapy, conventional treatment, and side effects. For randomized controlled trials (RCTs),{' '}
          KNOW also provides a Cochrane Risk of Bias analysis.
        </li>
        <li>
          Our clinicians, researchers, and medical librarians efficiently distill data from human studies in integrative oncology so that{' '}
          KNOW users can review key details of studies, export citations, and link to the publisher to access open access or full text articles.
        </li>
      </ul>
    </div>
  </section>
);

export default Explanation;
