// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1HFsJ21Pu8LNKeMLoyXQg- {\n  position: sticky;\n  width: 100vw;\n  background-color: #68689c;\n  color: white;\n  padding: 1em;\n  text-align: center;\n  z-index: 3;\n  bottom: 0;\n}\n\n  ._1HFsJ21Pu8LNKeMLoyXQg- input {\n    border-radius: 7.5px;\n    margin: 5px;\n    min-height: 2.2em;\n  }\n", "",{"version":3,"sources":["cookieConsent.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,SAAS;AAOX;;EALE;IACE,oBAAoB;IACpB,WAAW;IACX,iBAAiB;EACnB","file":"cookieConsent.css","sourcesContent":[".cookieBanner {\n  position: sticky;\n  width: 100vw;\n  background-color: #68689c;\n  color: white;\n  padding: 1em;\n  text-align: center;\n  z-index: 3;\n  bottom: 0;\n\n  input {\n    border-radius: 7.5px;\n    margin: 5px;\n    min-height: 2.2em;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"cookieBanner": "_1HFsJ21Pu8LNKeMLoyXQg-"
};
module.exports = exports;
