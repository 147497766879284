import React from 'react';

export const parsePhoneNumbers = numberArray => {
  if(!Array.isArray(numberArray)) return;

  return numberArray.map(phoneObj => {
    const { number, ...phoneTypes } = phoneObj;
    //to strip "is" from "isMobile", "isHome", etc
    const phoneType = Object.keys(phoneTypes).filter(type => phoneTypes[type]).map(type => type.slice(2));
    return (
      <p key={number}>{phoneType.join(', ')}: {number}</p>
    );
  });
};
