import React from 'react';
import { assetUrl } from '../../../utils/assetsMap';

const ExternalLinks = () => (
  <>
    <h4>In partnership with:</h4>
    <a href='https://oncanp.org/' target='_blank' rel="noopener noreferrer">
      <img src={assetUrl('ONC_ANP_LOGO_PNG')} alt='OncANP logo'></img>
    </a>
    <a href='https://thechi.ca/' target='_blank' rel="noopener noreferrer">
      <img src={assetUrl('CHI_LOGO_NO_TAG_JPG')} alt='Centre for Health Innovation logo'></img>
    </a>
    <a href='https://cancerchoices.org/' target='_blank' rel="noopener noreferrer">
      <img src={assetUrl('CANCER_CHOICES_LOGO_SVG')} alt='Cancer Choices logo'></img>
    </a>
  </>
);

export default ExternalLinks;
