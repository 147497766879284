// https://github.com/zloirock/core-js#babelpolyfill
//*** "As a full equal of @babel/polyfill, you can use this:"
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
//***

// polyfill Promise.all
import 'core-js/features/promise';

import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
import './index.css';
import { Provider } from 'react-redux';
import store from './store';
import PropTypes from 'prop-types';

window.addEventListener('error', function(event) {
  // eslint-disable-next-line quotes
  if(event.error && event.error.message === "Property 'handleEvent' is not callable.") {
    // Handle or log error as you like
    console.error(`Caught and ignored the error: ${event.error.message}`);
    event.preventDefault();
    event.stopImmediatePropagation();
  }
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  
  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(_error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, _errorInfo) {
    // You can log the error to an error reporting service
    // eslint-disable-next-line quotes
    if(error.message === "Property 'handleEvent' is not callable.") {
      console.error(`Error boundary caught and ignored the error: ${error.message}`);
    }
  }

  render() {
    if(this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

render(
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);
