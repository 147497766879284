import React, { useRef } from 'react';
import styles from './footer.css';
import { Link } from 'react-router-dom';
import Bainer from '../bainer/Bainer';
import { getUser } from '../../data/selectors/userAuthSelectors';
import { useSelector } from 'react-redux';
import { assetUrl } from '../../utils/assetsMap';
import SocialIcons from '../home/sidebar/SocialIcons';

const Footer = () => {
  const user = useSelector(getUser);
  const showBainer = user.organizations === 1;
  const footerElement = useRef(null);

  const imgsAndLinks = [
    {
      src: assetUrl('VITAZAN_BANNER_JPG'),
      href: 'https://www.vitazan.com',
      alt: 'Vitazan'
    },
    {
      src: assetUrl('IHC_BANNER_JPG'),
      href: 'https://www.integratedhealthclinic.com',
      alt: 'Integrated Health Clinic',
    },
    {
      src: assetUrl('PARMAR_BANNER_JPG'),
      href: 'https://www.drgurdevparmar.com',
      alt: 'Dr. Gurdev Parmar',
    },
    {
      src: assetUrl('NEW_EARTH_BANNER_JPG'),
      href: 'https://us.fullscript.com/o/catalog?brands_array=%7B%22id%22%3A%22QnJhbmQtODE3%22%2C%22name%22%3A%22New+Earth+Life+Science%22%2C%22logo%22%3A%22%22%7D',
      alt: 'New Earth Life Sciences',
    },
    {
      src: assetUrl('URIEL_BANNER_PNG'),
      href: 'https://www.urielpharmacy.com',
      alt: 'Uriel Pharmacy',
    }
  ];

  return (
    <footer className={styles.Footer} role="contentinfo" aria-label="contact information, disclaimer, and copyright" ref={footerElement}>
      {showBainer && <Bainer imgsAndLinks={imgsAndLinks} />}
      Follow us on social media:<br />
      <div className="social-links" aria-label="social media links">
        <SocialIcons size={25} padding = {{ top: 10, right: 0, bottom: 0, left: 0 }} />
      </div>
      <br />
      <p>Phone &amp; Fax: 1-800-908-5175</p>
      <br />
      Email <a href="mailto:info@knowoncology.org" className="ignore ui-link">info@knowoncology.org</a>
      <br />
      <a id='faqs' href={assetUrl('FAQS_AND_DEFINITIONS_PDF')} target='_blank' rel='noopener noreferrer' className="textLink">FAQs and Definitions</a>
      <br />
      The content on the KNOW website is not intended nor recommended as a substitute for medical advice, diagnosis, or treatment.<br />
      Always seek the advice of your own physician or other qualified health care professional regarding any medical questions or conditions.<br />
      The use of this website is governed by the <Link to='/terms-of-use'>Terms of Use</Link> and <Link to='/privacy-policy'>Privacy Policy</Link>.
      <br />
      <br />
      &copy; 2021 OncANP.org
    </footer>
  );
};

export default Footer;
