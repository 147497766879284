import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../data/actions/userAuthActions';
import { getUser, getAuthError, getUserLoading } from '../../data/selectors/userAuthSelectors';
import { useNavigate } from 'react-router-dom';
import styles from './login.css';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import ReactGA from 'react-ga';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useSelector(getUser);
  const authError = useSelector(getAuthError);
  const userLoading = useSelector(getUserLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'KNOW - Login';
  }, []);

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const handlePasswordChange = ({ target }) => {
    setPassword(target.value);
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    dispatch(setUser(email, password))
      .then(() => {
        setLoading(false);
      });
  };

  const handlePasswordReset = () => {
    return navigate('/forgot-password', { replace: true });
  };

  const handleSignUp = () => {
    return navigate('/join-now', { replace: true });
  };

  useEffect(() => {
    if(user.email && !userLoading) {
      // Set Google Analytics data
      ReactGA.set({
        userId: user._id,
        sessionStart: Date.now()
      });
      ReactGA.event({
        category: 'User',
        action: 'Log in',
        label: user.organizations
      });

      // Perform navigation
      if(window.location.hash) {
        navigate('/' + window.location.hash.slice(1));
      } else {
        navigate('/');
      }
    }
  }, [user.email, user._id, user.organizations, userLoading, navigate]);

  if(loading) return <LoadingSpinner />;

  return (
    <div className={styles.login} role="main" aria-label="login">
      <div className={styles.info} role="complementary">
        <p>
          <b>KNOW</b> is a dynamic clinical and educational tool that provides summaries of up-to-date- research in integrative oncology.
        </p>
        <p>
          <b>KNOW</b> is designed to help you quickly access pertinent information to make evidence-informed decisions.
        </p>
      </div>
      <form onSubmit={handleLoginSubmit}>
        <label> Email:
          <input type='text' onChange={handleEmailChange} value={email} placeholder='email address' autoFocus/>
        </label>
        <label> Password:
          <input type='password' onChange={handlePasswordChange} value={password} placeholder='password'/>
        </label>
        <input type='submit' className={styles.imAButton} value='Login' />
      </form>
      <button className={styles.imAButton} onClick={handlePasswordReset}>Forgot your password?</button>
      <button className={styles.imAButton} onClick={handleSignUp}>Sign Up</button>
      {authError && <p>{authError.message}</p>}
    </div>
  );
};

export default Login;
