import React, { useState } from 'react';
import propTypes from 'prop-types';
import styles from './editSummary.css';
import { optionsDict } from '../../../utils/dropdownSeedData';
import MultipleSelect from './MultipleSelect';
import { updateSummary } from '../../../services/studies';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';
// import { useSelector } from 'react-redux';
// import { getSearchQuery } from '../../../data/selectors/searchSelectors';

const PropTypes = {
  visibility: propTypes.bool.isRequired,
  study: propTypes.object.isRequired,
  closeCallback: propTypes.func.isRequired,
  updateCallback: propTypes.func.isRequired
};

const removeHighlighter = str => {
  if(typeof str !== 'string') return str;
  const rgx = /<\/?highlighter>/gm;
  return str.replace(rgx, '');
};

const initializeSummaryState = summary => {
  return Object.entries(summary).reduce((initialState, [fieldName, fieldValue]) => {
    return { ...initialState, [fieldName]: removeHighlighter(fieldValue) };
  }, {});
};

//Exclude fields that aren't in JotForm-Summaries, Microtags, or Merged (legacy) summaries
const excludeList = ['_id', 'docType', 'createdTime', 'studyTypeInt', 'summarizer', 'airtableId', 'lastModified', 'cancerStatus', 'pathology', 'molecularTargets', 'additionalReferences', 'abstract', 'summaryTitle', 'author', 'reference', 'pediatricPopulation', 'openAccess', 'year', 'isNotable', 'uuid'];

export default function EditSummary({ visibility, study, closeCallback, updateCallback }) {
  if(!visibility) return null;

  // const searchQuery = useSelector(getSearchQuery);
  const [summary, setSummary] = useState(initializeSummaryState(study));
  const [originalLinks, setOriginalLinks] = useState(study.link);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleValueChange = (fieldName, newValue) => {
    setSummary({ ...summary, [fieldName]: newValue });
  };

  const determineInputEl = fieldName => {
    try {
      switch(fieldName) {
        //textarea
        case 'briefSummary':
        case 'populationDescribed':
        case 'participants':
        case 'naturalTherapyDescribed':
        case 'naturalTherapyComponents':
        case 'resultsDescribed':
        case 'adverseEventsDescribed':
        case 'adverseEventsDescribed2':
        case 'sideEffectsDescribed':
        case 'keywords':
        case 'link':
          return <textarea value={summary[fieldName]} onChange={({ target }) => handleValueChange(fieldName, target.value)} />;
  
        //single options:
        case 'qraSequence':
        case 'qraAllocation':
        case 'qraBlinding':
        case 'qraBlindingOutcome':
        case 'qraIncomplete':
        case 'qraSelective':
        case 'qraOther':
        case 'qraJustification':
        case 'interactions':
        case 'sideEffectsAssociated':
        case 'associatedAdverseEvents':
        case 'studyType':
          return (
            <select value={summary[fieldName]} onChange={({ target }) => handleValueChange(fieldName, target.value)}>
              {optionsDict[fieldName].slice(2).map(option => {
                return <option key={option} value={option}>{option}</option>;
              })}
            </select>
          );
  
        // //multiple options:
        case 'conventionalTreatment':
        case 'conventionalTreatmentAgents': 
        case 'sideEffects':
        case 'tumorType':
        case 'outcomeResults': 
        case 'outcomeCategory':
        case 'comparator': 
        case 'naturalTherapyName':
        case 'naturalTherapyCategory':
        case 'designFeatures':
        case 'staging':
          return <MultipleSelect 
            options={optionsDict[fieldName].slice(2)} 
            defaultTrueOptions={summary[fieldName].split(',').map(opt => opt.trim())} 
            setCallback={handleValueChange}
            fieldName={fieldName}
          />;
        
        //default text
        default:
          return <input type='text' value={summary[fieldName]} onChange={({ target }) => handleValueChange(fieldName, target.value)} />;
      }
    } catch(error) {
      console.log(`fieldname causing error: ${fieldName}`);
      console.log(`Error: ${error}`);
    }
  };
  
  const summaryInfo = Object.keys(summary).map(fieldName => {
    if(summary[fieldName] === null || excludeList.includes(fieldName)) return;
    
    const inputEl = determineInputEl(fieldName);
    return (
      <tr key={fieldName}>
        <td className={styles.tdKey}>
          {fieldName}
        </td>
        <td className={styles.tdValue}>
          {inputEl}
        </td>
      </tr>
    );
  });
  
  const handleCancelEdit = () => {
    closeCallback();
  };

  const handleSaveEdit = () => {
    //hit BE to save to pg AND airtable
    //let BE deal with making sure column names are correct
    //bust cache and make new fetch for updated summary
    //TODO: refactor studies into state, refactor updateCallback such that it is not prop drilled five levels.
    setLoading(true);

    // const updateObj = { ...summary };
    const updateObj = Object.keys(summary).reduce((acc, currentKey) => {
      if(!summary[currentKey]) return acc;
      acc[currentKey] = summary[currentKey];
      return acc;
    }, {});
    if(updateObj.link && originalLinks === updateObj.link) {
      delete updateObj.link;
    }
    
    updateSummary(updateObj)
      .then(() => {
        setLoading(false);
        closeCallback();
        updateCallback();
      })
      .catch(err => {
        setErrorMessage(err.message);
      });
  };

  return (
    <div className={styles.modalBackground}>
      {loading ?
        <div style={{ width: '50%', height: '50%', background: 'white' }}>
          <LoadingSpinner />
        </div> :
        <div className={styles.modalContainer}>
          {errorMessage && <p style={{ color: 'red', fontSize: '20px' }}>Error: {errorMessage}</p>}
          <table>
            <tbody>
              {summaryInfo}
            </tbody>
          </table>
          <button type='button' onClick={handleCancelEdit}>Cancel Edit</button>
          <button type='button' onClick={handleSaveEdit}>Save Edit</button>
          {errorMessage && <p style={{ color: 'red', fontSize: '20px' }}>Error: {errorMessage}</p>}
        </div>}
    </div>
  );
}

EditSummary.propTypes = PropTypes;
