// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1xgxBbtb1dufOwJnmSJQOF {\n  align-self: center;\n  flex: 1 1 20%;\n  margin-left: .5em;\n  margin-right: .5em;\n  border: solid #dddddd 1px;\n  border-radius: 5px;\n  background-color: #f6f6f6;\n  padding: 1rem;\n}\n\n.VwQfXjw6oK1A-n9CGUFj7 {\n  display: flex;\n  flex-direction: column;\n  text-align: left;\n  top: 40%;\n  left: 50%;\n  position: fixed;\n  overflow: auto;\n  z-index: 2;\n  margin: 5% auto;\n  padding: 1rem;\n  border: solid #dddddd 1px;\n  border-radius: 5px;\n  background-color: #f6f6f6;\n  min-width: 7em;\n  width: auto;\n  height: 90%;\n  transform: translate(-50%, -50%);\n}\n\n._1lUh_utsv1of8vA1ijIlUB {\n  display: none;\n}", "",{"version":3,"sources":["searchInfo.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,QAAQ;EACR,SAAS;EACT,eAAe;EACf,cAAc;EACd,UAAU;EACV,eAAe;EACf,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,WAAW;EACX,WAAW;EACX,gCAAgC;AAClC;;AAEA;EACE,aAAa;AACf","file":"searchInfo.css","sourcesContent":[".modal {\n  align-self: center;\n  flex: 1 1 20%;\n  margin-left: .5em;\n  margin-right: .5em;\n  border: solid #dddddd 1px;\n  border-radius: 5px;\n  background-color: #f6f6f6;\n  padding: 1rem;\n}\n\n.showModal {\n  display: flex;\n  flex-direction: column;\n  text-align: left;\n  top: 40%;\n  left: 50%;\n  position: fixed;\n  overflow: auto;\n  z-index: 2;\n  margin: 5% auto;\n  padding: 1rem;\n  border: solid #dddddd 1px;\n  border-radius: 5px;\n  background-color: #f6f6f6;\n  min-width: 7em;\n  width: auto;\n  height: 90%;\n  transform: translate(-50%, -50%);\n}\n\n.hideModal {\n  display: none;\n}"]}]);
// Exports
exports.locals = {
	"modal": "_1xgxBbtb1dufOwJnmSJQOF",
	"showModal": "VwQfXjw6oK1A-n9CGUFj7",
	"hideModal": "_1lUh_utsv1of8vA1ijIlUB"
};
module.exports = exports;
