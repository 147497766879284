import React from 'react';
import PropTypes from 'prop-types';
import { Facebook, Linkedin, Instagram, Twitter } from 'lucide-react';

const SocialIcons = ({ size, padding }) => {
  const iconStyle = {
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#7E2E84',
    color: 'white',
    margin: '0 10px',
    transition: 'all 0.3s ease',
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    padding: `${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`,
  };

  const socialLinks = [
    { href: "https://www.facebook.com/KNOWoncology1", aria: "Facebook", Icon: Facebook },
    { href: "https://www.linkedin.com/company/know-oncology/", aria: "LinkedIn", Icon: Linkedin },
    { href: "https://www.instagram.com/knowoncology/", aria: "Instagram", Icon: Instagram },
    { href: "https://www.twitter.com/knowoncology/", aria: "X (formerly Twitter)", Icon: Twitter },
  ];

  return (
    <div style={containerStyle}>
      {socialLinks.map(({ href, aria, Icon }) => (
        <a
          key={href}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={aria}
          style={iconStyle}
          onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#9A4EAA'}
          onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#7E2E84'}
        >
          <Icon size={size * 0.6} />
        </a>
      ))}
    </div>
  );
};

SocialIcons.propTypes = {
  size: PropTypes.number,
  padding: PropTypes.shape({
    top: PropTypes.number,
    right: PropTypes.number,
    bottom: PropTypes.number,
    left: PropTypes.number,
  }),
};

SocialIcons.defaultProps = {
  size: 40,
  padding: { top: 20, right: 0, bottom: 20, left: 0 },
};

export default SocialIcons;
