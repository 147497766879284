function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export default function parseInvites(invitesText) {
  const parsedInvites =  invitesText.split('\n')
    .map(entry => {
      const entryObj = {};
      const splitEntry = entry.split(/[, \t]/).filter(str => str !== '');

      if(isValidDate(new Date(splitEntry[0]))) throw new Error('Date detected without an associated email.');

      if(splitEntry.length > 2) throw new Error('Entries not separated with new line.');

      if(splitEntry.length === 2 && !isValidDate(new Date(splitEntry[1]))) throw new Error('Entries not separated with new line.');

      entryObj.email = splitEntry[0];
      if(splitEntry[1]) entryObj.access_expiration = splitEntry[1];
      return entryObj;
    });
  
  return parsedInvites.filter(invitedUser => !!invitedUser.email);
}
