import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import styles from './multipleSelect.css';

const PropTypes = {
  options: propTypes.array.isRequired,
  defaultTrueOptions: propTypes.array.isRequired,
  setCallback: propTypes.func.isRequired,
  fieldName: propTypes.string.isRequired
};

const initializeState = (options, trueOptions) => {
  const state = options.reduce((acc, curr) => {
    acc[curr] = false;
    return acc;
  }, {});
  trueOptions.forEach(option => {
    state[option] = true;
  });

  return state;
};

export default function MultipleSelect({ options, defaultTrueOptions, setCallback, fieldName }) {
  const [selectState, setSelectState] = useState(initializeState(options, defaultTrueOptions));
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    const trueOptions = Object.keys(selectState).filter((option => selectState[option])).join(', ');
    setCallback(fieldName, trueOptions);
  }, [selectState]);

  const generateOptionHTML = (optionName, onClickCallback) => (
    <label key={optionName}>
      <input type='checkbox' checked={selectState[optionName]} value={optionName} onChange={onClickCallback}  />{optionName}
    </label>
  );

  const handleCheckboxSelect = ({ target }) => {
    setSelectState({ 
      ...selectState, 
      [target.value]: !selectState[target.value] 
    });
  };
  const handleSearchStringChange = ({ target }) => {
    setSearchString(target.value);
  };

  const filteredOptions = Object.keys(selectState).filter(optionName => {
    if(selectState[optionName]) return true;
    if(optionName.toLowerCase().includes(searchString.toLowerCase())) return true;
    return false;
  });
  const optionCheckboxes = filteredOptions.map(optionName => generateOptionHTML(optionName, handleCheckboxSelect));

  return (
    <div className={styles.multipleSelectContainer}>
      <input type='text' value={searchString} onChange={handleSearchStringChange} placeholder='Search' />
      <div className={styles.optionsContainer}>
        {optionCheckboxes}
      </div>
    </div>
  );
}

MultipleSelect.propTypes = PropTypes;
