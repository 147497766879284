// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "section[id=video] h4 {\n    align-self: flex-start;\n    font-weight: bold;\n    margin-bottom: 1em;\n  }\n\n._3kJQiBRPf6yicXXqnRlmS1 {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  flex-wrap: wrap;\n}\n\n._3kJQiBRPf6yicXXqnRlmS1 article {\n    display: flex;\n    flex-direction: column;\n    width: auto;\n    margin-bottom: 1em;\n  }\n\n@media (max-width: 425px) {\n  section[id=video] {\n    width: auto;\n  }\n\n    section[id=video] iframe {\n      width: 100%;\n      height: auto;\n    }\n\n  h4 {\n    text-align: center;\n  }\n}\n\n@media (max-width: 350px) {\n  ._3kJQiBRPf6yicXXqnRlmS1 {\n    padding-bottom: 65%;\n  }\n}", "",{"version":3,"sources":["video.css"],"names":[],"mappings":"AACE;IACE,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;EACpB;;AAGF;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,eAAe;AAQjB;;AANE;IACE,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,kBAAkB;EACpB;;AAGF;EACE;IACE,WAAW;EAMb;;IAJE;MACE,WAAW;MACX,YAAY;IACd;;EAGF;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","file":"video.css","sourcesContent":["section[id=video] {\n  h4 {\n    align-self: flex-start;\n    font-weight: bold;\n    margin-bottom: 1em;\n  }\n}\n\n.videoWrapper {\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n  flex-wrap: wrap;\n\n  article {\n    display: flex;\n    flex-direction: column;\n    width: auto;\n    margin-bottom: 1em;\n  }\n}\n\n@media (max-width: 425px) {\n  section[id=video] {\n    width: auto;\n\n    iframe {\n      width: 100%;\n      height: auto;\n    }\n  }\n\n  h4 {\n    text-align: center;\n  }\n}\n\n@media (max-width: 350px) {\n  .videoWrapper {\n    padding-bottom: 65%;\n  }\n}"]}]);
// Exports
exports.locals = {
	"videoWrapper": "_3kJQiBRPf6yicXXqnRlmS1"
};
module.exports = exports;
