export default function formatLinks(links) {
  const commaSeparatedLinks = links.split(',')
    .map(str => str.trim());
  return commaSeparatedLinks.map(link => {
    return link.split(/(http)s?:/g)
      .filter(x => x)
      .filter((_, i) => i % 2 === 1)
      .map(str => 'https:' + str)
      .join(',');
  }).join(',');
}
