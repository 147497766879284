import React, { useEffect } from 'react';
import styles from './patientResources.css';
import { useSelector } from 'react-redux';
import { getUserLoading } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import InternalLinks from '../home/sidebar/InternalLinks';

export default function ContinuingEducation() {
  const userLoading = useSelector(getUserLoading);
  const { ceContainer } = styles;

  useEffect(() => {
    document.title = 'KNOW - Patient Resources';
  }, []);

  if(userLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={ceContainer} role="main" aria-label="continuing education">
      <h1>Patient Resources</h1>

      <p>&nbsp;</p>
      <p><strong>The KNOW Provider Network</strong>
        <br /><a href='/providers'>Provider Network</a></p>

      <p>&nbsp;</p>
      <p><strong>Patient Brochures</strong>
        <div className="links">
          <InternalLinks />
        </div>
      </p>

      <p>&nbsp;</p>
      <p width="100%"><strong>Advocacy Groups</strong>
        <br />
        <iframe className="airtable-embed"
          src="https://airtable.com/embed/shrPyHT3opfVUqh4W?backgroundColor=purple&viewControls=on"
          frameBorder="0" onmousewheel="" width="100%" height="533"
          style={{ background: 'transparent', border: '1px solid #ccc' }} >
        </iframe>
      </p>
    </div>
  );
}
