import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PhoneForm from './PhoneForm';
import styles from './phoneStyles.css';

PhoneContainer.propTypes = {
  setParentCB: PropTypes.func,
  required: PropTypes.bool,
  editingNumbers: PropTypes.array,
  disableSubmitCB: PropTypes.func
};

export default function PhoneContainer({ 
  setParentCB, 
  required, 
  editingNumbers = [],
  disableSubmitCB
}) {
  const [phoneNumbers, setPhoneNumbers] = useState(editingNumbers);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    if(isEditing || isAdding) disableSubmitCB(true);
    else disableSubmitCB(false);
  }, [isEditing, isAdding]);

  const handleAddNumber = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsAdding(true);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsAdding(false);
    setIsEditing(false);
  };

  const handleSubmitNumber = (newPhoneNumber) => {
    if(isAdding) {
      setPhoneNumbers([...phoneNumbers, newPhoneNumber]);
      setParentCB([...phoneNumbers, newPhoneNumber]);
      setIsAdding(false);
      disableSubmitCB(false);
    }
    if(isEditing) {
      const newNumbers = [...phoneNumbers];
      newNumbers.splice(activeIndex, 1, newPhoneNumber);
      setPhoneNumbers(newNumbers);
      setParentCB(newNumbers);
      setActiveIndex(null);
      setIsEditing(false);
      disableSubmitCB(false);
    }
  };
  const handleDeleteNumber = (index, event) => {
    event.preventDefault();
    event.stopPropagation();
    const newNumbers = [...phoneNumbers];
    newNumbers.splice(index, 1);
    setPhoneNumbers(newNumbers);
    setParentCB(newNumbers);
  };

  const getAddButton = () => {
    if(isEditing || isAdding) return null;
    return (
      <label style={{ display: 'flex', justifyContent: 'center' }}>
        <input
          type="button"
          onClick={handleAddNumber}
          value={`Add${phoneNumbers.length ? ' Another' : ''} Phone Number`}
          className={styles.addButton}
        />
      </label>
    );
  };

  const phoneItems = phoneNumbers.map((phoneNumber, i) => {
    const phoneStringArray = [];
    if(phoneNumber.isMobile) phoneStringArray.push('Mobile');
    if(phoneNumber.isOffice) phoneStringArray.push('Office');
    if(phoneNumber.isHome) phoneStringArray.push('Home');
    if(phoneNumber.isFax) phoneStringArray.push('Fax');
    const phoneString = phoneStringArray.join(' / ');

    return (
      <div className={styles.phoneItem} key={phoneNumber.number}>
        <span>
          {`${phoneNumber.number} ${phoneString ? `[${phoneString}]` : ''}`}
        </span>
        {(!isAdding && !isEditing) && (
          <div style={{ display: 'inline' }}>
            <input
              type="button"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                setActiveIndex(i);
                setIsEditing(true);
              }}
              value="Edit"
            />
            <input
              type="button"
              onClick={(e) => handleDeleteNumber(i, e)}
              value="Delete"
            />
          </div>
        )}
      </div>
    );
  });

  return (
    <div className={styles.phoneContainer}>
      <p>Phone Numbers</p>
      {phoneItems}
      {getAddButton()}
      {(isEditing || isAdding) && <PhoneForm 
        submitCallback={handleSubmitNumber}
        cancelCallback={handleCancel}
        editingPhone={phoneNumbers[activeIndex]}
        required={required}
      />}
    </div>
  );
}
