// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2VpFMrxQ95mdPWueY-GHym {\n  margin: 1rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n  ._2VpFMrxQ95mdPWueY-GHym p {\n    margin: .5rem;\n  }", "",{"version":3,"sources":["renewSub.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AAKzB;;EAHE;IACE,aAAa;EACf","file":"renewSub.css","sourcesContent":[".renewContainer {\n  margin: 1rem;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  p {\n    margin: .5rem;\n  }\n}"]}]);
// Exports
exports.locals = {
	"renewContainer": "_2VpFMrxQ95mdPWueY-GHym"
};
module.exports = exports;
