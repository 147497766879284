import React from 'react';
import PropTypes from 'prop-types';
import styles from './methodButtons.css';

const propTypes = {
  onClick: PropTypes.func.isRequired,
  method: PropTypes.string.isRequired
};

const MethodButtons = ({ onClick, method }) => (
  <section className={styles.MethodButtons}>
    <h1>Methods by Topic:</h1>
    <br/>
    {/* <button className={method === 'all' ? styles.methodButtonActive : styles.methodButton} id='top' name='all' onClick={onClick}>All Cancer Types</button>
    <button className={method === 'breast' ? styles.methodButtonActive : styles.methodButton} name='breast' onClick={onClick}>Breast</button>
    <button className={method === 'neuropathy' ? styles.methodButtonActive : styles.methodButton} name='neuropathy' onClick={onClick}>Neuropathy</button>
    <button className={method === 'thoracic' ? styles.methodButtonActive : styles.methodButton} name='thoracic' onClick={onClick}>Thoracic</button>
    <button className={method === 'curcumin' ? styles.methodButtonActive : styles.methodButton} id='bottom' name='curcumin' onClick={onClick}>Curcumin</button> */}
    <button className={method === 'search' ? styles.methodButtonActive : styles.methodButton} id='top' name='search' onClick={onClick}>
      KNOW Search for Literature
    </button>
    <button className={method === 'outcomes' ? styles.methodButtonActive : styles.methodButton} id='bottom' name='outcomes' onClick={onClick}>
      KNOW Outcomes Reporting
    </button>
  </section>
);

MethodButtons.propTypes = propTypes;

export default MethodButtons;
