import React, { useEffect, useState } from 'react';
import styles from '../knowAdmin.css';
import { deleteReferrerCode, getReferrerCodes } from '../../../services/referrers';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';
import NewReferrerCode from './NewReferrerCode';

export default function ReferrerCodes() {
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getReferrerCodes()
      .then(res => setCodes(res))
      .catch(err => console.log(err))
      .finally(setLoading(false));
  }, []);

  const closeNewCodeModalCallback = () => {
    getReferrerCodes()
      .then(res => setCodes(res))
      .catch(err => console.log(err));
  };

  const handleDeleteCode = id => {
    setLoading(true);
    deleteReferrerCode(id)
      .then(res => setCodes(codes.filter(code => code.referrer != res.referrer)))
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  const referrerCodes = codes.map(code => {
    return (
      <tr key={code.org_name}>
        <td>{code.org_name}</td>
        <td>{code.referrer}</td>
        {/* <td>
          <button type='button' onClick={() => handleEditCode(code._id)}>Edit Code</button>
        </td> */}
        <td>
          <button type='button' onClick={() => handleDeleteCode(code.referrer)}>Delete Code</button>
        </td>
      </tr>
    );
  });

  if(loading) return <LoadingSpinner />;

  return (
    <details className ={styles.functionSection}>
      <summary>Referrer Codes</summary>
      <p>
        Referrer codes are what allow a particular organization to automatically log in their users from their website instead of through our login page. Below, you will find the referrer codes registered for organizations. The organization will use this in the link their users will click to come to our website.
      </p>
      <br />
      <p> 
        Developers for the other website will need a way to dynamically create the link for each user. The link needs to be in the following format:
      </p>
      <br />
      <p>
        https://knowintegrativeoncology.org/autoauth?referrer=*REFERRER CODE HERE*&amp;uid=*USER ID HERE*
      </p>
      <br />
      <p>
        The referrer code listed here will replace *REFERRER CODE HERE*. The organization implementing this will need to uniquely identify each user, and should replace *USER ID HERE* with the unique user identifier. Then, whenever a user follows the link from their organizations&apos; portal, we can identify them and log them into our website.
      </p>
      <NewReferrerCode 
        closeCallback={closeNewCodeModalCallback}
        registeredOrgs={codes.map(code => code.org_name)}/>
      <table>
        <thead>
          <tr>
            <th>Organization</th>
            <th>Referrer Code</th>
            {/* <th>Edit Code</th> */}
            <th>Delete Code</th>
          </tr>
        </thead>
        <tbody>
          {referrerCodes}
        </tbody>
      </table>
      {/* <p>It needs a way to edit referrer codes</p> */}
    </details>
  );
}
