import { request } from './request';

export const searchProviders = async(searchOptions) => {
  return request('/graphql', 'POST', {
    query: `query ($input: String, $filters: FiltersInput, $perPage: Int, $pageNum: Int) {
      search(term: $input, filters: $filters, perPage: $perPage, pageNum: $pageNum) {
        count
        pageNum
        pages
        providers {
          id
          firstName
          lastName
          designations
          locations {
            name
            virtual
            country
            address1
            address2
            city
            state
            zipCode
            phoneNumbers {
              number
            }, 
            email
            url
          }
          professions {
            name
          }
          headshot
          slug
        }
      }
    }`,
    variables: {
      input: searchOptions.searchText,
      filters: searchOptions.filters,
      perPage: searchOptions.perPage,
      pageNum: searchOptions.pageNum
    }
  })
    .then(({ data }) => data.search || {});
};

export const fetchProfessions = () => {
  return request('/graphql', 'POST', {
    query: '{allProfessions{name}}',
    variables: null
  })
    .then(({ data }) => data.allProfessions.map(obj => obj.name));
};

export const fetchFocuses = () => {
  return request('/graphql', 'POST', {
    query: '{allFocuses{name}}',
    variables: null
  })
    .then(({ data }) => data.allFocuses.map(obj => obj.name));
};

export const submitProvider = async(providerInfo) => {
  return request('/graphql', 'POST', {
    query: `mutation($providerIn: ProviderInput) {
      createProvider(provider: $providerIn) {
        locations {
          name,
          virtual,
          virtualOnly,
          suppressAddress,
          country,
          address1,
          address2,
          city,
          state,
          zipCode,
          email,
          url,
          phoneNumbers {
            number,
            isHome,
            isOffice,
            isFax,
            isMobile
          }
        },
        professions {
          name
        },
        focuses {
          name
        },
        serviceAreas {
          name
        },
        headshot,
        designations,
        title,
        bio,
        phoneNumbers {
          number,
          isHome,
          isOffice,
          isFax,
          isMobile
        },
        slug,
        id
      }
    }`,
    variables: { providerIn: providerInfo }
  })
    .then(({ data }) => {
      const providerResult = data.createProvider;
      return providerResult;
    }); 
};

export const submitHeadshot = async(headshotInfo) => {
  return request('/graphql', 'POST', headshotInfo, false);
};


export const updateProvider = async(providerInfo) => {
  return request('/graphql', 'POST', {
    query: `mutation($providerIn: ProviderInput) {
      updateProvider(provider: $providerIn) {
        id,
        locations {
          name,
          virtual,
          virtualOnly,
          suppressAddress
          country,
          address1,
          address2,
          city,
          state,
          zipCode,
          email,
          url,
          phoneNumbers {
            number,
            isHome,
            isOffice,
            isFax,
            isMobile
          }
        },
        professions {
          name
        },
        focuses {
          name
        },
        serviceAreas {
          name,
        }
        designations,
        title,
        bio,
        phoneNumbers {
          number,
          isHome,
          isOffice,
          isFax,
          isMobile
        },
        slug,
        id
      }
    }`,
    variables: { providerIn: providerInfo }
  })
    .then(({ data }) => {
      const result = data.updateProvider;
      return result;
    });
};

export const getProvider = async(slug) => {
  return request('/graphql', 'POST', {
    query: `query ($slug: String) {
      providerBySlug(slug: $slug) {
        locations {
          name,
          virtual,
          suppressAddress,
          country,
          address1,
          address2,
          city,
          state,
          zipCode,
          email,
          url,
          phoneNumbers {
            number,
            isHome,
            isOffice,
            isFax,
            isMobile
          }
        },
        professions {
          name
        },
        focuses {
          name
        },
        serviceAreas {
          name,
        },
        headshot,
        designations,
        title,
        bio,
        firstName,
        lastName,
        phoneNumbers {
          number,
          isHome,
          isOffice,
          isFax,
          isMobile
        }
      }
    }`,
    variables: { slug }
  })
    .then(({ data }) => {
      if(!data.providerBySlug) throw new Error;
      return data.providerBySlug;
    });
};

export const deleteProvider = async(id) => {
  return request('/graphql', 'POST', {
    query: `mutation ($providerId: String) {
      deleteProvider(providerId: $providerId) {
        _id
        ext_uid
        email
        role
        agreed_to_terms
        first_name
        last_name
        full_name
        display_name
        organizations
      }
    }`,
    variables: { providerId: id }
  });
};
