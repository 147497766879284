import React, { useEffect } from 'react';
import Welcome from './welcome/Welcome';
import Video from './video/Video.jsx';
import Explanation from './explanation/Explanation.jsx';
import Sidebar from './sidebar/Sidebar.jsx';
import './home.css';
import NotableTitles from './notableTitles/NotableTitles';
import { getEmail } from '../../data/selectors/userAuthSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { HomeBanner } from './HomeBanner.jsx';
import { inviteFreeTrial } from '../../data/actions/userAuthActions.js';

const Home = () => {
  useEffect(() => {
    document.title = 'KNOW - Home';
  }, []);

  const dispatch = useDispatch();

  const handleInviteFreeTrial = email => {
    return dispatch(inviteFreeTrial(email));
  };

  const email = useSelector(getEmail);

  let homeBanner = null;
  if(!email) {
    homeBanner = <HomeBanner
                   btnText={'2-Week Free Trial'}
                   inviteFreeTrial={handleInviteFreeTrial}
                 />;
  }

  return (
    <main className="grid-x grid-container">
      <div className="cell small-12 medium-8" role="main">
        {homeBanner}
        <Welcome />
        <Video />
        <NotableTitles />
        <Explanation />
      </div>
      <div className="cell small-12 medium-auto">
        <Sidebar />
      </div>
    </main>
  );
};

export default Home;
