// src/services/request.js

export const request = (
  path,
  method,
  body,
  stringify = true,
  useApi = true,
  timeout = 15000
) => {
  if (!path || !method) {
    throw new Error('Both path and method are required for making a request');
  }

  let fetchPath = '';

  /* eslint-disable no-undef */
  // Determine the environment
  const env = process.env.NODE_ENV;
  const appPath = process.env.APP_PATH;
  const beUrl = process.env.BE_URL;
  const apiPath = process.env.API_PATH;
  /* eslint-enable no-undef */

  // Set the fetch path based on the environment
  if (env === 'development') {
    fetchPath = `${beUrl}${useApi ? apiPath : ''}${path}`;
  } else if (env === 'production') {
    const browserUrl = `${window.location.protocol}//${window.location.hostname}`;
    fetchPath = `${browserUrl}${appPath}${useApi ? apiPath : ''}${path}`;
  }

  const fetchOptions = {
    method,
    credentials: 'include',
    headers: {}
  };

  if (body) {
    fetchOptions.body = stringify ? JSON.stringify(body) : body;
    if (stringify) {
      fetchOptions.headers['Content-Type'] = 'application/json';
    }
  }

  const controller = new AbortController();
  fetchOptions.signal = controller.signal;

  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      controller.abort();  // Actually cancels the request
      reject(new Error('Request timed out'));
    }, timeout);
  });

  const fetchPromise = fetch(fetchPath, fetchOptions)
    .then(async res => {
      const contentType = res.headers.get('Content-Type') || '';
      const isJson = contentType.includes('application/json');

      if (!res.ok) {
        const error = new Error('Request failed');
        error.response = isJson ? await res.json() : await res.text();
        throw error;
      }

      return isJson ? await res.json() : await res.text();
    });

  return Promise.race([fetchPromise, timeoutPromise]);
};
