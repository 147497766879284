const { ASSETS_PATH } = process.env; // eslint-disable-line no-undef
const { USE_GDRIVE } = process.env; // eslint-disable-line no-undef

const gDriveBaseUrl = 'https://drive.google.com/uc?id=';

export const assetUrl = (assetKey, useGdrive = true) => {
  useGdrive = JSON.parse(USE_GDRIVE);
  // map to either a filename if referring to a file in a traditional filesystem
  // or an Google Drive id if using GDrive like a CDN
  if(!assetKey) throw new Error('Must pass an assetKey for url generation');

  const [filename, fileId] = assetsMap[assetKey];
  return useGdrive ?
    `${gDriveBaseUrl}${fileId}` :
    `${ASSETS_PATH}/${filename}`;
};

// map image and other asset locations to create ability to change in a central location
const assetsMap = {
  // FULL SIZE IMAGES
  ARTICLE_SCREENSHOT_EXAMPLE: ['Article_Screenshot_Example.png', '1-O2PC_ymdEURlbd0mpYFJdq-OSgAUlhH'],
  CHI_LOGO_NO_TAG_JPG: ['TheCHi-LOGO-noTag-web.jpg', '1uAUgvx_yd84Y7JCqVKVHwLQdZg25bFrb'],
  CANCER_CHOICES_LOGO_SVG: ['cancer-choices-logo.svg', '1nJM_E7e9Z-ceiF9SamnKnjzGn8cTMl2W'],
  CTCA_IMG: ['ctca_img.png', '1GPbX63qnHXkbYA9UpYR3h-2xQQfewi_c'],
  DONATE_GIF: ['donate.gif', '1Nqy14lWmzqoGHu7hsVsTl1gP47pdgz2L'],
  IHC_LOGO_PNG: ['ihc_logo.png', '1U4fuiEDMQzCV8eZhPsr_WwbHw801naPe'],
  ONC_ANP_LOGO_PNG: ['oncanp_logo.png', '1KrmLWvcDtzsM4rgBVuSbibD88ifOae6J'],
  RESULTS_SCREENSHOT_EXAMPLE_PNG: ['Results_Screenshot_Example.png', '12LfpWEqPdC1y35orPFXLpQip6Q77qgkk'],
  ATTRIBUTIONS_TREE_LEFT_PNG: ['attributions-tree-transparent-left.png', '11a5CU0KHAMyHBzFypAr41Y25fO0EwzH-'],
  ATTRIBUTIONS_TREE_RIGHT_PNG: ['attributions-tree-transparent-right.png', '15yw8xghsbhusl6DTlhXbNKZp2q8paDYG'],

  // THUMBNAILS
  DATA_QUALITY_THUMB: ['data_quality_ss.png', '1Xmt4fIFtqKrj7WiPFfWQtIjuqOJWwoTJ'],
  INTERACTIONS_SEELY_THUMB: ['interactions_seely_img.png', '1mBXoQD7ZDRwx03WKWqTE7EjYs9V46W69'],
  IVC_THUMB: ['IntravenousVitaminC.png', '1jEV9qn-nb2dHzGRqqsCFz7aQfnqZ1DqJ'],
  PROTECTING_THE_HEART_THUMB: ['Protecting the heart patient education thumbnail.png', '12j4viWvNEVafQTgyFKil1P9PpuVhujpB'],
  TAM_HANDOUT_THUMB: ['tam_handout_ss.png', '1dTHfe6cSbYdIRkYvQsDsJ9FFd1jYOoxi'],
  TAM_POSTER_THUMB: ['tam_poster_ss.png', '1g8PAg9BCRI9AlMlXCgC3UfAPyMGA_Pn_'],
  TOP_50_SYS_REVIEWS_THUMB: ['top_50_sys_reviews_thumb.png', '1OEBCE5tbs7cF1Fd25MBL8tafhxcfKEXs'],
  WELLNESS_DURING_TAMOXIFEN_TX_THUMB: ['Wellness during tamoxifen treatment thumbnail.png', '1kZbw8qzbWnXVBfUUt3P3_ViVFfAF9Ysw'],

  // PDFS
  CTCA_INTERACTIONS_POSTER_PDF: ['ctca_interactions_poster.pdf', '12L0vaXvvJFwxtTIRRmTeXZar3bfkY8z-'],
  DATA_QUALITY_POSTER_PDF: ['KNOW Data Quality Poster OncANP 2020 FINAL.pdf', '13SwG6wlR_B13Ff91EdzSaU2NbSO8uVM1'],
  FAQS_AND_DEFINITIONS_PDF: ['FAQs and Definitions.pdf', '1vZG34WzU67CAj6I2snZbGVIzdtmte6ij'],
  INTERACTION_SEELY_PDF: ['interactions_seely.pdf', '1JTu2i5HCCYki9f9AHzHBznCpQcGHP6_u'],
  IVC_PDF: ['IntravenousVitaminC.pdf', '1ToXbv43FMVsu35C1KRMrrgoGZzbvMGxB'],
  KNOW_CORP_SPONSORSHIP_PDF: ['2020 KNOW Corporate Sponsorship.pdf', '1a-6jD0Ea4gvK7KiofRMttRDhSRwblQ1Q'],
  PROTECTING_THE_HEART_PDF: ['Protecting the heart patient education.pdf', '1b5Ype4zvolFtuWQymxUTq2m8vIJ4tEwE'],
  TAMOXIFEN_PATIENT_HANDOUT_PDF: ['Tamoxifen patient handout.pdf', '1g8PAg9BCRI9AlMlXCgC3UfAPyMGA_Pn_'],
  TAMOXIFEN_POSTER_2019_PDF: ['Tamoxifen poster 2019 .pdf', '15f3pN5vD6c08ghp9UZPxZaMoUfBDd5Fj'],
  TOP_50_SYS_REVIEWS: ['Top 50 systematic reviews in KNOW.pdf', '11C8j239ghjDFueUk6UsQ4y0Ddz-muWgz'],
  WELLNESS_DURING_TAMOXIFEN_TX_PDF: ['Wellness during tamoxifen treatment handout.pdf', '1htLklRMrTMIfTRHhMaPBln87cCmcvd2z'],

  // BANNERS
  BIOCEPT_BANNER_JPG: ['bainers/bioc.jpg', '13_kItzTpc9VmvWCP7v6TpZJZkRzQz2xj'],
  MEGASPORE_BANNER_JPG: ['bainers/b1.jpg', '1euTR5XK6cmsUAIBpA9kBmfZmC5igFHzK'],
  NATUROPATHIC_SPECIALISTS_BANNER_JPG: ['bainers/b2.jpg', '1yP-bdSk9Q34c2Pa-T2QhTV_cacjI3o-g'],
  SEATTLE_INTEGRATIVE_ONCOLOGY_BANNER_JPG: ['bainers/sio.jpg', '126FCMD6egBqSbqLEUa5swSPyIV_qDDiI'],
  VITAZAN_BANNER_JPG: ['bainers/vitazan.jpg', '1RDQEwUnqjWf_lGBnFIhQkyP9YHciTlDR'],
  IHC_BANNER_JPG: ['bainers/ihc_banner.jpg', '1YmpWR9GUeqEh14QJoMnW6dvHz-UFgLTD'],
  PARMAR_BANNER_JPG: ['bainers/parmar_banner.jpg', '1MYnS8qvdyVbT6oGqRyDBrbE1bwVhqM9c'],
  NEW_EARTH_BANNER_JPG: ['bainers/new_earth_banner.jpg', '1JNBYWxpQ4SPtD92WUVXfBOJus0flRJ1J'],
  URIEL_BANNER_PNG: ['bainers/uriel-banner.png', '1r2Pfh5-v6dd-S2jQVn1g1nqq-rzC7Fkk'],
};
