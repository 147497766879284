import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getUser, getUserLoading } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { orgSignup, signupGuest, getAllOrgs } from '../../services/knowAdmin';
import EditOrg from '../editOrg/EditOrg';
import OrgUserTable from '../orgUserTable/OrgUserTable';
import formatDate from '../../utils/formatDate';
import styles from './knowAdmin.css';
import ReferrerCodes from './referrerCodes/ReferrerCodes';

export default function KnowAdmin() {
  const navigate = useNavigate();
  const admin = useSelector(getUser);
  const adminLoading = useSelector(getUserLoading);
  const [message, setMessage] = useState('');
  const [orgName, setOrgName] = useState('');
  const [orgAddress, setOrgAddress] = useState('');
  const [orgWebAddress, setOrgWebAddress] = useState('');
  const [orgContactPerson, setOrgContactPerson] = useState('');
  const [orgPhoneNumber, setOrgPhoneNumber] = useState('');
  const [orgEmail, setOrgEmail] = useState('');
  const [orgExpiration, setOrgExpiration] = useState('');
  const [maxUsers, setMaxUsers] = useState('');
  const [orgInviteLoading, setOrgInviteLoading] = useState(false);
  const [guestEmail, setGuestEmail] = useState('');
  const [guestLoading, setGuestLoading] = useState(false);
  const [allOrgs, setAllOrgs] = useState([]);
  const [allOrgsLoading, setAllOrgsLoading] = useState(false);
  const [orgEdit, setOrgEdit] = useState(null);
  const [modalState, setModalState] = useState(false);
  const [orgUserDetailsSelect, setOrgUserDetailsSelect] = useState('GUESTS');
  const [orgUserDetailsId, setOrgUserDetailsId] = useState(null);
  const [orgUsersLoading, setOrgUsersLoading] = useState(false);
  const initialRender = useRef(true);

  useEffect(() => {
    document.title = 'KNOW - Know Admin';
  }, []);

  useEffect(() => {
    if(initialRender.current === false){
      setAllOrgsLoading(true);
      getAllOrgs()
        .then(res => {
          const [{ _id: guestOrgId }] = res.filter(({ org_name }) => org_name === 'GUESTS');
          setOrgUserDetailsId(guestOrgId);
          setAllOrgs(res);
          setAllOrgsLoading(false);
        });
    } else initialRender.current = false;
  }, [admin]);

  useEffect(() => {
    if(message === '') return;
    const messageTimer = setTimeout(() => setMessage(''), 20000);
    return () => clearTimeout(messageTimer);
  }, [message]);

  useEffect(() => {
    if(admin.role !== 'know-admin') navigate('/');
  }, [admin.role, navigate]);

  if(adminLoading || orgInviteLoading || guestLoading || allOrgsLoading || orgUsersLoading) return <LoadingSpinner />;

  initialRender.current = false;

  const handleOrgNameChange = ({ target }) => setOrgName(target.value);
  const handleOrgAddressChange = ({ target }) => setOrgAddress(target.value);
  const handleOrgWebAddressChange = ({ target }) => setOrgWebAddress(target.value);
  const handleOrgContactPersonChange = ({ target }) => setOrgContactPerson(target.value);
  const handleOrgPhoneNumberChange = ({ target }) => setOrgPhoneNumber(target.value);
  const handleOrgEmailChange = ({ target }) => setOrgEmail(target.value);
  const handleOrgExpirationChange = ({ target }) => setOrgExpiration(target.value);
  const handleMaxUsersChange = ({ target }) => setMaxUsers(target.value);
  const handleOrgSubmit = event => {
    event.preventDefault();
    setOrgInviteLoading(true);
    const orgInfo = {
      org_name: orgName,
      address: orgAddress,
      contact_person: orgContactPerson,
      contact_phone_number: orgPhoneNumber,
      contact_email: orgEmail,
      license_expire: orgExpiration,
      max_users: maxUsers
    };
    orgSignup(orgInfo)
      .then(newOrg => {
        setMessage(newOrg.message);
        setAllOrgsLoading(true);
        setOrgInviteLoading(false);
      })
      .catch(err => {
        setMessage(err.message);
        setOrgInviteLoading(false);
      })
      .finally(() => {
        getAllOrgs()
          .then(res => setAllOrgs(res))
          .catch(err => setMessage(err.message))
          .finally(() => setAllOrgsLoading(false));
      });
  };
  const handleGuestEmailChange = ({ target }) => setGuestEmail(target.value);
  const handleGuestSubmit = event => {
    event.preventDefault();
    setGuestLoading(true);
    const guestInfo = { email: guestEmail };
    signupGuest(guestInfo)
      .then(newGuest => {
        setMessage(newGuest.message);
        setGuestLoading(false);
      })
      .catch(err => {
        setMessage(err.message);
        setGuestLoading(false);
      });
  };
  const handleOrgUserDetailsChange = ({ target }) => {
    const [{ _id: orgId }] = allOrgs.filter(({ org_name }) => org_name === target.value);
    setOrgUserDetailsId(orgId);
    setOrgUserDetailsSelect(target.value);
  };

  const allOrgInfo = allOrgs.map(org => (
    <tr key={org._id}>
      <td>{org.org_name}</td>
      <td>{org.contact_person}</td>
      <td>{org.contact_phone_number}</td>
      <td>{org.contact_email}</td>
      <td>{org.address}</td>
      <td><a href={org.web_address} target='_blank' rel="noopener noreferrer"></a>{org.web_address}</td>
      <td>{formatDate(org.joined)}</td>
      <td>{formatDate(org.license_expire)}</td>
      <td>{org.max_users}</td>
      <td>
        <button type='button' onClick={() => {
          setOrgEdit(org);
          setModalState(true);
        }}>
          Edit Org</button>
      </td>
      {/* <td>Remove</td> */}
    </tr>
  ));
  const orgNameOptions = allOrgs.map(({ org_name }) => (
    <option key={org_name} value={`${org_name}`}>
      {org_name}
    </option>
  ));

  const usersLoadingCallback = loadingBool => setOrgUsersLoading(loadingBool);
  const closeModalCallback = (editedOrg) => {
    if(editedOrg){
      const orgIndex = allOrgs.map(org => org._id).indexOf(editedOrg._id);
      const newOrgArray = [...allOrgs];
      newOrgArray.splice(orgIndex, 1, editedOrg);
      setAllOrgs(newOrgArray);
      setOrgUserDetailsId(0);
      setOrgUserDetailsId(editedOrg._id);
      setMessage(`${editedOrg.org_name} account details edited.`);
    }
    setOrgEdit(null);
    setModalState(false);
  };

  const modalBlur = modalState ? { filter: 'blur(2px)' } : {};

  return (
    <div className={styles.adminContainer}>
      <h1>Know Onc. Administration</h1>
      <EditOrg org={orgEdit} closeCallback={closeModalCallback} />
      <div className={styles.container} style={modalBlur}>
        <p className={styles.message}>{message}</p>
        <details className={styles.functionSection}>
          <summary>New Organization Invitation</summary>
          <p>Register a new Organizaion here. Upon submission, the organization will be registered, and an email will be sent to the inbox of the email you provided, asking the recipent to sign up as the admin of the new organization.</p>
          <form onSubmit={handleOrgSubmit}>
            <label htmlFor="orgName">Org Name</label>
            <input id='orgName' type='text' value={orgName} onChange={handleOrgNameChange} placeholder='Org name' />
            <label htmlFor='orgAddress'>Org Address</label>
            <input id='orgAddress' type='text' value={orgAddress} onChange={handleOrgAddressChange} placeholder='Org Address' />
            <label htmlFor='orgWebAddress'>Web Address</label>
            <input id='orgWebAddress' type='text' value={orgWebAddress} onChange={handleOrgWebAddressChange} placeholder='Web Address' />
            <label htmlFor='orgContact'>Org Contact Person</label>
            <input id='orgContact' type='text' value={orgContactPerson} onChange={handleOrgContactPersonChange} placeholder='Org Contact Person' />
            <label htmlFor='orgPhone'>Org Phone Number</label>
            <input id='orgPhone' type='text' value={orgPhoneNumber} onChange={handleOrgPhoneNumberChange} placeholder='Org Phone Number' />
            <label htmlFor='orgEmail'>Admin Email</label>
            <input id='orgEmail' type='text' value={orgEmail} onChange={handleOrgEmailChange} placeholder='Email' />
            <label htmlFor='orgExp'>Org License Expiration Date</label>
            <input id='orgExp' type='text' value={orgExpiration} onChange={handleOrgExpirationChange} placeholder='License Expiration' />
            <label htmlFor='orgMaxUsers'>Max Number of Org Users</label>
            <input id='orgMaxUsers' type='text' value={maxUsers} onChange={handleMaxUsersChange} placeholder='Max number of users' />
            <button type='submit'>Send Org Invite</button>
          </form>
        </details>
        <ReferrerCodes />
        <details className={styles.functionSection}>
          <summary>Guest Invitations</summary>
          <p>Enter an email to send that person guest login credentials. A secure, randomly generated password will be sent to them to log in with, which they may change if they wish. A guest will have slightly limited access to the site, and the guest&apos;s access will expire automatically after 30 days.</p>
          <form onSubmit={handleGuestSubmit}>
            <label htmlFor='guestEmail'>Guest Email</label>
            <input id='guestEmail' type='text' value={guestEmail} onChange={handleGuestEmailChange} placeholder={'Guest\'s email'} />
            <button type='submit'>Send Guest Invite</button>
          </form>
        </details>
        <details className={styles.functionSection}>
          <summary>Organizations</summary>
          <p>Here are all registered organizations. Currently they can not be deleted, but they can be edited. Max Users is the maximum number of registered users an organization is allowed to have, and does not take into account any open invitations an organization may have. The license expiration is when all users of the organization will lose access to the KNOW website automatically. It is possible for an organization&apos;s admin to have individual user&apos;s access expire before the end of the license period. If the license expiration is changed, the access expiration date for the organization&apos;s adminsitrators will automatically update to the new lic expiration date. The new expiration date will not change automatically for regular users of the organization, but there is a button to change the date for those regular users all at the same time.</p>
          <table>
            <thead>
              <tr>
                <th>Org Name</th>
                <th>Contact Person</th>
                <th>Contact Phone Number </th>
                <th>Contact Email</th>
                <th>Address</th>
                <th>Web Address</th>
                <th>Joined</th>
                <th>License Expiration</th>
                <th>Max Users</th>
                <th>Edit Org</th>
                {/* <th>Remove</th> */}
              </tr>
            </thead>
            <tbody>
              {allOrgInfo}
            </tbody>
          </table>
        </details>
        <details className={styles.functionSection}>
          <summary>Organization Users</summary>
          <label> Organization Name:
            <select value={orgUserDetailsSelect} onChange={handleOrgUserDetailsChange}>
              {orgNameOptions}
            </select>
            <OrgUserTable
              admin={admin}
              orgId={orgUserDetailsId}
              loadingCallback={usersLoadingCallback}
              messageCallback={setMessage}
              open={true}
              orgName={orgUserDetailsSelect} />
          </label>
        </details>
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
}
