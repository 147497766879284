import React from 'react';
import PropTypes from 'prop-types';
import styles from './providerNetwork.css';
import ProviderResultSummary from './ProviderResultSummary';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { useSelector } from 'react-redux';
import { getProviderQuery, getQueried } from '../../data/selectors/providerSelectors';
import Pagination from '../studies/pagination/Pagination';

ProviderResults.propTypes = {
  results: PropTypes.shape({
    count: PropTypes.number,
    pageNum: PropTypes.number,
    pages: PropTypes.number,
    providers: PropTypes.array
  }),
  handlePagination: PropTypes.func,
  loading: PropTypes.bool
};

export default function ProviderResults({ results, handlePagination, loading }) {
  const queried = useSelector(getQueried);
  const query = useSelector(getProviderQuery);
  if(loading) return <LoadingSpinner />;

  if(queried && !results?.providers?.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', margin: '1em' }}>
        <p className={styles.noResults}>
          No results found
        </p>
      </div>
    );
  }

  const summaryCards = results.providers.map(provider => (
    <ProviderResultSummary 
      key={provider.id}
      providerResult={provider}
    />
  ));
  
  return (
    <div>
      <div className={styles.resultsContainer}>
        {summaryCards}
      </div>
      {queried && <Pagination
        fullCount={results.count}
        masterSearchQuery={query}
        callback={handlePagination}
      />}
    </div>
  );
}
