import { SET_FOOTER_INFO, SET_COOKIE_BANNER_INFO } from '../actions/stylesActions';

const initialState = {
  footerInfo: {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0
  },
  cookieBannerInfo: {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0
  }
};

export const stylesReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_FOOTER_INFO: 
      return { ...state, footerInfo: action.payload };
    case SET_COOKIE_BANNER_INFO:
      return { ...state, cookieBannerInfo: action.payload };
    default: 
      return state;
  }
};
