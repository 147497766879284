const naturalTherapyAgents = [
  'Natural Therapy Agents',
  'All Natural Therapy Agents',
  'Achillea',
  'Aconite',
  'Actea',
  'Actinidia valvata',
  'Acupuncture',
  'Adenine',
  'Agaricus',
  'Agastachis Herba',
  'Agrimony',
  'AHCC',
  'Aidi',
  'Alanine',
  'Alanyl-glutamine',
  'Alcea digitata',
  'Alfalfa',
  'Algae',
  'Alismatis Rhizoma',
  'Almond',
  'Aloe vera',
  'Alpha-lipoic acid',
  'Amaranth',
  'Amukkara chooranam',
  'Amyl nitrosum',
  'Angelica sinensis',
  'Anthocyanins',
  'Anti-flatulence diet',
  'Antrodia cinnamomea',
  'Arginine',
  'Arisaema consanguineum',
  'Aristolochia longa',
  'Arnica',
  'Aromatherapy',
  'Aronia concentrate',
  'Arsenicum',
  'Artesunate',
  'Ascorbic acid',
  'Astaxanthin',
  'Astragalus',
  'Atractylodes',
  'Aurum',
  'B vitamin',
  'Bacillus licheniformis',
  'Baicalin',
  'Baihe',
  'Baishao',
  'Baiwei',
  'baking soda',
  'Barley',
  'Bee venom',
  'Beeswax',
  'Belladonna',
  'Bellis',
  'Bengal gram',
  'Berberine',
  'Bergamot',
  'beta-carotene',
  'beta-elemene',
  'Beta-glucan',
  'beta-hydroxy-beta-methylbutyrate',
  'Betaine',
  'Bezoar',
  'Biotin',
  'Black cohosh',
  'Bojungikki-tang Granule',
  'Boron',
  'Boswellia',
  'Bran',
  'Branched chain amino acids',
  'Breathing exercises',
  'Bromelain',
  'Brucea javinica',
  'Bryophyllum pinnatum',
  'Bu Gu Zahi',
  'Burdock root',
  'Byronia',
  'Cadmium sulphate',
  'caffeine',
  'Calamine',
  'Calcarea carbonicum',
  'Calcium',
  'calcium pantothenate',
  'Calendula',
  'Caloric restriction',
  'Cang Zhu',
  'Cannabis',
  'Cantharidin',
  'Carbohydrates',
  'Carnitine',
  'Casearia sylvestris',
  'Casein',
  'Cat\'s claw',
  'Causticum',
  'Cesium Chloride',
  'Chai Hu Shu Gan Tang',
  'Chamomile',
  'Chamomilla',
  'Chan Pi',
  'Chinese Herbal formula',
  'Chinese herbs',
  'Chlorella',
  'chlorophyll',
  'chondroitin',
  'Chong Lou',
  'cinnamon',
  'Cinobufacini',
  'Cishi',
  'Citri Unshiu Pericarpium',
  'Citrus',
  'Clove',
  'Cobalt',
  'Cocculine',
  'Cocculus',
  'Codonopsis',
  'Coenzyme Q10',
  'coffee',
  'Cognitive training',
  'Colostrum',
  'Conium',
  'Coptis chinensis',
  'Coptis Root',
  'Coptis-Kush',
  'Cordyceps sinensis',
  'coriander',
  'Coriolus',
  'Corn oil',
  'coumestrol',
  'Counseling',
  'Cranberry',
  'Crataegi Fructus',
  'Creatine monohydrate',
  'cumin',
  'Curcumin',
  'Cystine',
  'd-mannose',
  'Dachengqi Tang',
  'Daikenchuto',
  'Dan Dou Chi',
  'Dang Gui Liu Huang Tang',
  'Danggui Buxue Decoction',
  'Danpi',
  'Devil\'s claw',
  'Dexpanthenol',
  'DHA',
  'DHEA',
  'Di Bella Method',
  'diet',
  'Dietary counseling',
  'Dietary fat',
  'Dietary Protein Restriction',
  'Dihuang',
  'Diindolymethane',
  'Dingqi',
  'Dixiong Decoction',
  'Dokhwaljihwang-tang',
  'Echinacea',
  'Echium',
  'Electroacupuncture',
  'Energy and Protein rich diet',
  'Energy healing',
  'Essential oils',
  'Essiac',
  'Estriol',
  'Evening primrose oil',
  'Exercise',
  'fasting',
  'Fatigue reduction diet',
  'Feng Fang',
  'fermented milk',
  'fiber',
  'Fish oil',
  'Flavonoids',
  'Flax',
  'Fluoric acidum',
  'Fo ti',
  'Folate',
  'Forsythia',
  'Foshou',
  'Fructus bruceae',
  'Fruit',
  'Fucoidan',
  'Fuling',
  'Fuxiaomai',
  'Fuzheng kang\'ai',
  'Fuzheng quyu',
  'Galacto-oligosaccharides',
  'gamma-ray',
  'Ganlu Xiaodu Dan',
  'Ganoderma lucidum',
  'Garlic',
  'General CAM treatment',
  'Gentian',
  'Gentian violet',
  'Gentian violet dressing',
  'Geonchilgyebok-jeong',
  'Geranium',
  'Gereisanin',
  'Gerson Therapy',
  'Ginger',
  'Ginkgo biloba',
  'Ginseng',
  'Glehnia',
  'Glucosamine',
  'Glutamic acid',
  'Glutamine',
  'Glutathione',
  'Glycine',
  'Glycyrrhiza',
  'Glycyrrhiza uralensis',
  'Glycyrrhizic acid',
  'Gonzalez Therapy',
  'Goshajinkigan',
  'Gouteng',
  'Grape juice',
  'Grape seed extract',
  'Green Tangerine Peel',
  'Green Tea',
  'Grifola frondosa',
  'Guarana',
  'Guilongtongluofang',
  'Hachimijiohan',
  'Hamamilis',
  'Han Lian Cao',
  'Hangeshashin-to',
  'Hangeshashinto',
  'Helichrysum',
  'Henna',
  'Hepar sulph',
  'Herbal Therapy',
  'Herbs',
  'HESA-A',
  'Hesperidin methyl chalcone',
  'High fiber diet',
  'Hippospongia communis',
  'Histidine',
  'Hochu-ekki-to (TJ-41)',
  'Hochuekkito',
  'Hoelen',
  'Holy basil',
  'Homeopathy',
  'Hu Zhang',
  'Huaier Granule',
  'Huang Jing',
  'Huang Lian',
  'Huangbai',
  'Hwanggibujeong-dan',
  'Hyaluronate',
  'hydrocolloid',
  'Hydroelectric baths',
  'Hydrotherapy',
  'Hyperbaric Oxygen',
  'Hypericum',
  'hyperthermia',
  'Ignatia',
  'Immune enhancing supplement',
  'Immunonutrition',
  'Indigo root',
  'Iron',
  'Isoflavones',
  'Javanica',
  'Ji-nei-jin',
  'Jia Wei Xiao Yao San',
  'Jian Pi Li Qi',
  'Jianpi Jiedu',
  'Jianpi Ligan',
  'Jiawei Huangqi Guizhi Wuwu',
  'Jie-du',
  'Jinfukang',
  'Jinlong Capsule',
  'Jinlong Capsules',
  'Jinlongshe Granule',
  'Jojoba',
  'Juzentaihoto',
  'Kali bic',
  'Kali Carbonicum',
  'Kampo',
  'Kang Liu Zeng Xiao Jiao Gu Lan',
  'Kang\'ai xiaoshui',
  'Kanglaite',
  'Kang\'ai Xiaoshui',
  'Keishi-bukuryo-gan (TJ-25)',
  'Ketogenic',
  'Korean Herbal formula',
  'Korean medicine',
  'Kuan-sin-yin',
  'Kukkil parpam',
  'Kushan',
  'L-isoleucine',
  'L-valine',
  'Lachesis',
  'Lactoferrin',
  'laetrile',
  'Lavender',
  'Lemon',
  'Lentil lectin',
  'lentinan',
  'Leucine',
  'Lignans',
  'Ligustrum',
  'Limonene',
  'Ling Zhi',
  'Linoleic acid',
  'Linolenic acid',
  'Lithospermum oil',
  'Liu-He-Dan',
  'long chain triglycerides',
  'Low carbohydrate diet',
  'Low fat diet',
  'Low fat high fiber diet and physical activity',
  'Lumbricus',
  'Lycium',
  'Lycopene',
  'Lycopodium',
  'Lysine',
  'Macleaya',
  'Madulai manappagu',
  'Magnesium',
  'Magnetic therapy',
  'Magnolia',
  'Maidong',
  'Maitake',
  'Malic cid',
  'Mallow',
  'Mandarin',
  'Manganese',
  'Manuka',
  'Mao-ren-shen',
  'Marutham pattai chooranam',
  'Mathumega chooranam',
  'Mauktikyukta Kamdudha',
  'Mauktikyukta Praval Panchamruta',
  'Maytenus ilicifolia',
  'Meditation',
  'medium chain triglycerides',
  'Medium chain triglycerides',
  'Melatonin',
  'Mentha piperita',
  'Menthol',
  'Mercurius',
  'Meridian therapy',
  'Methionine',
  'Methylcobalamin',
  'Mikania glomerata',
  'Milk protein isolate',
  'Milletia',
  'Mindfulness',
  'Mistletoe',
  'Morinda citrifolia',
  'Movement',
  'Moxibustion',
  'Mucotrol',
  'Mugwort',
  'Muli',
  'Multivitamin',
  'Mushrooms',
  'Mylabris',
  'myrrh',
  'N-acetylcysteine',
  'Natrum muriaticum',
  'Natural triglycerides-fitosterols',
  'Navy bean',
  'neem',
  'Nellikkai legium',
  'Nettles',
  'Niacin',
  'Nilavemu Kudineer',
  'Nitric acidum',
  'Nutrition education',
  'nutritional counselling',
  'Nutritional ice cream',
  'Nutritional shake',
  'Nux Vomica',
  'Nv Zhen Zi',
  'Nvzhenzi',
  'Oldenlandia',
  'Olive oil',
  'Omega 6',
  'Omega 9',
  'Omnivore diet',
  'omnivorous diet',
  'Onion',
  'Ophiopogon',
  'Oregano',
  'Paeonia',
  'Panax quinquefolius',
  'Papain',
  'Patrinia',
  'Pectin',
  'Persumac',
  'Petroleum',
  'Pfaffia paniculata',
  'PG2',
  'pharmacopuncture',
  'phenolic acids',
  'Phenylalanine',
  'Phosphorous',
  'PHY906',
  'Physical activity',
  'Phytoestrogen',
  'Phytolacca',
  'Pinelliae',
  'Pistacia terebinthus',
  'plant-based diet',
  'Plantago major',
  'Polaprezinc',
  'polaprezinc',
  'Policosanol',
  'Pollen',
  'Polyunsaturated Fatty acids',
  'Pomegranate',
  'Pongamia pinnata',
  'Poriae',
  'Probiotic',
  'propolis',
  'Prostate health cocktail',
  'Protein',
  'Prunella',
  'Pseudobulbus cremastrae',
  'PSK',
  'Psorinum',
  'Psyllium',
  'Pulsatilla',
  'Pyridoxine',
  'Qianlie xiaozheng tang',
  'Qigong',
  'Quercetin',
  'Radix Acanthopanacis Senticosi',
  'radix astragali',
  'Radix Codonopsis',
  'Radix Pseudostellariae',
  'Rasagandi mezhugu',
  'Red clover',
  'red yeast rice',
  'Red yeast rice extract',
  'Reishi',
  'Ren Shen Yangrong Tang',
  'Restricted iodine diet',
  'Resveratrol',
  'Retinoids',
  'Rhamnus purshiana',
  'Rhizoma Atractylodis Macrocephalae',
  'Rhodiola algida',
  'Rhodiola rosea',
  'Rhus Toxicondron',
  'Rhus verniciflua',
  'Riboflavin',
  'Ribonucleotides',
  'Rice',
  'Rikkunshi-to',
  'Rosemary',
  'Royal jelly',
  'Ruanjian Xiaoliu',
  'Ruscus aculeatus',
  'Ruta graveolens',
  'Saffron',
  'Sage',
  'Saireitou',
  'Salix alba',
  'Salvia miltiorrhiza',
  'Sandalwood',
  'Sanguinaria',
  'Sargassum',
  'Saw palmetto',
  'Scutellaria',
  'Selenium',
  'Senkottai nei',
  'senna',
  'Senpukukataishasekito',
  'Sepia',
  'Serine',
  'Sesame oil',
  'Shakuyakukanzoto',
  'Shan Ci Gu',
  'Shan-ci-gu',
  'Shanyao',
  'Shanyurou',
  'Shark cartilage',
  'Shea butter',
  'Shenghuang',
  'Shenmai',
  'Shenqi Fuzheng',
  'Shi Pi',
  'Shi-jian-chuan',
  'Shiitake',
  'Shouwuteng',
  'Shuangbai San',
  'Shuanghuang shengbai',
  'Silica',
  'Silymarin',
  'simo',
  'Sodium hyaluronate',
  'Soramdan',
  'Soy',
  'Soybean Oil',
  'stilbenes',
  'Streptococcus',
  'Stress reduction',
  'Sulphur',
  'Support group',
  'Suvarnabhasmadi',
  'Sweet potato',
  'Symphytum',
  'Tabacum',
  'Tamanu',
  'Tao Hong Si Wu',
  'Taurine',
  'tea',
  'Theanine',
  'Thiamine',
  'Thuja',
  'Thyme',
  'Tianwan Buxin',
  'Tibetan Herbal Formula',
  'Tomato',
  'Total Parenteral Nutrition',
  'touch therapy',
  'Traditional Chinese Medicine',
  'Traditional Japanese Medicine',
  'Traditional Thai Medicine',
  'Traumeel',
  'Traumeel-S',
  'Trichosanthes kirilowii',
  'Tripalachooranam',
  'Tryptophan',
  'Tufting',
  'Turkeytail Mushroom',
  'Tyrosine',
  'Ukrain',
  'Uncaria tomentosa',
  'Undaria',
  'Varunadi Ghritha',
  'Vegetables',
  'Vegetarian Diet',
  'Visualization',
  'Vitamin A',
  'Vitamin B',
  'Vitamin D2',
  'Vitamin D3',
  'Vitamin E',
  'vitamins',
  'Vitex',
  'Vitis vinifera',
  'Wheat grass juice',
  'Wheel balance cancer therapy',
  'Whey',
  'wild yam',
  'Withania somnifera',
  'Woohwanggeosa-dan',
  'Wuweizi',
  'X-ray',
  'Xiang Sha Liu Jun Zi Tang',
  'Xianhe Baijiang',
  'Xianmao',
  'Xiao Chaihu Tang',
  'Xiao-Ai-Tong',
  'Xiao-Chaihu',
  'Xihuang Jiedu',
  'Xylitol',
  'Yang Fei',
  'yi qi yang xue sheng sui',
  'Yinchen Wuling San',
  'Yoga',
  'Yuanshen',
  'Yujin',
  'Yunnan Baiyao',
  'Yunxiangjing',
  'Yunzhi',
  'Zanthoxylum',
  'Zexie',
  'Zhenzhumu',
  'Zhi Gan Cao Tang',
  'Zikang',
  'Zinc',
  'Zincum',
  'Zingiberis Rhizoma',
  'Zizyphi fructus',
  'Zyflammend'
];

export default naturalTherapyAgents;
