import { request } from './request';

export const searchSummariesWithFilter = filterOptions => 
  request('/summaries/search', 'POST', filterOptions)
    .then(res => ({
      res,
      promiseId: 'summarized'
    }));

export const searchSummariesWithFilterBeta = filterOptions => 
  request('/summaries/search-beta', 'POST', filterOptions)
    .then(res => ({
      res,
      promiseId: 'summarized'
    }));

export const searchSupportLitWithFilter = filterOptions =>
  request('/support-lit/search', 'POST', filterOptions)
    .then(res => ({
      res,
      promiseId: 'support'
    }));

export const searchSupportLitWithFilterBeta = filterOptions => 
  request('/support-lit/search-beta', 'POST', filterOptions)
    .then(res => ({
      res,
      promiseId: 'support'
    }));

export const getSingleSummarizedStudy = uuid => request(`/summaries/uuid/${uuid}`, 'GET');
export const getSingleSupportLit = id => request(`/support-lit/${id}`, 'GET');

export const getAllSummaries = () => request('/summaries', 'GET')
  .then(res => ({
    res,
    promiseId: 'summarized'
  }));

export const getAllSupportLit = () => request('/support-lit', 'GET')
  .then(res => ({
    res,
    promiseId: 'support'
  }));

export const getNotableStudies = () => request('/summaries/notable', 'GET');

export const getNotableTitles = () => request('/airtable/notable-titles', 'GET');

export const updateSummary = summaryInfo => request('/summaries/pg-and-airtable', 'PATCH', summaryInfo);

export const toggleIsNotable = (airtableId, _id, isNotableVal) => {
  return request('/summaries/toggle-notable', 'PATCH', { airtableId, _id, isNotableVal });
};
