// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._2I6ibWyz4sev6Z8UaxaOCN {\n  position: fixed;\n  z-index: 2;\n  width: 50vw;\n  height: -moz-fit-content;\n  height: fit-content;\n  top: 25vh;\n  left: 25vw;\n  background-color: white;\n  border: 5px solid black;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 3em;\n}\n\n  ._2I6ibWyz4sev6Z8UaxaOCN h3 {\n    font-size: 20px;\n    font-weight: bold;\n    margin: .5em;\n  }\n\n.RIXlg2e-x_PaVcFMwdVC0 {\n  display: none;\n}\n", "",{"version":3,"sources":["confirmModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU;EACV,WAAW;EACX,wBAAmB;EAAnB,mBAAmB;EACnB,SAAS;EACT,UAAU;EACV,uBAAuB;EACvB,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AAOd;;EALE;IACE,eAAe;IACf,iBAAiB;IACjB,YAAY;EACd;;AAGF;EACE,aAAa;AACf","file":"confirmModal.css","sourcesContent":[".openModal {\n  position: fixed;\n  z-index: 2;\n  width: 50vw;\n  height: fit-content;\n  top: 25vh;\n  left: 25vw;\n  background-color: white;\n  border: 5px solid black;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 3em;\n\n  h3 {\n    font-size: 20px;\n    font-weight: bold;\n    margin: .5em;\n  }\n}\n\n.closedModal {\n  display: none;\n}\n"]}]);
// Exports
exports.locals = {
	"openModal": "_2I6ibWyz4sev6Z8UaxaOCN",
	"closedModal": "RIXlg2e-x_PaVcFMwdVC0"
};
module.exports = exports;
