import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import ModalGroup from '../modalGroup/ModalGroup';
import styles from './providerSearch.css';
import { fetchFocuses, fetchProfessions } from '../../services/providers';
import { provinceList, stateList } from '../../utils/addressData';
import { useSelector } from 'react-redux';
import { getProviderQuery, getQueried } from '../../data/selectors/providerSelectors';
import { resetProviderQuery, setProviderQuery } from '../../data/actions/providerActions';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { IoIosInformationCircleOutline } from 'react-icons/io';

ProviderSearch.propTypes = {
  submitCb: PropTypes.func,
  clearCb: PropTypes.func
};

export default function ProviderSearch({ submitCb, clearCb }) {
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const [rawFocuses, setRawFocuses] = useState([]);
  const [rawProfessions, setRawProfessions] = useState([]);
  // const [serviceAreas, setServiceAreas] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    focuses: [],
    professions: [],
    serviceAreas: []
  });
  const [onlyPhysical, setOnlyPhysical] = useState(false);
  const [onlyVirtual, setOnlyVirtual] = useState(false);
  const [remountKey, setRemountKey] = useState(0);
  const initialRender = useRef(true);
  const storedQuery = useSelector(getProviderQuery);
  const queried = useSelector(getQueried);
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([fetchProfessions(), fetchFocuses()])
      .then(([professionData, focusesData]) => {
        setRawProfessions(professionData.sort());
        setRawFocuses(focusesData.sort());
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);
  
  useEffect(() => {
    if(!initialRender.current) {
      const query = constructQuery();
      submitCb(query);
    } else {
      if(queried) submitCb(storedQuery);
      initialRender.current = false;
    }
  }, [filterOptions]);

  if(loading) {
    return <LoadingSpinner />;
  }
  
  const handleClear = () => {
    setRemountKey(remountKey + 1);
    dispatch(resetProviderQuery());
    initialRender.current = true;
    setSearchText('');
    setFilterOptions({
      focuses: [],
      professions: [],
      serviceAreas: []
    });
    clearCb();
  };

  const excludedAlls = ['All Professions', 'All Areas of Focus', 'All States & Provinces'];
  const filterOutAllOption = (optionsArray) => {
    if(!optionsArray) return [];
    return optionsArray.slice().filter(option => !excludedAlls.includes(option));
  };
  const constructQuery = () => {
    const query = {
      searchText,
      filters: {
        focuses: filterOutAllOption(filterOptions['Focuses']),
        professions: filterOutAllOption(filterOptions['Professions']),
        serviceAreas: filterOutAllOption(filterOptions['Areas of Service']),
        onlyPhysical,
        onlyVirtual
      },
      perPage: 20,
      pageNum: 1
    };
    dispatch(setProviderQuery(query));
    return query;
  };

  const handleSearchText = ({ target }) => setSearchText(target.value);

  const handleFilterChange = newOptions => setFilterOptions(newOptions);

  const handlePhysicalChange = () => {
    setOnlyPhysical(!onlyPhysical);
    setOnlyVirtual(false);
  };

  const handleVirtualChange = () => {
    setOnlyVirtual(!onlyVirtual);
    setOnlyPhysical(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const query = constructQuery();
    submitCb(query);
  };

  return (
    <div className={styles.searchContainer}>
      <h2>Find A Provider</h2>
      <form 
        // className={styles.form}
        onSubmit={handleSubmit}
      >
        <div className={styles.textInputContainer}>
          <input 
            type='text' 
            className={styles.textInput} 
            value={searchText} 
            onChange={handleSearchText} 
            autoFocus={true}/>
          <Tooltip
            id="search-info-tooltip"
            place="bottom"
            content="Search by name, location, phone number, title, etc"
          >
            <IoIosInformationCircleOutline data-tooltip-id="search-info-tooltip" />
          </Tooltip>
        </div>
      </form>
      <ModalGroup
        key={remountKey}
        styles={styles}
        modalGroupArray={[
          ['Professions', 'All Professions', ...rawProfessions],
          ['Focuses', 'All Areas of Focus', ...rawFocuses],
          ['Areas of Service', 'All States & Provinces', ...stateList, ...provinceList]
        ]}
        setCallback={handleFilterChange}
        includeAllOption={true}
      />
      <div>
        <input 
          type='submit'
          className={styles.searchButton}
          value='Search'
          onClick={handleSubmit}
        />
        <input
          type='button'
          className={styles.searchButton}
          value='Clear'
          onClick={handleClear}
        />
      </div>
    </div>
  );
}
