import { login, logout, autoLogin, fetchUser } from '../../services/auth';
import { noInviteSignup, inviteFreeTrialUser } from '../../services/signup';

export const SET_USER_LOADING = 'SET_USER_LOADING';
export const setUserLoading = () => ({
  type: SET_USER_LOADING
});

export const SET_USER = 'SET_USER';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';

export const setUser = (email, password) => dispatch => {
  dispatch(setUserLoading());
  return login(email, password)
    .then(user => {
      dispatch({ type: SET_USER, payload: user });
    })
    .catch(err => {
      if (err) {
        dispatch({ type: SET_AUTH_ERROR, payload: err });
      }
    });
};

export const autoSetUser = (referrer, uid) => dispatch => {
  dispatch(setUserLoading());
  return autoLogin(referrer, uid)
    .then(user => {
      dispatch({ type: SET_USER, payload: user });
      return user;
    })
    .catch(err => {
      if (err) {
        dispatch({ type: SET_AUTH_ERROR, payload: err });
      }
    });
};

export const autoSignupAndLogin = (userInfo, uid, referrer) => dispatch => {
  dispatch(setUserLoading());
  return noInviteSignup(userInfo, uid, referrer)
    .then(user => {
      dispatch({ type: SET_USER, payload: user });
      return user;
    })
    .catch(err => {
      if (err) {
        dispatch({ type: SET_AUTH_ERROR, payload: err });
      }
    });
};

export const loadUser = () => dispatch => {
  dispatch(setUserLoading());
  return fetchUser()
    .then(user => {
      dispatch({ type: SET_USER, payload: user });
    })
    .catch(() => {
      dispatch({ type: SET_USER, payload: {} });
    });
};

export const LOGOUT_USER = 'LOGOUT_USER';
export const logoutUser = () => dispatch => {
  dispatch(setUserLoading());
  return logout()
    .then(() => {
      dispatch({ type: LOGOUT_USER });
    });
};

export const SET_COOKIE_PREF = 'SET_COOKIE_PREF';
export const setCookiePref = pref => ({
  type: SET_COOKIE_PREF,
  payload: pref
});

export const SET_PROVIDER_EDITING = 'SET_PROVIDER_EDITING';
export const setProviderEditing = isEditing => ({
  type: SET_PROVIDER_EDITING,
  payload: isEditing
});

export const SET_PROVIDER = 'SET_PROVIDER';
export const setProvider = provider => ({
  type: SET_PROVIDER,
  payload: provider
});

export const INVITE_FREE_TRIAL_USER_SUCCESS = 'INVITE_FREE_TRIAL_USER_SUCCESS';
export const INVITE_FREE_TRIAL_USER_ERROR = 'INVITE_FREE_TRIAL_USER_ERROR';

export const inviteFreeTrial = emailAddress => async dispatch => {
  try {
    const response = await inviteFreeTrialUser(emailAddress);
    dispatch({ type: INVITE_FREE_TRIAL_USER_SUCCESS, payload: response });
    return response;  // Ensure the response is returned for further processing
  } catch (err) {
    dispatch({ type: INVITE_FREE_TRIAL_USER_ERROR, payload: err });
    throw err;  // Ensure the error is thrown to be caught in the calling function
  }
};

