import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './orgUsersAccessExpiration.css';
import { patchAllRegularUsers } from '../../../services/orgAdmin';

const propTypes = {
  open: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  defaultDate: PropTypes.string.isRequired
};

export default function OrgUsersAccessExpiration({ open, closeCallback, orgId, defaultDate }) {
  if(!open) return null;
  const date = new Date(defaultDate);
  const defaultFormattedDate = date.toISOString().substr(0, 10);

  const [newAccessExpiration, setNewAccessExpiration] = useState(defaultFormattedDate);

  const handleNewAccessExpirtion = ({ target }) => setNewAccessExpiration(target.value);
  const handleCancel = () => closeCallback(null);
  const handleSubmit = () => {
    patchAllRegularUsers({ access_expiration: newAccessExpiration }, orgId)
      .then(updatedUsers => closeCallback(updatedUsers))
      .catch(err => console.log(err));
  };

  return (
    <div className={styles.backgroundDiv}>
      <div className={styles.accessModal}>
        <p>All Users Access Expiration</p>
        <p>This will change the access expiration of all users of this organization at once.</p>
        <label htmlFor='newAccessExpiration'>New Access Expiration</label>
        <input id='newAccessExpiration' type='date' value={newAccessExpiration} onChange={handleNewAccessExpirtion} />
        <button type='button' onClick={handleCancel}>Cancel Change</button>
        <button type='button' onClick={handleSubmit}>Save Change</button>
      </div>
    </div>
  );
}

OrgUsersAccessExpiration.propTypes = propTypes;
