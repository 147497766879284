export const clinicalTrialFields = [
  'briefSummary',
  'designFeatures',
  'populationDescribed',
  'participants',
  'cancerType',
  'staging',
  'naturalTherapyDescribed',
  'naturalTherapyName',
  'naturalTherapyComponents',
  'comparator',
  'resultsDescribed',
  'sideEffects',
  'sideEffectsAssociated',
  'sideEffectsDescribed',
  'qraSequence',
  'qraAllocation',
  'qraBlinding',
  'qraBlindingOutcome',
  'qraIncomplete',
  'qraSelective',
  'qraOther',
  'qraJustification',
  'reference',
  'link'
];

export const microtagFields = [
  'abstract',
  'briefSummary',
  'naturalTherapyName',
  'conventionalTreatment',
  'conventionalTreatmentAgents',
  'sideEffects',
  'reference',
  'link'
];

export const observationalFields = [
  'briefSummary',
  'designFeatures',
  'populationDescribed',
  'participants',
  'cancerType',
  'staging',
  'naturalTherapyDescribed',
  'naturalTherapyName',
  'naturalTherapyComponents',
  'comparator',
  'outcomeResults',
  'resultsDescribed',
  'sideEffects',
  'sideEffectsAssociated',
  'sideEffectsDescribed',
  'summarizer',
  'reference',
  'link'
];

export const sysReviewFields = [
  'briefSummary',
  'designFeatures',
  'cancerType',
  'humanStudies',
  'nonhumanStudies',
  'inclusionCriteria',
  'searchDates',
  'populationDescribed',
  'participants',
  'staging',
  'naturalTherapyDescribed',
  'naturalTherapyName',
  'naturalTherapyComponents',
  'sideEffects',
  'sideEffectsAssociated',
  'sideEffectsDescribed',
  'reference',
  'link'
];

export const caseReportFields = observationalFields;

export const unknownFields = [
  'abstract',
  'year',
  'reference',
  'link'
];

export const supportLitFields = [
  'authorName',
  'source',
  'filesLink'
];

export const ignoreEntries = [null, 'null', '', '\n', 'n/a', 'N/A', 'N/a', ','];
