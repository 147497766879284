import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCookiePref } from '../data/selectors/userAuthSelectors';

import styles from './app.css';

import pageTracker from './analytics/pageTracker';
import ReactGA from 'react-ga';

import AddPearl from './addPearl/AddPearl';
import Attributions from './attributions/Attributions';
import AutoAuth from './autoAuth/AutoAuth';
// import ClinicalPearls from './clinicalPearls/ClinicalPearls';
import ContinuingEducation from './continuingEducation/ContinuingEducation';
import CookieConsent from './cookieConsent/CookieConsent';
import Donate from './donate/Donate';
import EditProvider from './providerNetwork/EditProvider';
import Feedback from './feedback/Feedback';
import Footer from './footer/Footer';
import ForgotPassword from './forgotPassword/ForgotPassword';
import Header from './header/Header';
import Home from './home/Home';
import IowgSurvey from './iowgSurvey/IowgSurvey';
import IpLoginNotice from './ipLoginNotice/IpLoginNotice';
import JoinNow from './joinNow/JoinNow';
import KnowAdmin from './knowAdmin/KnowAdmin';
import License from './license/License';
import Login from './login/Login';
import Methodology from './methodology/Methodology';
import NoMatchPage from './404/NoMatchPage';
import OrgAdmin from './orgAdmin/OrgAdmin';
import PasswordReset from './passwordReset/PasswordReset';
import PatientResources from './patientResources/PatientResources';
import PrivacyPolicy from './privacyPolicy/PrivacyPolicy';
import ProviderNetwork from './providerNetwork/ProviderNetwork';
import ProviderResultDetail from './providerNetwork/ProviderResultDetail';
import RenewSub from './renewSub/RenewSub';
import Signup from './signup/Signup';
import SingleResult from './singleResult/SingleResult';
import Studies from './studies/Studies';
import TermsOfUse from './termsOfUse/TermsOfUse';

// const EventTargetPrototype = document.__proto__.__proto__.__proto__.__proto__;
// const origAddEventListener = EventTargetPrototype.addEventListener;
// EventTargetPrototype.addEventListener = function addEventListenerWrapper(type, listener) {
//     if (typeof listener !== 'function') throw new Error('bad listener for ' + type);
//     return origAddEventListener.apply(this, arguments);
// };

export default function App() {
  const cookiePref = useSelector(getCookiePref);
  if(cookiePref) {
    ReactGA.initialize('UA-178653011-1', {
      // debug: true,
      titleCase: false,
      gaOptions: {
        siteSpeedSampleRate: 100
      }
    });
  }

  return (
    <div className={styles.pageContainer}>
      <Router>
        <Header />
        <div className={styles.contentWrap}>
          <Routes>
            <Route exact path='/' element={React.createElement(pageTracker(Home))} />
            <Route exact path='/add-pearl' element={React.createElement(pageTracker(AddPearl))} />
            <Route exact path='/attributions' element={React.createElement(pageTracker(Attributions))} />
            <Route exact path='/autoauth' element={React.createElement(pageTracker(AutoAuth))} />
            <Route exact path='/continuing-education' element={React.createElement(pageTracker(ContinuingEducation))} />
            <Route exact path='/donate' element={React.createElement(pageTracker(Donate))} />
            <Route exact path='/edit-provider' element={React.createElement(pageTracker(EditProvider))} />
            <Route exact path='/feedback' element={React.createElement(pageTracker(Feedback))} />
            <Route exact path='/forgot-password' element={React.createElement(pageTracker(ForgotPassword))} />
            <Route exact path='/iowg-survey' element={React.createElement(pageTracker(IowgSurvey))} />
            <Route exact path='/join-now' element={React.createElement(pageTracker(JoinNow))} />
            <Route exact path='/know-admin' element={React.createElement(pageTracker(KnowAdmin))} />
            <Route exact path='/license' element={React.createElement(pageTracker(License))} />
            <Route exact path='/login' element={React.createElement(pageTracker(Login))} />
            <Route exact path='/methodology' element={React.createElement(pageTracker(Methodology))} />
            <Route exact path='/org-admin' element={React.createElement(pageTracker(OrgAdmin))} />
            <Route exact path='/patient-resources' element={React.createElement(pageTracker(PatientResources))} />
            {/* <Route exact path='/pearls' element={React.createElement(pageTracker(ClinicalPearls))} /> */}
            <Route exact path='/privacy-policy' element={React.createElement(pageTracker(PrivacyPolicy))} />bEegp43:+
            <Route exact path='/provider/:slug' element={React.createElement(pageTracker(ProviderResultDetail))} />
            <Route exact path='/providers' element={React.createElement(pageTracker(ProviderNetwork))} />
            <Route exact path='/renew-subscription' element={React.createElement(pageTracker(RenewSub))} />
            <Route exact path='/reset-password' element={React.createElement(pageTracker(PasswordReset))} />
            <Route exact path='/shared' element={React.createElement(pageTracker(SingleResult))} />
            <Route exact path='/signup' element={React.createElement(pageTracker(Signup))} />
            <Route exact path='/studies' element={React.createElement(pageTracker(Studies))} />
            <Route exact path='/terms-of-use' element={React.createElement(pageTracker(TermsOfUse))} />
            <Route element={React.createElement(pageTracker(NoMatchPage))} />
          </Routes>
        </div>
        <IpLoginNotice />
        <CookieConsent />
        <Footer />
      </Router>
    </div>
  );
}
