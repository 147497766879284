import { request } from './request';

export const getReferrerCodes = () => {
  return request('/referrers/referrer-codes', 'GET');
};

export const createReferrerCode = (referrer, organization) => {
  return request('/referrers', 'POST', { referrer, organization });
};

export const deleteReferrerCode = id => {
  return request(`/referrers/${id}`, 'DELETE');
};
