import React, { useEffect } from 'react';
import styles from './continuingEducation.css';
import { useSelector } from 'react-redux';
import { getUser, getUserLoading } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';

export default function ContinuingEducation() {
  const navigate = useNavigate();
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);

  useEffect(() => {
    if(!user.email) navigate('/login#continuing-education');
  }, [user.email, navigate]);

  const { organizations, role } = user;
  const { ceContainer } = styles;

  useEffect(() => {
    document.title = 'KNOW - Continuing Education';
  }, []);


  if(userLoading) {
    return <LoadingSpinner />;
  }

  if(organizations !== 1 || role == 'allied') {
    return (
      <div className={ceContainer}>
        <h1>You must be logged in as an OncANP member to view CE links.</h1>
      </div>
    );
  }

  return (
    <div className={ceContainer} role="main" aria-label="continuing education">
      <h1>Continuing Education</h1>

      <p>&nbsp;</p>
      <p>Natural Support for Surgery, Radiation, Chemo & Hormone Therapies
        <br /><a href='https://www.mytestcom.net/app/myTestcomURL.cfm?accountID=c1f6p772e98qw32&assignContentCode=z8110188'>
                       https://www.mytestcom.net/app/myTestcomURL.cfm?accountID=c1f6p772e98qw32&assignContentCode=z8110188</a></p>

      <p>&nbsp;</p>
      <p>TCM Support
        <br /><a href='https://www.mytestcom.net/app/myTestcomURL.cfm?accountID=c1f6p772e98qw32&assignContentCode=z4110314</p>'>
                       https://www.mytestcom.net/app/myTestcomURL.cfm?accountID=c1f6p772e98qw32&assignContentCode=z4110314</a></p>

      <p>&nbsp;</p>
      <p>Getting to KNOW Side Effect Management of Small Molecule Inhibitors (2 hours pharm CE) 
        <br /><a href='https://forms.gle/ao6k8hkADSYqJ9NG9'>
                       https://forms.gle/ao6k8hkADSYqJ9NG9</a></p>

      <p>&nbsp;</p>
      <p>Up-to-Date Research on Chemotherapy-Herb Interactions (2 hours pharm CE)
        <br /><a href='https://forms.gle/rHGq47UEYMUpcBYv6'>
                       https://forms.gle/rHGq47UEYMUpcBYv6</a></p>
      <p>&nbsp;</p>
      <p>Up-to-Date Research on Xeloda (2 hours pharm CE)
        <br /><a href='https://forms.gle/UUBpyUbBdRQvL1FS9'>
                       https://forms.gle/UUBpyUbBdRQvL1FS9</a></p>
    </div>
  );
}
