import naturalTherapyAgents from './naturalTherapyAgents';
import conventionalTreatmentAgents from './conventionalTreatmentAgents';

export const sideEffects = [
  'Side Effects',
  'All Side Effects',
  'Alopecia',
  'Anorexia',
  'Anxiety',
  'Arthralgia',
  'Ascites',
  'Bone marrow suppression',
  'Bruising',
  'Cachexia/Sarcopenia',
  'Cancer pain',
  'Cardiotoxicity',
  'Cerebral edema',
  'Cognitive changes',
  'Constipation',
  'Cystitis',
  'Depression',
  'Dermatitis',
  'Diarrhea',
  'Dysgeusia (taste changes)',
  'Esophagitis',
  'Fatigue',
  'Fertility',
  'Gastroesophageal reflux disease',
  'Hand Foot Syndrome',
  'Hematuria',
  'Hepatotoxicity',
  'Hot flashes',
  'Ileus',
  'Infectious Complications',
  'Insomnia',
  'Joint pain',
  'Lymphedema',
  'Mucositis',
  'Myalgia',
  'Nausea & Vomiting',
  'Nephrotoxicity',
  'Nocturia',
  'Obesity',
  'Osteoporosis',
  'Ototoxicity',
  'Pericardial effusion',
  'Peripheral neuropathy/Neurotoxicity',
  'Peritoneal effusion',
  'Pleural effusion',
  'Pneumonitis',
  'Radiation-induced fibrosis',
  'Radiation-induced proctitis',
  'Scar tissue',
  'Sexual function',
  'Swelling',
  'Vaginal dryness',
  'Wounds',
  'Xerostomia (dry mouth)',
  'OTHER'
];

// create an array of years descending from current year inclusive of 1969
export const yearPublished = (() => {
  const yearPublishedArray = ['Year Published', 'All Years'];
  const currentYear = new Date().getFullYear();
  for(let year = currentYear; year >= 1969; year--) {
    yearPublishedArray.push(year.toString());
  }
  
  return yearPublishedArray;
})();

export const studyTypes = [
  'Study Types',
  'All Study Types',
  'Systematic review and/or Meta-analysis',
  'Clinical Trial',
  'Case Report/Series',
  'Observational'
];

export const studyDesignFeatures = [
  'Study Design Features',
  'All Design Features',
  'Blinded',
  'Controlled Clinical Trial',
  'Cross-Over Trial',
  'Double-Blinded',
  'Multi-center',
  'Placebo-Controlled',
  'Randomized',
  'Retrospective',
  'Uncontrolled Clinical Trial'
];

export const populationSizes = [
  'Population Sizes (N)',
  'All Population Sizes (N)',
  'Small (0-49)',
  'Medium (50-99)',
  'Large (100-499)',
  'Extra Large (≥500)'
];

export const adverseEvents = [
  'Adverse Events',
  'All Adverse Events',
  'Present',
  'Absent',
  'None Reported'
];

// Note that 'pediatric' is omitted from this list because it has a separate flag generated for it
export const tumorType = [
  'Cancer Type',
  'All Cancer Types',
  'Bladder',
  'Bone',
  'Brain & CNS',
  'Breast',
  'Cancer Unknown Primary',
  'Carcinoid Tumor',
  'Cervical',
  'Colorectal',
  'Endocrine',
  'Gastric & Esophageal',
  'Head & Neck',
  'Kidney',
  'Leukemia & Lymphoma',
  'Liver & Gallbladder',
  'Lung',
  'Melanoma & Skin',
  'Merkel Cell',
  'Mesothelioma',
  'Multiple Myeloma',
  'Myelodysplastic Syndromes',
  'Neuroblastoma',
  'Ovarian & Fallopian Tube',
  'Pancreatic',
  'Penile',
  'Peritoneal',
  'Prostate',
  'Retinoblastoma',
  'Sarcoma',
  'Small Intestine',
  'Testicular',
  'Thyroid',
  'Ureter & Renal Pelvis',
  'Urethral',
  'Uterine',
  'Vulvar',
  'Precancerous Condition',
  'Precancerous Condition and Cancer'
];

export const stage = [
  'Stage',
  'All Stages',
  '0',
  'I',
  'II',
  'III',
  'IV',
  'Not Reported'
];

export const outcomeCategories = [
  'Outcome Categories',
  'All Outcome Categories',
  'QOL',
  'Recurrence',
  'Safety',
  'Surrogate',
  'Survival',
  'Symptoms'
];

export const outcomeResults = [
  'Outcome Results',
  'All Outcome Results',
  'Positive',
  'Negative',
  'Neutral'
];

export const interactions = [
  'Interactions',
  'All Interactions',
  'Absent',
  'Not Reported',
  'Present - Beneficial',
  'Present - Harmful',
  'Reported and Possible'
];

export const naturalTherapyTypes = [
  'Natural Therapy Types',
  'All Natural Therapy Types',
  'Acupuncture',
  'Amino Acid',
  'Dietary',
  'Enzyme',
  'Essential Fatty Acid',
  'Exercise',
  'Herb/Plant Compound',
  'Homeopathy',
  'Hormone',
  'Injectable/Intravenous',
  'Integrative Care',
  'Mineral',
  'Mental/Emotional',
  'Mushroom',
  'Probiotics',
  'Supportive Care',
  'Topical',
  'Vitamin',
  'Other'
];

export const conventionalTreatmentTypes = [
  // Not Applicable is also a possible value, but should not be displayed
  'Conventional Treatment Types',
  'All Conventional Treatment Types',
  'Bone Marrow/Stem Cell Transplant',
  'Chemotherapy/Cytotoxic',
  'Hormone Therapy',
  'Patient Opts To Forgo Conventional Treatment/Active Surveillance',
  'Radiation',
  'Radionuclide/Radioligand',
  'Surgery',
  'Targeted Therapy',
  'Transarterial Chemoembolization'
];


export const allFilters = [
  yearPublished,
  studyTypes,
  populationSizes,
  tumorType,
  naturalTherapyTypes,
  naturalTherapyAgents,
  conventionalTreatmentTypes,
  conventionalTreatmentAgents,
  outcomeCategories,
  outcomeResults,
  sideEffects,
  studyDesignFeatures,
  adverseEvents,
  stage,
  interactions
];

//the first two values in the following arrays are filler values. As these are not actual filters, the array will never have the first two values read. It is purely to match the format of the above filters.
export const qra = [
  'QRA',
  'All QRA',
  'Low Risk',
  'Unclear Risk',
  'High Risk'
];

export const sideEffectsAssociated = [
  'Side Effects Associated',
  'All Associated Side Effects',
  'None Reported',
  'Present',
  'Authors unsure if caused by natural therapy'
];

export const comparator = [
  'Comparator',
  'All Comparators',
  'No treatment',
  'Placebo',
  'Standard of care',
  'Arms with different dosages',
  'N/A'
];

export const isNotable = [
  'Is Notable',
  'filler value',
  'true',
  'false'
];

export const optionsDict = {
  studyType: studyTypes,
  populationSizes,
  tumorType,
  naturalTherapyCategory: naturalTherapyTypes,
  naturalTherapyName: naturalTherapyAgents,
  conventionalTreatment: conventionalTreatmentTypes,
  conventionalTreatmentAgents,
  outcomeCategory: outcomeCategories,
  outcomeResults,
  sideEffects,
  designFeatures: studyDesignFeatures,
  associatedAdverseEvents: adverseEvents,
  interactions,
  qraSequence: qra,
  qraAllocation: qra,
  qraBlinding: qra,
  qraBlindingOutcome: qra,
  qraIncomplete: qra,
  qraSelective: qra,
  qraOther: qra,
  qraJustification: qra,
  sideEffectsAssociated,
  comparator,
  staging: stage,
  isNotable
};
