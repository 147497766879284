import { combineReducers } from 'redux';
import { reducer as checkboxReducer } from './checkboxReducer';
import { userAuthReducer } from './userAuthReducer';
import { searchReducer } from './searchReducer';
import { stylesReducer } from './stylesReducer';
import { providerReducer } from './providerReducer';

export default combineReducers({
  checkboxReducer,
  userAuthReducer,
  searchReducer,
  stylesReducer,
  providerReducer,
});
