import React, { useState, useEffect } from 'react';
import { resetPassword } from '../../services/auth';
import styles from './passwordReset.css';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner.jsx';
import { useNavigate } from 'react-router-dom';

export default function PasswordReset() {
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'KNOW - Reset Password';
  }, []);

  const handlePasswordOne = ({ target }) => {
    setPasswordOne(target.value);
  };

  const handlePasswordTwo = ({ target }) => {
    setPasswordTwo(target.value);
  };

  useEffect(() => {
    if(successFlag) {
      const redirectTimeout = setTimeout(() => {
        return navigate('/login', { replace: true });
      }, 4000);
      return () => clearTimeout(redirectTimeout);
    }
  }, [successFlag]);

  useEffect(() => {
    if(passwordOne !== '' && passwordOne === passwordTwo){
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [passwordOne, passwordTwo]);

  const handleChangePassword = () => {
    setLoading(true);
    const token = new URL(document.location).searchParams.get('token');
    resetPassword(token, passwordOne)
      .then(() => {
        setMessage('Success! You will be redirected to the login page in a few seconds.');
        setSuccessFlag(true);
        setLoading(false);
      })
      .catch(err => {
        if(err.status === 401 || err.message === 'JWT must be provided.'){
          setMessage('Please go back to your email to follow the password reset link once more.');
        } else {
          setMessage(err.message);
        }
        setLoading(false);
      });
  };

  if(loading) return <LoadingSpinner />;

  return (
    <div className={styles.resetContainer}>
      <div className={styles.forgot}>
        <h1>Reset Your Password</h1>
        {message && <p className={styles.message} style={{ 'textAlign': 'center', 'padding-bottom': '1rem' }}>{message}</p>}
        {!successFlag && 
        <div style={{ 'display': 'flex', 'flex-direction': 'column' }}>
          <section>
            <ul> <strong>Your new password must:</strong>
              <li>Be different than your old password,</li>
              <li>Be at least 8 characters long,</li>
              <li>Contain at least:
                <ul>
                  <li>one upper case letter,</li>
                  <li>one lower case letter,</li>
                  <li>one number,</li>
                  <li>one of the following symbols: !@#$%^&*</li>
                </ul>
              </li>
            </ul>
          </section>
          <div>
            <p>Please enter your new password:</p>
            <input type='password' value={passwordOne} onChange={handlePasswordOne} />
          </div>
          <div>
            <p>Please confirm your new password:</p>
            <input type='password' value={passwordTwo} onChange={handlePasswordTwo} />
          </div>
          <button disabled={buttonDisable} onClick={handleChangePassword}>Change your password</button>
        </div>}
      </div>
    </div>
  );
}
