import React from 'react';
import PropTypes from 'prop-types';
import styles from './topNavButton.css';
import { IoIosHeart, IoIosMail, IoIosPeople, IoMdPerson, IoIosText, IoIosKey, IoIosStar } from 'react-icons/io';
import { NavLink } from 'react-router-dom';


export default function TopNavButton({ text, link, handleButtonClick }) {
  
  const iconKey = {
    'Donate': <IoIosHeart  />,
    'Feedback': <IoIosMail />,
    'Sign Up': <IoIosPeople />,
    'Log In': <IoMdPerson />,
    'Log Out': <IoMdPerson />,
    'Add Pearl': <IoIosText />,
    'Org Admin': <IoIosKey />,
    'Know Admin': <IoIosStar />
  };

  // if(text === 'Join Now') {
  //   return (
  //     <div className={styles.TopNavButton}>
  //       <a href={link} rel='noopener noreferrer' target='_blank'>
  //         {iconKey[text]}
  //         <span>{text}</span>
  //       </a>
  //     </div>
  //   );
  // }

  const correctTopNavUserExperience = () => {
    if(text === 'Log Out' && link === '/') {
      return (<div onClick={handleButtonClick} className={styles.TopNavButton}>
        <NavLink to={link}>
          {iconKey[text]}
          <span>{text}</span>
        </NavLink>
      </div>);
    }

    return (<div onClick={handleButtonClick} className={styles.TopNavButton}>
      <NavLink
        to={link}
        className={({ isActive, isPending }) =>
          isPending ? 'pending' : isActive ? styles.navLink : ''
        }>
        {iconKey[text]}
        <span>{text}</span>
      </NavLink>
    </div>);
  };

  return (
    <>
      { correctTopNavUserExperience() }
    </>
  );
}

TopNavButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func
};
