export const SET_FOOTER_INFO = 'SET_FOOTER_INFO';
export const setFooterInfo = info => ({
  type: SET_FOOTER_INFO,
  payload: info
});

export const SET_COOKIE_BANNER_INFO = 'SET_COOKIE_BANNER_INFO';
export const setCookieBannerInfo = info => ({
  type: SET_COOKIE_BANNER_INFO,
  payload: info
});

