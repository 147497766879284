// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".GScmvvZVa1oLZF2PqiiBp {\n  display: flex;\n    flex-direction: column;\n    text-align: left;\n    top: 50%;\n    left: 50%;\n    position: fixed;\n    overflow: auto;\n    z-index: 3;\n    margin: 5% auto;\n    padding: 1rem;\n    border: solid #dddddd 1px;\n    border-radius: 5px;\n    background-color: #f6f6f6;\n    min-width: 7em;\n    width: 50%;\n    height: 60%;\n    transform: translate(-50%, -50%);\n}\n\n.u3WOwl5uLCDLNIwqDF9S {\n  position: absolute;\n  z-index: 2;\n  width: 100vw; \n  height: 100vh;\n  background-color: #bbbaba6c;\n}", "",{"version":3,"sources":["editUser.css"],"names":[],"mappings":"AAAA;EACE,aAAa;IACX,sBAAsB;IACtB,gBAAgB;IAChB,QAAQ;IACR,SAAS;IACT,eAAe;IACf,cAAc;IACd,UAAU;IACV,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,UAAU;IACV,WAAW;IACX,gCAAgC;AACpC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,2BAA2B;AAC7B","file":"editUser.css","sourcesContent":[".editModal {\n  display: flex;\n    flex-direction: column;\n    text-align: left;\n    top: 50%;\n    left: 50%;\n    position: fixed;\n    overflow: auto;\n    z-index: 3;\n    margin: 5% auto;\n    padding: 1rem;\n    border: solid #dddddd 1px;\n    border-radius: 5px;\n    background-color: #f6f6f6;\n    min-width: 7em;\n    width: 50%;\n    height: 60%;\n    transform: translate(-50%, -50%);\n}\n\n.backgroundDiv {\n  position: absolute;\n  z-index: 2;\n  width: 100vw; \n  height: 100vh;\n  background-color: #bbbaba6c;\n}"]}]);
// Exports
exports.locals = {
	"editModal": "GScmvvZVa1oLZF2PqiiBp",
	"backgroundDiv": "u3WOwl5uLCDLNIwqDF9S"
};
module.exports = exports;
