import React from 'react';
import { assetUrl } from '../../../utils/assetsMap';

const InternalLinks = () => (
  <>
    <br />
    <a href={assetUrl('IVC_PDF')} target='_blank' rel="noopener noreferrer">
      <img src={assetUrl('IVC_THUMB')} alt='Intravenous vitamin C in cancer care patient handout' />
    </a>
    <a href={assetUrl('WELLNESS_DURING_TAMOXIFEN_TX_PDF')} target='_blank' rel="noopener noreferrer">
      <img src={assetUrl('WELLNESS_DURING_TAMOXIFEN_TX_THUMB')} alt='Wellness during tamoxifen treatment patient handout' />
    </a>
    <a href={assetUrl('PROTECTING_THE_HEART_PDF')} target='_blank' rel="noopener noreferrer">
      <img src={assetUrl('PROTECTING_THE_HEART_THUMB')} alt='Protecting your heart during chemotherapy patient handout' />
    </a>
    <a href={assetUrl('DATA_QUALITY_POSTER_PDF')} target='_blank' rel="noopener noreferrer">
      <img src={assetUrl('DATA_QUALITY_THUMB')} alt='KNOW data quality poster' />
    </a>
  </>
);

export default InternalLinks;
