import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import styles from './renewSub.css';
import { getUserById, updateUserExpiration  } from '../../services/auth';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { SUBSCRIPTION_PRICES } from '../../utils/prices';

export default function RenewSub() {
  const [userExpiration, setUserExpiration] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [showPaypalButtons, setShowPaypalButtons] = useState(true);

  useEffect(() => {
    document.title = 'KNOW - Subscription Renewal';
  }, []);
  
  const searchParams = new URL(window.location).searchParams;
  const userId = searchParams.get('id');

  useEffect(() => {
    // setLoading(true);
    getUserById(userId)
      .then(res => setUserExpiration(res.access_expiration))
      .catch(err => setMessage(err.message))
      .finally(() => setLoading(false));
  }, []);

  if(!userId) {
    return (
      <p>Please return to your email and follow the provided link to continue renewing your subscription.</p>
    );
  }

  if(loading) return <LoadingSpinner />;

  const expirationMessage = Date.parse(userExpiration) > Date.now() ? `Your access will expire on ${userExpiration}` : `Your access expired on ${userExpiration}`;

  const createOrder = (_data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: SUBSCRIPTION_PRICES.standard_cents
          },
          description: 'KNOWOncology Individual License Renewal'
        }
      ]
    });
  };

  const onApprove = (_data, actions) => {
    return actions.order.capture()
      .then(details => {
        switch(details.status) {
          case 'COMPLETED':
          case 'APPROVED':
            updateUserExpiration(details.id, userId, userExpiration)
              .then(res => {
                setShowPaypalButtons(false);
                setMessage(`Thank you for renewing your subscription to KNOW Oncology! You should receive a confirmation of your purchase from paypal to the email address associated with your account shortly.
                
                Your access to KNOWOncology has been extended for another year. We sincerely appreciate your support. Your new access expiration date is ${res.newAccessExpiration}`);
              })
              .catch(err => setMessage(`Your payment was successful, but there was an error while we were trying to update your license expiration date. We sincerely apologize. Please send us an email and we will get everything sorted out right away.
              
              Error: ${JSON.stringify(err)}`));
            break;
          default:
            break;
        }
      });
  };

  const onError = err => setMessage(`There was an error with your payment: ${JSON.stringify(err)}`);

  return (
    <div className={styles.renewContainer}>
      <p style={{ textAlign: 'center' }}>{message}</p>
      <h4 className='h4'>Subscription Renewal</h4>
      <p><strong>{expirationMessage}</strong></p>
      <p>To renew your subscription to KNOW Oncology, please proceed with making your payment via PayPal.</p>
      <p>
        <strong>
          After the PayPal window closes, there will be a brief delay before a successful order message appears.
        </strong>
      </p>
      {showPaypalButtons && 
        <div>
          <PayPalScriptProvider
            options={{
              // eslint-disable-next-line no-undef
              'client-id': `${process.env.PAYPAL_CLIENT_ID}`,
              'disable-funding': 'paylater'
            }}>
            <PayPalButtons shippingPreference='NO_SHIPPING' createOrder={createOrder} onApprove={onApprove} onError={onError} />
          </PayPalScriptProvider>
        </div>
      }
    </div>
  );
}
