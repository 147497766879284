import React from 'react';
import { assetUrl } from '../../utils/assetsMap';
import styles from './attributions.css';

export default function Attributions() {
  return (
    <div className={styles.pageContainer}>
      <h1>Who Makes KNOW Possible</h1>
      <div className={styles.backgroundImage}>
        <img src={assetUrl('ATTRIBUTIONS_TREE_LEFT_PNG')} />
        <img src={assetUrl('ATTRIBUTIONS_TREE_RIGHT_PNG')} />
      </div>
      <div className={styles.treeContainer}>
        <div className={styles.textContainerLeft}>
          <img className={styles.imageContainerLeft} src={assetUrl('ATTRIBUTIONS_TREE_LEFT_PNG')} />
          <h2>Sustaining Circle</h2>
          <section>
            Dr Winston Cardwell ND Lac,<br />
            Masters of Science of Oriental Medicine,<br />
            atlantaintegrativeandinternalmedicine.com<br />
            <br />
            Dr Dan Rubin ND FABNO,<br />
            Founding President of the OncANP,<br />
            Co-founder Naturopathic Specialists<br />
            listenandcare.com<br />
            <br />
            Dr Gurdev Parmar ND FABNO,<br />
            Editor-in-Chief of the Textbook of<br />
            Naturopathic Oncology:<br />
            A Desktop Guide of Integrative Cancer Care,<br />
            Co-founder Integrated Health Clinic<br />
            integratedhealthclinic.com<br />
            <br />
            Dr Virender Sodhi MD (Ayurveda), ND,<br />
            Fellowship in Integrative Oncology<br />
            CEO of Ayush Herbs<br />
            ayurvedicscience.com
          </section>
        </div>
        <div className={styles.textContainerRight}>
          <img className={styles.imageContainerRight} src={assetUrl('ATTRIBUTIONS_TREE_RIGHT_PNG')} />
          <h2>Founding Partners</h2>
          <section>
            Oncology Association of Naturopathic Physicians<br />
            OncANP.org<br />
            <br />
            The Centre for Health Innovation<br />
            thechi.ca<br />
            <br />
            Scheidel Foundation<br />
            scheidelfoundation.org<br />
          </section>
          <h2>Our Team</h2>
          <section>
            Research Directors:<br />
            Jen Green, ND, FABNO<br />
            Casey Martell, MBA, ND, FABNO<br />
            Sarah Soles, MSc, ND, FABNO<br />
            Heather Wright, ND, FABNO<br />
            <br />
            KNOW Research Associates:<br />
            Erica Eckstrand, ND<br />
            Carissa Fioritto, ND<br />
            Gillian Flower, ND, FABNO<br />
            Julie Robinson RN, ND<br />
            Kristina Schieferecke, ND<br />
            Anne Thiel, ND<br />
            Michael Walker, ND, FABNO<br />
            <br />
            KNOW Medical Librarian & Information Specialist:<br />
            Becky Skidmore BA(H), MLS<br />
            <br />
            Web Development & Design<br />
            Ian Andrewson<br />
            Casey Martell MBA, ND, FABNO<br />
          </section>
        </div>
      </div>
      <h1>Special Thanks To:</h1>
      <p className={styles.specialThanks}>
        Chad Aschtgen, ND, FABNO - Lise Alschuler ND, FABNO - Lynda Balneaves PhD, RN - Heather Boon BScPhm, PhD - Cynthia Bye ND, FABNO<br />
        Ellen Conte, ND, FABNO - Jason Harmon ND, FABNO - Erica Joseph, ND, LAc, FABNO - Eric Marsden ND - Corey Murphy, OncANP Executive Director<br />
        Eric Secor ND, PhD, LAc - Dugald Seely ND, MSc, FABNO - Institute for Natural Medicine - Corewell Health<br />
        The Natural Medicine Journal - OncANP Board of Directors - Canadian College of Naturopathic Medicine - University of Toronto - Cancer Choices
      </p>
    </div>
  );
}
