import React, { useEffect, useState } from 'react';
import { requestPasswordResetLink } from '../../services/auth';
import styles from './forgotPassword.css';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner.jsx';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [newPasswordMessage, setNewPasswordMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = 'KNOW - Forgot Password';
  }, []);

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const handleForgotPassword = () => {
    setLoading(true);
    requestPasswordResetLink(email)
      .then(() => {
        setNewPasswordMessage('Please check the email address you provided for the link to reset your password.');
        setLoading(false);
      })
      .catch(err => {
        setNewPasswordMessage(err.message);
        setLoading(false);
      });
  };

  if(loading) return <LoadingSpinner />;

  return (
    <div aria-label="Forgot Password" className={styles.forgot}>
      <div>
        <p>
          Please input your KNOW registered email address. A link will be sent that will allow you to change your password. 
        </p>
        <input type='text' value={email} onChange={handleEmailChange} placeholder='email' />
      </div>
      <button onClick={handleForgotPassword}>Send password reset link</button>
      {newPasswordMessage && <p className={styles.message}>{newPasswordMessage}</p>}
    </div>
  );
}
