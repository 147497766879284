import React, { useState } from 'react';
import ProviderResults from './ProviderResults';
import ProviderSearch from './ProviderSearch';
import styles from './providerNetwork.css';
import { searchProviders } from '../../services/providers';
import { useSelector } from 'react-redux';
import { getUser, getEmail, getProvider } from '../../data/selectors/userAuthSelectors';
import { ProviderBanner } from './ProviderBanner';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';

export default function ProviderNetwork() {
  const cookies = new Cookies();
  const [results, setResults] = useState({ 
    count: 0,
    pageNum: 1,
    pages: 0,
    providers: [] 
  });
  const [loading, setLoading] = useState(false);
  const user = useSelector(getUser);
  const email = useSelector(getEmail);
  const provider = useSelector(getProvider);
  
  let allowProvider = false;
  try {
    const sessionCookie = cookies.get('session');
    const cookiePayload = jwt_decode(sessionCookie);
    allowProvider = cookiePayload.allow_provider;
  // eslint-disable-next-line no-empty
  } catch(err) {}

  let banner = null;
  if(email && allowProvider) {
    if(provider) {
      banner = <ProviderBanner btnText={'See Your Provider Profile'} link={`/provider/${user.provider.slug}`} />;
    } else {
      banner = <ProviderBanner btnText={'Join Provider Network'} link={'/edit-provider'} />;
    }
  }

  const submitSearch = (input) => {
    setLoading(true);
    searchProviders(input)
      .then(res => {
        setResults(res);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };

  const clearSearch = () => {
    setResults({
      count: 0,
      pageNum: 1,
      pages: 0,
      providers: []
    });
  };

  const handlePagination = (newPageNum, query) => {
    searchProviders({
      ...query,
      pageNum: newPageNum
    })
      .then(res => {
        setResults(res);
      })
      .catch(err => console.log(err));
  };

  return (
    <div className={styles.providerContainer}>
      {banner}
      <ProviderSearch submitCb={submitSearch} clearCb={clearSearch}/>
      <ProviderResults 
        loading={loading} 
        results={results} 
        handlePagination={handlePagination}
      />
    </div>
  );
}
