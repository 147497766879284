import { dictionary, supportLitFilterDict } from '../../../utils/resultsSectionSetArray';
export const emptyQuery = {};

const searchQuery = {};
const memoizedSearchQuery = {};
export default function createQuery(filterOptions, searchText, onlyPediatric, betaSearch) {
  const stringifiedQueryArgs = JSON.stringify(filterOptions) + searchText + onlyPediatric + betaSearch;
  if(memoizedSearchQuery[stringifiedQueryArgs]) return JSON.parse(memoizedSearchQuery[stringifiedQueryArgs]);
  searchQuery.selectedOptionsArray = [];
  searchQuery.supportLitOptionsArray = [];

  Object.entries(filterOptions).forEach(filterOptionsArr => {
    const [filterType, filterOptions] = filterOptionsArr;

    filterOptions.forEach(filterOption => {
      if(filterOption.toString().slice(0, 3) !== 'All') {
        searchQuery.selectedOptionsArray.push(
          `${dictionary[filterType]}:${filterOption}`);
        searchQuery.supportLitOptionsArray.push(
          `${supportLitFilterDict[filterType]}:${filterOption}`
        );
      }
    });
  });
  searchQuery.searchTextInput = searchText;
  searchQuery.perPage = 20;
  searchQuery.page = 1;
  searchQuery.onlyPediatric = onlyPediatric;
  searchQuery.betaSearch = betaSearch;

  if(
    !searchQuery.searchTextInput &&
    !searchQuery.onlyPediatric &&
    !searchQuery.betaSearch &&
    !searchQuery.selectedOptionsArray.length &&
    !searchQuery.supportLitOptionsArray.length)
    return emptyQuery;
    
  memoizedSearchQuery[stringifiedQueryArgs] = JSON.stringify(searchQuery);
  return searchQuery;
}
