import React from 'react';
import styles from './providerResultSummary.css';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import VirtualServices from './VirutalServices';

ProviderResultSummary.propTypes = {
  providerResult: PropTypes.object
};

export default function ProviderResultSummary({ providerResult }) {

  const { firstName, lastName, designations, locations, headshot, professions, slug } = providerResult;

  const isVirtual = locations.filter(location => location.virtual === true).length > 0;

  const formattedProfessions = professions.map(prof => prof.name).join(', ');

  const nameText = designations ? `${firstName} ${lastName} ${designations}` : `${firstName} ${lastName}`;

  return (
    <div className={styles.cardContainer}>
      {headshot && <img src={headshot} />}
      <div className={styles.infoContainer}>
        <h3>{nameText}</h3>
        <span>
          {formattedProfessions}
        </span>
        {isVirtual && <VirtualServices />}
        <span className={styles.linkContainer}>
          <Link className={styles.link} to={`/provider/${slug}`}>
            See Details
          </Link>
        </span>
      </div>
    </div>
  );
}
