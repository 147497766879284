import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { getAllOrgs } from '../../../services/knowAdmin';
import LoadingSpinner from '../../loadingSpinner/LoadingSpinner';
import { createReferrerCode } from '../../../services/referrers';

const PropTypes = {
  closeCallback: propTypes.func.isRequired,
  registeredOrgs: propTypes.array.isRequired
};

export default function NewReferrerCode({ closeCallback, registeredOrgs }) {
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [newCode, setNewCode] = useState('');
  const [selectedOrg, setSelectedOrg] = useState(0);

  useEffect(() => {
    setLoading(true);
    getAllOrgs()
      .then(res => setOrgs(res))
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleCreateCode = () => {
    setLoading(true);
    createReferrerCode(newCode, selectedOrg)
      .then(() => closeCallback())
      .finally(setLoading(false));
  };

  const handleOrgOptionChange = ({ target }) => setSelectedOrg(target.value);

  const orgOptions = orgs.filter(org => !registeredOrgs.includes(org.org_name))
    .map(org => {
      return (
        <option key={org._id} value={org._id}>{org.org_name}</option>
      );
    });

  const handleNewCodeChange = ({ target }) => setNewCode(target.value);

  if(loading) return <LoadingSpinner />;

  return (
    <details style={{ margin: '1em', padding: '1em', width: 'max-content' }}>
      <summary>Create New Referrer Code</summary>
      <select onChange={handleOrgOptionChange} value={selectedOrg}>
        <option key={0} value={0}> -- select an org -- </option>
        {orgOptions}
      </select>
      <label>
        New referrer code:
        <input type='text' value={newCode} onChange={handleNewCodeChange}></input>
      </label>
      <button type='submit' onClick={handleCreateCode}>Create New Referrer Code</button>
    </details>
  );
}

NewReferrerCode.propTypes = PropTypes;
