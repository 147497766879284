import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './pagination.css';

Pagination.propTypes = {
  masterSearchQuery: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fullCount: PropTypes.number.isRequired,
};

export default function Pagination({ masterSearchQuery, fullCount, callback }) {
  const [currentPage, setCurrentPage] = useState(1);

  const downDisabled = currentPage === 1;
  const upDisabled = currentPage === Math.ceil(fullCount / masterSearchQuery.perPage);
  
  useEffect(() => {
    callback(currentPage, masterSearchQuery);
  }, [currentPage]);

  const pageUp = () => setCurrentPage(currentPage + 1);

  const pageDown = () => setCurrentPage(currentPage - 1);

  return (
    <div
      style = {{ 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <button className={styles.prev} disabled={downDisabled} onClick={pageDown}>
        Previous Page
      </button>
      <button className={styles.next} disabled={upDisabled} onClick={pageUp}>
        Next Page
      </button>
      {!!fullCount && <span>
        Page {currentPage} of {Math.ceil(fullCount / masterSearchQuery.perPage) || 0}
      </span>}
    </div>
  );
}
