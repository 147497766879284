// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Je84CdE-3sU0_PRA-zbSn {\n  grid-column: 1 / 3;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-bottom: 1em;\n}", "",{"version":3,"sources":["orgUserTable.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB","file":"orgUserTable.css","sourcesContent":[".buttonContainer {\n  grid-column: 1 / 3;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-bottom: 1em;\n}"]}]);
// Exports
exports.locals = {
	"buttonContainer": "Je84CdE-3sU0_PRA-zbSn"
};
module.exports = exports;
