import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getUserLoading } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { getSingleSummarizedStudy } from '../../services/studies';
import SummarizedResultItem from '../studies/results/SummarizedResultItem';
import { yearPublished, tumorType, naturalTherapyTypes, conventionalTreatmentTypes, sideEffects } from '../../utils/dropdownSeedData';

export default function SingleResult() {
  const navigate = useNavigate();
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);
  const [resultLoading, setResultLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [displayComponent, setDisplayComponent] = useState('');  

  useEffect(() => {
    document.title = 'KNOW - Shared Result';
  }, []);

  const searchParams = new URL(window.location).searchParams;
  const uuid = searchParams.get('uuid');
  const docType = searchParams.get('docType');

  const filterGroupArrays = [yearPublished, tumorType, naturalTherapyTypes, conventionalTreatmentTypes, sideEffects];
  const filterOptions = () => {
    return filterGroupArrays.reduce((initialState, filterArray) => {
      const [filterName, allOption] = filterArray;
      return { ...initialState, [filterName]: [allOption] };
    }, {});
  };
  const filteredKeys = Object.entries(filterOptions()).filter(([, [firstFilter]]) => !firstFilter.startsWith('All ')).map(([key]) => key);

  useEffect(() => {
    try {
      switch(docType) {
        case 'Microtag':
        case 'None':
        case 'Summary': getSingleSummarizedStudy(uuid)
          .then(res => setDisplayComponent(<SummarizedResultItem study={res.results} isOpen={true} filteredKeys={filteredKeys} />));
          break;
        default: break;
      }
      setResultLoading(false);
    }
    catch(err) {
      setMessage(err);
      setResultLoading(false);
    }
  }, []);

  useEffect(() => {
    if(!user.email) {
      const postLoginPath = window.location.pathname.slice(1) + window.location.search;
      navigate(`/login#${postLoginPath}`);
    }
  }, [user.email, navigate]);

  if(userLoading || resultLoading) return <LoadingSpinner />;

  return (
    <>
      {message}
      {displayComponent}
    </>
  );
}
