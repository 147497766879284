// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".M0h9tsw0MUgpSV8IS-rig {\n  height: 200px;\n}\n  \n  .M0h9tsw0MUgpSV8IS-rig ._1tl_0Jx-l-If1K9gUFfo8S {\n    overflow: auto;\n    height: 80%;\n  }", "",{"version":3,"sources":["multipleSelect.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AAMf;;EAJE;IACE,cAAc;IACd,WAAW;EACb","file":"multipleSelect.css","sourcesContent":[".multipleSelectContainer {\n  height: 200px;\n  \n  .optionsContainer {\n    overflow: auto;\n    height: 80%;\n  }\n}"]}]);
// Exports
exports.locals = {
	"multipleSelectContainer": "M0h9tsw0MUgpSV8IS-rig",
	"optionsContainer": "_1tl_0Jx-l-If1K9gUFfo8S"
};
module.exports = exports;
