import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import styles from './license.css';
import { invitePaidUser } from '../../services/signup';
import { getUser, getUserLoading } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { SUBSCRIPTION_PRICES } from '../../utils/prices';

const iframeStyle = {
  minWidth: '80%'
};

export default function License() {
  const [agreement, setAgreement] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [showPayPalButtons, setShowPayPalButtons] = useState(false);
  const [message, setMessage] = useState('');
  // const [savedOrderStatus, setSavedOrderStatus] = useState(false);

  // Redux
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);
  const { email: userEmail } = user;

  // get promo code from URL if present
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const promoCode = queryParams.get('code');

  useEffect(() => {
    document.title = 'KNOW - License Agreement';
  }, []);

  useEffect(() => {
    if(userEmail) {
      setEmailAddress(userEmail);
    } else {
      setEmailAddress('');
    }
  }, [userEmail]);

  useEffect(() => {
    const regExStatement = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setShowPayPalButtons(regExStatement.test(emailAddress));
  }, [emailAddress]);

  // useEffect(() => {
  //   if(savedOrderStatus) clearInterval(checkStatus);
  //   return () => clearInterval(checkStatus);
  // }, [savedOrderStatus]);

  if(userLoading) {
    return <LoadingSpinner />;
  }

  const handleAgreement = () => setAgreement(!agreement);
  const handleEmailChange = ({ target }) => setEmailAddress(target.value);
  
  const createOrder = (_data, actions) => {
    const price = SUBSCRIPTION_PRICES.standard_cents;
  
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: price,
          },
          description: 'KNOWOncology Individual License',
        },
      ],
    });
  };

  const createSubscription = (_data, actions) => {
    // eslint-disable-next-line no-undef
    const planId = process.env.PAYPAL_PLAN_ID_INDIVIDUAL;

    return actions.subscription.create({
      plan_id: planId
    });
  };

  //unknown whether this will actually ever get called. The PayPal buttons seem to only return completed orders. To call this function, the returned order status will not be completed or approved. Additionally, since we do not call the orders API ourselves, we cannot mock an unsuccessful payment. It is assume the paypal buttons will inform the user that they do not have the necessary funds, or that the payment was declined.

  // const checkStatus = (data, actions) => {
  //   return setInterval(() => {
  //     actions.order.capture()
  //       .then(details => {
  //         console.log('Saved order status details: ', details.status);
  //         switch(details.status) {
  //           case 'COMPLETED':
  //           case 'APPROVED':
  //             setSavedOrderStatus(true);
  //             invitePaidUser(details.id, emailAddress)
  //               .catch(err => setMessage(`There was an error sending your invitational link, please contact the KNOW administration. Error: ${err}`));
  //             break;
  //           default: 
  //             break;
  //         }});
  //   }, 15000);
  // };
  
  const onApprove = (_data, actions) => {
    return actions.order.capture()
      .then(details => {
        // console.log('order captured');
        switch(details.status) {
          case 'COMPLETED':
          case 'APPROVED':
            invitePaidUser(details.id, emailAddress, promoCode)
              .then(() => {
                setShowPayPalButtons(false);
                setMessage(
                  // eslint-disable-next-line quotes
                  `Thank you for your purchase! Your account has been updated.${!userEmail ? " If you haven't yet created a KNOW profile, you'll receive an email with further instructions." : ''}`
                );
              })
              .catch(err => setMessage(`Your payment was successful, but there was an error sending your invitational link, please contact us at info@knowoncology.org. Error: ${JSON.stringify(err)}`));
            break;
          // case 'SAVED':
          //   setMessage('Thank you for your order. Your purchase is being processed. Please check the email address you provided in a few minutes for a link to complete your KNOW profile.');
          //   checkStatus(data, actions);
          //   break;
          // case 'CREATED':
          //   setMessage('Your order was created, but not completed. Please finish your transaction.');
          //   break;
          // case 'VOIDED':
          //   setMessage('Purchasing canceled.');
          //   break;
          // case 'PAYER_ACTION_REQUIRED':
          //   setMessage('Action is required on your paypal account, likely the result of requiring 2 Factor Authentication. Please go to PayPal to approve this transaction.');
          //   checkStatus(data, actions);
          //   break;
          default:
            break;
        }
      });
  };
  const onError = err => setMessage(`There was an error with your payment: ${JSON.stringify(err)}`);

  return (
    <div className={styles.licenseContainer}>
      <p className='h5'>License Agreement</p>
      <p><strong>Please carefully read the following licensing agreement.</strong></p>
      <iframe
        src="https://docs.google.com/document/d/e/2PACX-1vRmNUzylsPtUgfoSL_RTEGYOU1xszLQlh6Y2jQtPaHMXn1xGzyhbyjUv6dILlCuZw/pub?embedded=true"
        style={iframeStyle}>
      </iframe>
      <section>
        <div>
          <label>
            <input type='checkbox' onClick={handleAgreement} value={agreement}/>
            I have read the license, accept all terms and conditions, and acknowledge that clicking this checkbox serves as a legally binding digital signature.
          </label>
        </div>
        {agreement && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {!userEmail && <p>
              Upon completion of payment, we will send an email to the email address you provide with a link to complete your KNOW profile (if you don&apos;t have one already).<br /><br />
              The email address here can be different than the one associated with your paypal account.
            </p>}
            {!userEmail && <label style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}> 
              Email Address
              <input type='text' value={emailAddress} onChange={handleEmailChange} style={{ width: '60%', margin: '1em' }} />
            </label>}
            {showPayPalButtons &&
              <div style={{ display: 'flex', alignSelf: 'center', flexDirection: 'column', alignItems: 'center' }}>
                {/* <p>
                  <strong>
                    After the paypal window closes, there will be a brief delay before a successful order message appears.
                  </strong>
                </p> */}
                <strong style={{ marginBottom: '15px' }}>${SUBSCRIPTION_PRICES.standard_cents}</strong>
                <PayPalScriptProvider
                  options={{
                    // eslint-disable-next-line no-undef
                    'client-id': `${process.env.PAYPAL_CLIENT_ID}`,
                    'disable-funding': 'paylater'
                  }}>
                  <PayPalButtons shippingPreference='NO_SHIPPING' createOrder={createOrder} onApprove={onApprove} onError={onError} />
                  {/* <PayPalButtons shippingPreference='NO_SHIPPING' createOrder={createSubscription} onApprove={onApprove} onError={onError} /> */}
                </PayPalScriptProvider>
              </div>
            }
          </div>
        )}
        {message && <p>
          {message}
        </p>}
      </section>
    </div>
  );
}
