import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCookieBannerInfo } from '../../data/selectors/stylesSelectors';
import { getUser } from '../../data/selectors/userAuthSelectors';
import styles from './ipLoginNotice.css';

export default function IpLoginNotice() {
  const user = useSelector(getUser);
  const cookieBannerInfo = useSelector(getCookieBannerInfo);

  if(user.email !== 'ccnm@ccnm.org') return null;

  return (
    <div className={styles.noticeContainer} style={{ bottom: cookieBannerInfo.height }} aria-label="Implicit terms of use agreement banner" role="note">
      <p>
        Welcome! By continuing to use our site, you are agreeing to our <Link to='/terms-of-use' className={styles.linkText}>Terms of Use</Link>.
      </p>
    </div>
  );
}


