import React, { useEffect } from 'react';
import MethodButtons from './methodButtons/MethodButtons';
import { useMethods } from '../../hooks/methods';
import styles from './methodology.css';


const Methodology = () => {
  const { 
    handleClick, 
    component, 
    method 
  } = useMethods();

  useEffect(() => {
    document.title = 'KNOW - Methodology';
  }, []);

  return (
    <div className={`${styles.Methodology} grid-x grid-container ${styles.methodContainer}`}>
      <div className="cell small-12 medium-3" role="nav" aria-label="methodology section navigation">
        <MethodButtons onClick={handleClick} method={method} />
      </div>
      <div className="cell small-12 medium-9" role="main" aria-label="Methodology">
        <section id='method-display'>
          <div>{component}</div>
        </section>
      </div>
    </div>
  );
};

export default Methodology;
