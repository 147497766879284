import { request } from './request';

export const login = (email, password) => {
  return request('/auth/login', 'POST', { email, password });
};

export const fetchUser = () => {
  return request('/auth/user', 'GET');
};

export const logout = () => {
  return request('/auth/logout', 'GET');
};

export const requestPasswordResetLink = email => {
  return request('/auth/forgot-password', 'POST', { email });
};

export const resetPassword = (token, password) => {
  return request('/auth/reset-password', 'PATCH', { token, password });
};

export const agreeToTerms = id => {
  return request(`/auth/agree-to-terms/${id}`, 'PATCH', { agreed_to_terms: true });
};

export const autoLogin = (referrer, uid) => {
  return request('/auth/auto-login', 'POST', { referrer, uid });
};

export const getUserById = id => {
  return request(`/auth/user/${id}`, 'GET');
};

export const updateUserExpiration = (transactionId, userId, accessExpiration) => {
  return request('/auth/renew-subscription', 'PATCH', { transactionId, userId, accessExpiration });
};
