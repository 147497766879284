import { RESET_PROVIDER_QUERY, SET_PROVIDER_QUERY } from '../actions/providerActions';

const initialState = {
  query: {
    searchText: '',
    filters: {
      focuses: [],
      professions: [],
      serviceAreas: [],
      onlyPhysical: false,
      onlyVirtual: false
    }
  },
  queried: false,
  perPage: 20,
  page: 1,
};

export const providerReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_PROVIDER_QUERY:
      return { ...state, query: action.payload, queried: true };
    case RESET_PROVIDER_QUERY:
      return { ...initialState };
    default:
      return state;
  }
};
